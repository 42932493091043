<div
  *ngIf="message"
  class="modal style-modal"
  tabindex="-1"
  role="dialog"
>
  <div
    class="modal-dialog custom-alert box cnfrm-modal"
    role="document"  >
    <div class="modal-content">
      <!-- <div class="custom-card-header card-header heading text-center"> -->
      <div class="people-title-heading">
        <span class="table-title"> Confirmation Message! </span>
      </div>
      <div class="body p-1">
        <div *ngIf="message?.type == 'confirm'" class="modal-body">
          <div class="row">
            <div class="col-md-12 body-text">
              <p class="text-center confirm-message">{{ message.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-right">
        <button *ngIf="!isNoButton"
          id="button"
          data-dismiss="modal"
          (click)="message.noFn()"
          aria-label="Close"
        >
          Cancel
        </button>
        <button id="button" (click)="message.yesFn()">{{yesButtonText ? yesButtonText : 'Confirm'}}</button>
      </div>
    </div>
  </div>
</div>
