<div class="inner-section">
  <mat-card class="mat-card-design">

  <nav mat-tab-nav-bar class="d-none d-sm-flex padding-left-10px">
    <a class="tab-size mt-0"
      mat-tab-link
      *ngFor="let e of tabs"
      [routerLink]="e.path"
      [queryParams]="e.params"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{ e.title }}
    </a>
  </nav>
  </mat-card>

  <div>
    <router-outlet></router-outlet>
  </div>
</div>
