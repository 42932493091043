<div class="module-style notification-history">
  <div class="row w-100 m-0 bg-white" style="padding:10px 20px;">
    <div class="col-md-12 p-0 mb-2 card-shadow row m-0">
      <div class="people-title-heading w-100">Notification History List
        <form
          (submit)="search(notificationHistoryFilterForm.value)"
          [formGroup]="notificationHistoryFilterForm"
          class="row emp-content noti-reciever col-6 just-end m-0 px-2 filters"
        >
          <!--
            Date
          -->
          <div class="pl-1 pr-0">
            <div class="re-size toggle-date" style="    width: 107px;">
              <input
              style="padding-left: 7px !important;"
                #date
                formControlName="tempdate"
                class="m-0"
                matInput
                placeholder="Date"
                [matDatepicker]="varDate"
              />
              <mat-datepicker-toggle [for]="varDate"></mat-datepicker-toggle>
              <mat-datepicker #varDate></mat-datepicker>
            </div>
          </div>
          <!--
            Message Type
          -->
          <div class="pl-1 pr-0" id="filter_field">
            <mat-form-field appearance="fill">
              <mat-label class="label-color" [hidden]="messageType.value">Message Type</mat-label>
              <mat-select
                #messageType
                formControlName="tempmessageType"
                panelClass="options-panel"
              >
                <mat-option value="multicast"
                  >MultiCast - Notification</mat-option
                >
                <mat-option value="broadcast"
                  >Broadcast - Notification</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <!--
            Message Category
          -->
          <div class="pr-0" id="filter_field">
            <mat-form-field appearance="fill">
              <mat-label class="label-color" [hidden]="messageCategory.value">Message Category</mat-label>
              <mat-select
                #messageCategory
                formControlName="tempmessageCategory"
                panelClass="options-panel"
              >
                <mat-option value="notification">Notification</mat-option>
                <mat-option value="email">Email</mat-option>
                <mat-option value="sms">SMS</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button id="button" class="ml-1" type="submit">Search</button>
          <button id="button" class="ml-1" type="reset" (click)="reset()">Reset</button>
          <!-- <div class="custom-btn btn-wrapper bg-transparent p-cus-0">

          </div> -->
        </form>
      </div>
      <div class="p-cus-0 doc-content col-12">
        <div class="m-0">
          <div
            class="scroll-results table-scroll w-100 col-md-12 doc-content comp-card p-0"
            infiniteScroll
            [infiniteScrollDistance]="3"
            [infiniteScrollThrottle]="50"
            [alwaysCallback] = "true"
            [scrollWindow]="false"
            (scrolled)="getDataOnScroll(notificationHistoryFilterForm.value)"
          >
            <table *ngIf="allNotifications.length > 0" class="doc-records">
              <tr>
                <th>Creation Date</th>
                <th>Scheduler Date</th>
                <th>Created by</th>
                <th>Category</th>
                <th>Type</th>
                <th>Send Status</th>
                <th>Send date</th>
                <th>Send Count</th>
              </tr>
              <tr
                class="notification-history-table"
                (click)="getSingleNotification(notification.id)"
                *ngFor="let notification of allNotifications"
              >
                <td>{{ notification.created_date | formatDate }}</td>
                <td>{{ notification.notification_date ? (notification.notification_date | formatDate) : "Not Set" }}</td>
                <td>{{ notification.created_by ? notification.created_by : "Not Set" }}</td>
                <td>{{ notification.notification_category }}</td>
                <td>{{ notification.notification_type }}</td>
                <td>{{ notification.status }}</td>
                <td>{{ notification.completion_date ? (notification.completion_date | formatDate) : 'Not Set' }}</td>
                <td>{{ notification.send_count }}</td>
              </tr>
            </table>
            <div class="not-found" *ngIf="allNotifications.length == 0">Record Not Found</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
