import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HeaderService } from "src/app/services/header.service";
import { EmployeeAttendanceService } from "src/app/services/employee-attendance.service";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";
import { NgForm, FormBuilder } from "@angular/forms";
import { MessageService } from "src/app/services/message.service";
import { ToastrService } from "ngx-toastr";
import { LoaderService } from "src/app/services/loader.service";
import { HttpErrorResponse } from "@angular/common/http";
import * as config from "config.json";
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  public clock: string;
  public currentDate: number = Date.now();
  breadCrumb = [];

  orgStructureUrl =
    typeof localStorage.getItem("host") !== "undefined" &&
    localStorage.getItem("host") !== null
      ? JSON.parse(localStorage.getItem("host"))
      : "";

  userEncName =
    typeof localStorage.getItem("u") !== "undefined" &&
    localStorage.getItem("u") !== null
      ? JSON.parse(localStorage.getItem("u"))
      : "";

  userEncPassword =
    typeof localStorage.getItem("p") !== "undefined" &&
    localStorage.getItem("p") !== null
      ? JSON.parse(localStorage.getItem("p"))
      : "";

  employeeName =
    typeof localStorage.getItem("emp_name") !== "undefined" &&
    localStorage.getItem("emp_name") !== null
      ? JSON.parse(localStorage.getItem("emp_name"))
      : "HR";

  companyName = localStorage.getItem("company_name");

  profile = "";
  companyLogo: string = "";

  url: any;
  portals;
  selectedPortalLink;
  empName: string;
  isClientPortal = 0;
  portalObj: any;
  isPortalHomePage: boolean = false;
  policyManualLink = `${config.laravelAppUrl}policy-manual/`;
  userManualLink = `${config.laravelAppUrl}usermanual/`;
  subPortals: any;
  selectedSubPortal: any;
  selectedPortal: any;
  IsSubPortalSelected: boolean = false;

  constructor(
    public headerService: HeaderService,
    public employeeAttendanceService: EmployeeAttendanceService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public formBuilder: FormBuilder,
    private messageService: MessageService,
    private toastr: ToastrService,
    public loader: LoaderService
  ) {}

  public testGroup = this.formBuilder.group({
    Email: "",
  });

  async ngOnInit() {
    this.runMiniClock();
    this.url = this.router.url.substr(1);
    this.loader.profile.subscribe((res) => (this.profile = res));
    this.loader.companyLogo.subscribe((res) => (this.companyLogo = res));

    // this.portalObj = await JSON.parse(localStorage.getItem('portalObj'));
    this.selectedPortalLink = localStorage.getItem("link");

    const isClientPortals: string = localStorage.getItem("isClientPortals");
    const portalId = localStorage.getItem("portalId");

    this.headerService.getPortals(this.isClientPortal).subscribe(
      (resp: any) => {
        if (typeof resp["data"] !== "undefined") {
          const response =
            isClientPortals == "true"
              ? resp.data.isClientPortals
              : resp.data.isPeoplePortals;
          this.portals = response.map((item) => ({
            ...item,
            val: item.link + "|" + item.phpurl,
          }));
          console.log("this.portals=>", this.portals);
          this.portalObj = this.portals.filter((x) => +x.id == +portalId)[0];
          console.log("this.portals after=>", this.portals);

          this.selectedPortal = this.portalObj;
          localStorage.setItem(
            "parentPortalId",
            this.selectedPortal?.portal_id
          );
          localStorage.setItem("selectedPortalName", this.selectedPortal?.name);
          console.log("this.selectedPortal =>", this.selectedPortal);
          if (this.selectedPortal?.has_child == 1) {
            this.getSubPortals(this.portalObj?.portal_id);
          } else {
            this.getMenus(true);
            localStorage.removeItem("subPortalTitle");
            localStorage.setItem("subPortalId", "0");
          }
        } else {
          this.toastr.error("Session Expired. Please login again!");
          this.router.navigate(["/"]);
        }
      },
      (error: HttpErrorResponse) => {
        this.toastr.error("Token Expired. Please login again!");
        this.router.navigate(["/"]);
      }
    );

    this.employeeAttendanceService.empName$.subscribe((name) => {
      this.empName = name;
    });
  }

  getSubPortals(portalId) {
    const subPortalId: number = +localStorage.getItem("subPortalId");
    if (subPortalId) this.IsSubPortalSelected = true;
    this.headerService.getSubPortals(portalId).subscribe(
      (resp: any) => {
        if (resp?.data?.subPortals?.length) {
          this.subPortals = resp?.data?.subPortals;
          if (subPortalId) {
            this.selectedSubPortal = this.subPortals.filter(
              (x) => x.id == subPortalId
            )[0];
            this.selectedPortal = this.selectedSubPortal;
            localStorage.setItem("subPortalTitle", this.selectedPortal.title);
            localStorage.setItem("subPortalId", this.selectedPortal.id);
            localStorage.setItem(
              "selectedPortalName",
              this.selectedSubPortal.name
            );
          }
          const index = this.portals.findIndex(
            (obj) => obj.portal_id === portalId
          );
          if (index !== -1) {
            this.portals[index] = {
              ...this.portals[index],
              subPortals: this.subPortals,
            };
          }
          if (subPortalId) {
            this.getMenus(true);
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.toastr.error("Token Expired. Please login again!");
        this.router.navigate(["/"]);
      }
    );
  }

  // 1) Get raw menus from MySQL.
  getMenus(isMenuCalled: boolean = false) {
    // const portalId = this.subPortals.
    if (isMenuCalled) {
      // this.headerService.getMenus(this.portalObj?.id, this.portalObj?.is_client_portal)
      this.headerService
        .getMenus(
          this.selectedPortal?.id,
          this.selectedPortal?.is_client_portal
        )
        .subscribe((result: Array<any>) => {
          this.headerService.rawHeaderMenus.next(result);
          this.headerService.sortedMenus();
        });
    }
  }

  public runMiniClock() {
    const time = new Date();
    let hours = 0;
    let minutes = null;
    let ampm = "";

    setInterval(() => {
      hours = time.getHours();
      minutes = time.getMinutes();
      minutes = (minutes < 10 ? "0" : "") + minutes;
      ampm = hours >= 12 ? "PM" : "AM";
      hours = hours > 12 ? hours - 12 : hours;
      hours = hours === 0 ? 12 : hours;
      this.clock = hours + ":" + minutes + " " + ampm;
    }, 1000);
  }

  openLink(componentName: string, fileName: string, clickStatus: boolean) {
    if (clickStatus) {
      if (componentName) {
        this.router.navigate(["/" + componentName], {
          relativeTo: this.activatedRoute,
        });
        this.headerService.getBreadCrum();
      } else if (fileName) {
        /*
         * In case seamless if fileName found it will redirect the page.
         *  Seamless
         */

        this.messageService.sendMessage({
          Component: "Header",
          For: "SeamlessForm",
          Data: { fileName: fileName },
        });
        this.loader.isLoading.next(true);

        /* const destinationRequest = fileName.split('/').pop();
         const portal = (typeof localStorage.getItem('link') != 'undefined'
           && localStorage.getItem('link') != 'null') ?
           localStorage.getItem('link') : '';
         const employeeId = (typeof localStorage.getItem('e_number') != 'undefined'
           && localStorage.getItem('e_number') != 'null') ? localStorage.getItem('e_number') : '';
         const sourceRequest = this.router.url.split('/').pop();

         $('.showLoader').show();

         var extraParams = "";
         if (destinationRequest === "cempedit.php") {
           extraParams = JSON.stringify({ "id": window.btoa('0') });
         }

         this.authService.switchSeamlessportal(destinationRequest, portal, employeeId, sourceRequest, extraParams)
           .subscribe(data => {
             if (data.status == '200' && data.loginId != 'false') {
               let routingLink = environment.phpSiteUrl + "?u=" + data.loginId + "&mt=aes";
               window.open(routingLink, '_self');
             }
             else {
               alert('Something went wrong cannot proceed your request');
             }
           });*/
      }
    } else {
      this.headerService.levelOne.next([]);
      this.headerService.levelTwo.next([]);
      this.headerService.levelOneActive =
        this.headerService.levelOneActivePrevious;
      this.headerService.levelTwoActive =
        this.headerService.levelTwoActivePrevious;
      this.headerService.levelThreeActive =
        this.headerService.levelThreeActivePrevious;

      // Previous Recode
      this.headerService.levelOnePrevious.subscribe((subMenu) => {
        this.headerService.levelOne.next(subMenu);
      });
      this.headerService.levelTwoPrevious.subscribe((resp) => {
        this.headerService.levelTwo.next(resp);
      });

      this.headerService.levelTwoHover = null;
    }
  }

  /*
   *  Put Portal Title and Portal ID into localStorage
   */

  // portalDetails(portal: any) {
  //   debugger
  //   this.selectedPortal = portal
  //   localStorage.setItem("portalTitle", portal.title);
  //   localStorage.setItem("portalId", portal.id);
  //   localStorage.setItem("portalObj", JSON.parse(portal));

  //   this.messageService.sendMessage({ Component: "Header", For: "UpdatePortalSeamLess", Data: { portalTitle: portal.title, portalId: portal.id } });

  // }

  selectPortal(event, portal, subPortal) {
    if (portal?.has_child == 0) {
      if (portal.is_enable == 0 || subPortal?.is_enable == 0) {
        this.toastr.error("You are not authorised to use this feature!");
        return;
      }
    }

    const linkName = subPortal ? subPortal.link : portal.val;

    if (linkName) {
      var splittedLinkName = linkName.split("|")[0];
      this.selectedPortalLink = splittedLinkName;
      this.selectedPortal = portal;

      localStorage.setItem("link", splittedLinkName);
      localStorage.setItem("portalTitle", portal.name);
      localStorage.setItem("portalId", portal.id);
      localStorage.setItem("portalObj", JSON.stringify(portal));
      localStorage.setItem("parentPortalId", portal.portal_id);
      localStorage.setItem("selectedPortalName", portal.name);

      if (subPortal) {
        localStorage.setItem("subPortalTitle", subPortal.name);
        localStorage.setItem("subPortalId", subPortal.id);
        localStorage.setItem("subPortalObj", JSON.stringify(subPortal));
        localStorage.setItem("selectedPortalName", subPortal.name);
        this.IsSubPortalSelected = true;
      } else {
        this.IsSubPortalSelected = false;
        localStorage.removeItem("subPortalTitle");
        localStorage.removeItem("subPortalId");
        localStorage.removeItem("subPortalObj");
        localStorage.setItem("subPortalId", "0");
      }

      this.messageService.sendMessage({
        Component: "Header",
        For: "UpdatePortalSeamLess",
        Data: { portalTitle: portal.name, portalId: portal.id },
      });

      if (portal?.has_child == 0 || subPortal?.is_enable == 1) {
        this.router.navigate(["/" + splittedLinkName]);
      }
      this.ngOnInit();
    }
  }

  getPortalName(): string {
    // debugger
    if (this.portals)
      return this.portals.filter(
        (map) =>
          map.group_name === this.selectedPortal?.link ||
          map?.link === this.selectedPortal?.link
      )[0]?.name;
    return "";
  }

  toggle(element) {
    let toggle = false;

    if (element === "blue") {
      if (Number(localStorage.getItem("clientPortalsCount")) > 0) {
        toggle = true;
        localStorage.setItem("isClientPortals", "true");
        this.router.navigate(["/portals"]);
      } else {
        this.toastr.error("Client portals not found!");
      }
    }

    if (element === "green") {
      if (Number(localStorage.getItem("peoplePortalsCount")) > 0) {
        toggle = false;
        localStorage.setItem("isClientPortals", "false");
        this.router.navigate(["/portals"]);
      } else {
        this.toastr.error("People portals not found!");
      }
    }

    /*
        if (toggle) {
          this.loader.appBackground.next('full-page-background');
          this.loader.appFooterBackground.next('footer_wrapper');
          this.loader.appMenuBackground.next('main_navigation');
          this.loader.appSecondTierMenuBackground.next('second_tier_menu');
          this.loader.appSecondTierMenuHover.next('hover_color');
        }
        else {
          this.loader.appBackground.next('full-page-background1');
          this.loader.appFooterBackground.next('footer_wrapper1');
          this.loader.appMenuBackground.next('main_navigation1');
          this.loader.appSecondTierMenuBackground.next('second_tier_menu1');
          this.loader.appSecondTierMenuHover.next('hover_color1');
        }*/
  }
  navigateHome() {
    let link = localStorage.getItem("link");
    let token = localStorage.getItem("token");
    if (token) {
      if (link != null) {
        this.router.navigate(["/" + link]);
      } else {
        this.router.navigate(["/portals"]);
      }
    } else {
      this.router.navigate(["/"]);
    }
  }
  logout() {
    const appToken = localStorage.getItem("app_token");
    const token = JSON.parse(localStorage.getItem("token"));
    const tokenNda = JSON.parse(localStorage.getItem("token_nda"));
    const authToken = `Bearer ${token}|${appToken}|${tokenNda}`;
    this.loader.isLoading.next(true);
    this.authService.logoutUser(authToken).subscribe(
      (response) => {
        this.loader.isLoading.next(false);
        if (response.success == true) {
          localStorage.clear();
          this.router.navigate(["/"]);
          this.toastr.info(response.message);
          this.loader.profile.next("");
          this.profile = "";
        } else {
          this.loader.isLoading.next(false);
          this.toastr.info("Error while logout user, Please try again later");
        }
      },
      (error) => {
        this.loader.isLoading.next(false);
      }
    );
  }

  verifyImage(e) {
    if (e.type == "error") {
      this.profile = "assets/images/user.png";
    }
  }

  verifyImage2(e) {
    if (e.type == "error") {
      this.companyLogo = "assets/img/logo.png";
    }
  }
}

// ================== Steps for dynamic menus
// 1) Get raw menus from MySQL.
// 2) Sort raw menus.
// 3) Get selected menu (Current Page).
// 4) Create parent and set sub menus using sorted menus and selected menus (Current Loaded Page)
