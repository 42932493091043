import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  getLookup(arg0: string) {
    throw new Error("Method not implemented.");
  }
  headers: any;
  constructor(private http: HttpClient) {
    this.headers = new Headers({
      "Content-Type": "multipart/form-data",
    });
  }
  public getLoookup(url: string): Observable<any> {
    return this.http.get(environment.nodeApiUrl + url);
  }

  get(url: string): Observable<any> {
    return this.http.get(url)?.flatMap((res: any) => {
      return Observable.of(res);
    });
  }

  public getByNodeUrl(url: string, body?: any): Observable<any> {
    return this.http.post(environment.nodeApiUrl + url, body);
  }

  getByParams(url: string, data: any): Observable<any> {
    return this.http
      .get(url, {
        params: data,
      })
      ?.flatMap((res: any) => {
        return Observable.of(res);
      });
  }

  getByParams2(url: string, data?: any): Observable<any> {
    return this.http
      .post(url, {
        params: data,
      })
      ?.flatMap((res: any) => {
        return Observable.of(res);
      });
  }

  post(url: string, data: any): Observable<any> {
    return this.http.post(url, data)?.flatMap((res: any) => {
      return Observable.of(res);
    });
  }

  put(url: string, data: any): Observable<any> {
    return this.http.put(url, data)?.flatMap((res: any) => {
      return Observable.of(res);
    });
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url)?.flatMap((res: any) => {
      return Observable.of(res);
    });
  }
  getSpecificSlip(url: string, data: any): Observable<any> {
    return this.http
      .post(url, { id: data.id, sid: data.sid })
      ?.flatMap((res: any) => {
        return Observable.of(res);
      });
  }
  public getLookupByNode(url: string): Observable<any> {
    return this.http.get(environment.nodeApiUrl + url);
  }
}
