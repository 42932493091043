<div class="wrapper center-block summary_detail">
    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingThree">
        <h4 class="panel-title">
          <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
            href="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                <i class="navbar-toggler-icon fa fa-angle-down pull-right" style="font-size: 16px;margin-right:10px;"></i>
          </a>
        </h4>
      </div>
      <div id="collapseThree" class="collapse show" role="tabpanel" aria-labelledby="headingThree">
        <div class="panel-body" style="    position: relative;">
            <div #summaryData class="setHeight"></div>
        </div>
      </div>
    </div>
  </div>
</div>
