import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { APIs } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  private _columnChartSeriesData = new Subject();
  private _lineChartSeriesData = new Subject();
  constructor(private http: HttpClient) { }

      // Get and Set Chart series Data
      set setColumnChartSeriesData(value: any) {
        this._columnChartSeriesData.next(value);
    }

    get getColumnChartSeriesData(): Observable<any> {
        return this._columnChartSeriesData.asObservable();
    }

      // Get and Set Chart categories Data
      set setLineChartSeriesData(value: any) {
        this._lineChartSeriesData.next(value);
    }

    get getLineChartSeriesData(): Observable<any> {
        return this._lineChartSeriesData.asObservable();
    }
}
