<ngx-charts-chart [view]="[width, height]" [showLegend]="legend" [legendOptions]="legendOptions"
  (legendLabelClick)="onClick($event)" (legendLabelActivate)="onActivate($event)"
  (legendLabelDeactivate)="onDeactivate($event)" mouseWheel (mouseWheelUp)="onZoom($event, 'in')"
  (mouseWheelDown)="onZoom($event, 'out')">
  <svg:g *ngIf="initialized && graph" [attr.transform]="transform" (touchstart)="onTouchStart($event)"
    (touchend)="onTouchEnd($event)" class="graph chart">

    <defs>
      <ng-container *ngIf="defsTemplate" [ngTemplateOutlet]="defsTemplate"></ng-container>
      <svg:path class="text-path" *ngFor="let link of graph.edges" [attr.d]="link.textPath" [attr.id]="link.id">
      </svg:path>
    </defs>

    <svg:rect class="panning-rect" [attr.width]="dims.width * 100" [attr.height]="dims.height * 100"
      [attr.transform]="'translate(' + (-dims.width || 0) * 50 + ',' + (-dims.height || 0) * 50 + ')'"
      (mousedown)="isPanning = true" />

    <ng-content></ng-content>

    <svg:g class="clusters">
      <svg:g #clusterElement *ngFor="let node of graph.clusters; trackBy: trackNodeBy" class="node-group"
        [class.old-node]="animate && oldClusters.has(node.id)" [id]="node.id" [attr.transform]="node.transform"
        (click)="onClick(node)">
        <ng-container *ngIf="clusterTemplate" [ngTemplateOutlet]="clusterTemplate"
          [ngTemplateOutletContext]="{ $implicit: node }"></ng-container>
        <svg:g *ngIf="!clusterTemplate" class="node cluster">
          <svg:rect [attr.width]="node.dimension.width" [attr.height]="node.dimension.height"
            [attr.fill]="node.data?.color" />
          <svg:text alignment-baseline="central" [attr.x]="10" [attr.y]="node.dimension.height / 2">
            {{ node.label }}
          </svg:text>
        </svg:g>
      </svg:g>
    </svg:g>

    <svg:g class="links">
      <svg:g #linkElement *ngFor="let link of graph.edges; trackBy: trackLinkBy" class="link-group" [id]="link.id">
        <ng-container *ngIf="linkTemplate" [ngTemplateOutlet]="linkTemplate"
          [ngTemplateOutletContext]="{ $implicit: link }"></ng-container>
        <svg:path *ngIf="!linkTemplate" class="edge" />
      </svg:g>
    </svg:g>

    <svg:g class="nodes">
      <svg:g #nodeElement *ngFor="let node of graph.nodes; trackBy: trackNodeBy" class="node-group"
        [class.old-node]="animate && oldNodes.has(node.id)" [id]="node.id"
        [attr.transform]="node.data.expectedLevel ? refactorAxixs(node.transform, node.data.currentLevel ,node.data.expectedLevel) : node.transform"
        (click)="onClick(node)" (mousedown)="onNodeMouseDown($event, node)">
        <ng-container *ngIf="nodeTemplate" [ngTemplateOutlet]="nodeTemplate"
          [ngTemplateOutletContext]="{ $implicit: node }"></ng-container>
        <svg:circle *ngIf="!nodeTemplate" r="10" [attr.cx]="node.dimension.width / 2"
          [attr.cy]="node.dimension.height / 2" [attr.fill]="node.data?.color" />
      </svg:g>
    </svg:g>
  </svg:g>
</ngx-charts-chart>
