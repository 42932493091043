import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class MessageService {

  private subject = new Subject();
 
  /**
   * 
   * @param message 
   * 
   *  Always send the following parameter to any message
   *  {Component: "Header", For: "SeamlessForm", Data: {}}
   * 
   *  Component : Component where you calling this message
   *  For: Explain the purpose of the 
   *  Data: provide data in it.
   * 
   */

  sendMessage(message: any) { 
      this.subject.next(message);
  }

  clearMessages() {
      this.subject.next();
  }

  getMessage(): Observable<any> {
      return this.subject.asObservable();
  }
}
