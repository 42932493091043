import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  year = (new Date()).getFullYear();
  constructor(public loaderService: LoaderService) { }

  ngOnInit() { }

  ngAfterViewInit() {
    setTimeout(
      function () {
        $("#loader-body").hide();
        // alert("hello world");
      }, 2000
    );

  }

}
