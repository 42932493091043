import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { CountryFilter } from '../shared/interfaces/country_filter';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class OrgFiltersService {
  private headers: Object = {};
  private _url = environment.nodeApiUrl;

  constructor(private http: HttpClient) {
    if(localStorage.getItem("token_nda") != 'undefined') {
      this.headers = {
        headers: new HttpHeaders({
          "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}|${localStorage.getItem('app_token')}|${JSON.parse(localStorage.getItem('token_nda'))}`
        })
      };
    }
  }

  getCountries(): Observable<CountryFilter[]> {
    return this.http.get<CountryFilter[]>(this._url + 'orgchart/countries_filter', this.headers)
      .catch(this.errorHandler);;
  }

  getCities(): Observable<CountryFilter[]> {
    return this.http.get<CountryFilter[]>(this._url + 'orgchart/cities_filter', this.headers)
      .catch(this.errorHandler);
  }

  getBranches(): Observable<CountryFilter[]> {
    return this.http.get<CountryFilter[]>(this._url + 'orgchart/branches_filter', this.headers)
      .catch(this.errorHandler);
  }

  getJobGroup(): Observable<CountryFilter[]> {
    return this.http.get<CountryFilter[]>(this._url + 'orgchart/group_two_filter', this.headers)
      .catch(this.errorHandler);
  }

  getJobFamily(): Observable<CountryFilter[]> {
    return this.http.get<CountryFilter[]>(this._url + 'orgchart/group_three_filter', this.headers)
      .catch(this.errorHandler);
  }

  getDepartment(): Observable<CountryFilter[]> {
    return this.http.get<CountryFilter[]>(this._url + 'orgchart/department_filter', this.headers)
      .catch(this.errorHandler);
  }

  getDesignation(department): Observable<CountryFilter[]> {
    if (department == null) {
      // Handle the case where department is null or undefined.
      return Observable.of([]);
    }
  const params = new HttpParams().set('department', department.toString());
     return this.http.get<CountryFilter[]>(this._url + 'orgchart/designation_filter', 
     {
      params: params,
      ...this.headers
    }).catch(this.errorHandler);
  }

  getBand(): Observable<CountryFilter[]> {
    return this.http.get<CountryFilter[]>(this._url + 'orgchart/bands_filter', this.headers)
      .catch(this.errorHandler);
  }

  // post filters to manipulate it dynamically
  sendCountryData(countryId): Observable<CountryFilter[]> {
    if (countryId == null) {
      // Handle the case where countryId is null or undefined
      return Observable.of([]);
    }
    const body = new HttpParams().set('countryId', countryId.toString());

    return this.http.post<CountryFilter[]>(this._url + 'orgchart/cities_filter', body.toString(),
      {
        headers: new HttpHeaders({
          "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}|${localStorage.getItem('app_token')}|${JSON.parse(localStorage.getItem('token_nda'))}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }
    )
      .catch(this.errorHandler);

  }

  sendCityData(cityId): Observable<CountryFilter[]> {
    if (cityId == null) {
      // Handle the case where cityId is null or undefined
      return Observable.of([]);
    }
    const body = new HttpParams().set('cityId', cityId.toString());

    return this.http.post<CountryFilter[]>(this._url + 'orgchart/cities_branches', body.toString(),
      {
        headers: new HttpHeaders({
          "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}|${localStorage.getItem('app_token')}|${JSON.parse(localStorage.getItem('token_nda'))}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }
    )
      .catch(this.errorHandler);

  }

  sendBranchData(branchId): Observable<CountryFilter[]> {
    if (branchId == null) {
      // Handle the case where branchId is null or undefined.
      return Observable.of([]);
    }
    const body = new HttpParams().set('branchId', branchId.toString());

    return this.http.post<CountryFilter[]>(this._url + 'orgchart/branch_country', body.toString(),
      {
        headers: new HttpHeaders({
          "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}|${localStorage.getItem('app_token')}|${JSON.parse(localStorage.getItem('token_nda'))}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }
    )
      .catch(this.errorHandler);

  }

  sendJobGroupData(groupId): Observable<CountryFilter[]> {
    if (groupId == null) {
      // Handle the case where groupId is null or undefined.
      return Observable.of([]);
    }
    const body = new HttpParams().set('groupId', groupId.toString());

    return this.http.post<CountryFilter[]>(this._url + 'orgchart/get_group_three', body.toString(),
      {
        headers: new HttpHeaders({
          "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}|${localStorage.getItem('app_token')}|${JSON.parse(localStorage.getItem('token_nda'))}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }
    )
      .catch(this.errorHandler);

  }

  sendJobFamilyData(familyId): Observable<CountryFilter[]> {
    if (familyId == null) {
      // Handle the case where familyId is null or undefined.
      return Observable.of([]);
    }
    const body = new HttpParams().set('familyId', familyId.toString());

    return this.http.post<CountryFilter[]>(this._url + 'orgchart/get_family_group_depart', body.toString(),
      {
        headers: new HttpHeaders({
          "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}|${localStorage.getItem('app_token')}|${JSON.parse(localStorage.getItem('token_nda'))}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }
    )
      .catch(this.errorHandler);

  }

  sendDepartmentData(departmentId): Observable<CountryFilter[]> {
    if (departmentId == null) {
      // Handle the case where departmentId is null or undefined
      return Observable.of([]);
    }
    const body = new HttpParams().set('departmentId', departmentId.toString());

    return this.http.post<CountryFilter[]>(this._url + 'orgchart/get_depart_groups', body.toString(),
      {
        headers: new HttpHeaders({
          "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}|${localStorage.getItem('app_token')}|${JSON.parse(localStorage.getItem('token_nda'))}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }
    )
      .catch(this.errorHandler);

  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server Error");
  }
}
