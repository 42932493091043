/**
 * @author Usman Ali <usman.ali@people.com.pk>
 */
import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    selector: 'input[alphabetOnly]'
})
export class AlphabetOnlyDirective {

    constructor(private _el: ElementRef) {
    }
    @HostListener('input', ['$event']) onInputChange(event): void {
        debugger
        const initalValue = this._el.nativeElement.value;
        this._el.nativeElement.value = initalValue.replace(/[^-A-z ]*/g, '');
        if (initalValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }

}
