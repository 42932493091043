import { Directive, ElementRef, EventEmitter, HostListener, Output, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[password-hide]',
})
export class PasswordHideDirective {
  constructor(public viewContainerRef: ViewContainerRef, private _el: ElementRef) { }

  @Output() onkeyup: EventEmitter<boolean> = new EventEmitter<false>();

  @HostListener('input', ['$event']) onInputChange(event): void {
    const initalValue = this._el.nativeElement.value;
}

}
