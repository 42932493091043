import {
  HttpClient,
  HttpHeaders,
  HttpBackend,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { APIs } from "src/environments/environment";

import { ObservableService } from "../../util/observablefn.service";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  imageUpload: string = "";
  fileUrl: string = "";
  headers: HttpHeaders = new HttpHeaders();
  fileTypes;
  availeFileTypes;
  clickedFile = new BehaviorSubject({});

  private httpClient: HttpClient;

  constructor(
    private http: HttpClient,
    private os: ObservableService,
    private handler: HttpBackend
  ) {
    this.httpClient = new HttpClient(handler);
    this.imageUpload = APIs["imageUpload"];
    this.fileUrl = APIs["fileUpload"];
    this.headers = this.os.headers();

    this.headers.set("content-type", "multipart/form-data");

    this.fileTypes = {
      "image/png": true,
      "image/jpeg": true,
      "image/jpg": true,
      "application/pdf": true,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        true,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": true,
      "application/msword": true,
      "application/vnd.ms-excel": true,
      "text/csv": true,
    };

    this.availeFileTypes = {
      png: "image/png",
      jpeg: "image/jpeg",
      jpg: "image/jpg",
      pdf: "application/pdf",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      doc: "application/msword",
      csv: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
  }

  validateAttachment(file, fileType = null, userId) {
    let formData: FormData = new FormData();

    if (this.fileTypes[file.type]) {
      if (fileType != null) {
        console.log(this.availeFileTypes[fileType]);

        console.log(fileType);
        console.log(file.type);
        if (fileType != file.type) {
          return false;
        }
      }
      formData.append("image", file);
      let loggedinUser: any = Number(localStorage.getItem("e_number"));
      console.log(loggedinUser);
      if (!loggedinUser) {
        loggedinUser = -1;
      }
      if (userId) {
        loggedinUser = userId;
      }
      if (userId) formData.append("user_id", userId);
      formData.append("uploaded_by", loggedinUser);
      return formData;
    }
    return false;
  }

  accessFile(fileId) {
    return new Promise(async (resolve, reject) => {
      const accessFileResponse = { success: true, data: "", error: "" };
      const result = await this.os.asSubscribed(this.getFile(fileId));
      if (result.status) {
        accessFileResponse.data = result.payload.url;
        resolve(accessFileResponse);
        return;
      }

      accessFileResponse.success = false;
      accessFileResponse.error = result.message;
      resolve(accessFileResponse);
    });
  }

  getFile(fileId) {
    // return this.http.post(`${this.fileUrl}` ,{
    //   headers : this.os.headers(),
    //   params: {fileId}
    // })

    return this.http.get(`${APIs.getFile}?fileId=${fileId}`, {
      headers: this.os.headers(),
    });
  }

  uploadFile(file, fileType = null, userId = null) {
    return new Promise(async (resolve, reject) => {
      const fileUploadResponse = { success: true, data: "", error: "" };

      const fileData = this.validateAttachment(file, fileType, userId);
      if (!fileData) {
        console.log("error");
        fileUploadResponse.success = false;
        fileUploadResponse.data = null;
        fileUploadResponse.error = "invalid file is selected";
        resolve(fileUploadResponse);
        return;
      }
      console.log(fileData.get("image"));

      const result = await this.os.asSubscribed(this.upload(fileData));

      console.log(result);

      if (result.status) {
        console.log("in serivce of ressolved");
        console.log(result);

        fileUploadResponse.success = true;
        fileUploadResponse.data = result.payload;
        fileUploadResponse.error = null;

        console.log(fileUploadResponse);
        fileUploadResponse.data["id"] = `${fileUploadResponse.data["id"]}`;
        resolve(fileUploadResponse);
        return;
      }
      fileUploadResponse.success = false;
      fileUploadResponse.error = result.message;
      resolve(fileUploadResponse);
    });
  }

  upload(formData) {
    return this.http.post(`${this.imageUpload}`, formData, {
      headers: this.headers,
    });
  }

  getFileTypes() {
    return this.availeFileTypes;
  }

  downloadFile(url): any {
    const headers = new HttpHeaders();
    headers.delete("Authorization");
    console.log(headers);
    return this.httpClient.get(`${url}`, { headers, responseType: "blob" });
  }

  downloadReqFile(id): any {
    return this.http.get(`${APIs.getFile}/download?fileId=${id}`);
  }
}
