<div class="loader-wrapper" *ngIf="showLoader">
  <div id="loader-body1" class="loading" >
    <div class="loading-bar"></div>
    <div class="loading-bar"></div>
    <div class="loading-bar"></div>
    <div class="loading-bar"></div>
    <div class="loading-bar"></div>
    <div class="loading-bar"></div>
  </div>
</div>
