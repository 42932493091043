import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-people-frame',
  templateUrl: './people-frame.component.html',
  styleUrls: ['./people-frame.component.css']
})
export class PeopleFrameComponent implements OnInit {

  constructor(public loaderService : LoaderService) { }

  ngOnInit() {

    let checkPortal = (localStorage.getItem('isClientPortals') && localStorage.getItem('isClientPortals') === 'true') ? 
                        true : false;

    if(checkPortal) {

      this.loaderService.appBackground.next('full-page-background');

      this.loaderService.appFooterBackground.next('footer_wrapper');

      this.loaderService.appMenuBackground.next('main_navigation');

      this.loaderService.appSecondTierMenuBackground.next('second_tier_menu');

      this.loaderService.appSecondTierMenuHover.next('hover_color');

      this.loaderService.appBackground.subscribe((res:string) => {
        localStorage.setItem('dashboard_color',res)
      })
      this.loaderService.appFooterBackground.subscribe((res:string) => {
        localStorage.setItem('footer_color',res)
      })
      this.loaderService.appMenuBackground.subscribe((res:string) => {
        localStorage.setItem('menu_color',res)
      })
      this.loaderService.appSecondTierMenuBackground.subscribe((res:string) => {
        localStorage.setItem('second_tier_menu_color',res)
      })
      this.loaderService.appSecondTierMenuHover.subscribe((res:string) => {
        localStorage.setItem('second_tier_menu_hover_color',res)
      })

    }
    else {

      this.loaderService.appBackground.next('full-page-background1');

      this.loaderService.appFooterBackground.next('footer_wrapper1');

      this.loaderService.appMenuBackground.next('main_navigation1');

      this.loaderService.appSecondTierMenuBackground.next('second_tier_menu1');

      this.loaderService.appSecondTierMenuHover.next('hover_color1');

      this.loaderService.appBackground.subscribe((res:string) => {
        localStorage.setItem('dashboard_color',res)
      })
      this.loaderService.appFooterBackground.subscribe((res:string) => {
        localStorage.setItem('footer_color',res)
      })
      this.loaderService.appMenuBackground.subscribe((res:string) => {
        localStorage.setItem('menu_color',res)
      })
      this.loaderService.appSecondTierMenuBackground.subscribe((res:string) => {
        localStorage.setItem('second_tier_menu_color',res)
      })
      this.loaderService.appSecondTierMenuHover.subscribe((res:string) => {
        localStorage.setItem('second_tier_menu_hover_color',res)
      })

    }

    
  }

}
