import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Location } from "@angular/common";
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  DialogPosition,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { color } from "highcharts";
import moment from "moment";
import { NotificationPopupComponent } from "../../dialogs/notification-popup/notification-popup.component";
import { GetNotificationsService } from "../../services/get-notifications.service";
import { HelpersService } from "../../util/helpers.service";
import { ObservableService } from "../../util/observablefn.service";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";
import { MessageService } from "src/app/services/message.service";
import * as config from "../../../../../../config.json";

@Component({
  selector: "app-notifications-panel",
  templateUrl: "./notifications-panel.component.html",
  styleUrls: ["./notifications-panel.component.scss"],
  animations: [
    trigger("openClose", [
      state("true", style({ display: "block"})),
      state("false", style({ display: "none",'max-height': '0' })),
      transition("maximize => minimize", [animate("100ms ease-in-out")]),
      transition("minimize => maximize", [animate("100ms ease-in-out")]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
  @Input() hideBackBtn: boolean;
  @Input() showViewAll: boolean;
  @Input() openNotificationDialog: boolean;
  @Input() sliceData: boolean;
  @Input() ellipsisText: boolean;
  @Input() minHeight: boolean;
  @Input() isFitImage: boolean;
  @Input() maxHeight: string;

  @ViewChild("notificationRef") elementRef: ElementRef;

  isExpandPanel: boolean = true;
  notification :any= {};

  stateUserNotification: string = "maximize";

  notifications = [];
  unreadNotificationCount = 0;

  pageSize: number = 8;
  pageNumber: number = 1;
  disableInfinitScroll: Boolean = false;

  colors = {};

  constructor(
    private dialog: MatDialog,
    private os: ObservableService,
    private gns: GetNotificationsService,
    private router: Router,
    private location: Location,
    private hs: HelpersService,
    public authService: AuthService, private messageService: MessageService
  ) {
    console.log("in show notification");
  }

  ngOnInit(): void {
    this.getAllNotifications();
    this.getUnreadNotificationCount();
    // this.getColors();
  }

  async getColors() {
    const colorsArray = (await this.os.asPromised(this.hs.getColors())).payload.colors;
    // this.hs.getColors().subscribe((resp:any) =>{
      colorsArray.forEach(color =>{
        this.colors[`${color.color_key}`] = `${color.color_code}`
      // },err=>{
      //   console.log(err)
      // });
    })
  }


  redirect(notification){
    this.getSingleNotification(notification.userNotificationId);
    notification.is_read == 0
    if(notification.is_angular == 2){
      document.location.href = config.fileDirUrl+notification.link;
    }
    else if(notification.is_angular == 1){
      this.router.navigate([notification.link])
    }
    else{
      const getLink = localStorage.getItem('link');
        const getENumber = localStorage.getItem('e_number');
        const destinationRequest = notification.link;
        const portal = (typeof getLink != 'undefined' && getLink != 'null') ? getLink : '';
        const employeeId = (typeof getENumber != 'undefined' && getENumber != 'null') ? getENumber : '';
        const sourceRequest = this.router.url.split('/').pop();
        this.authService.switchSeamlessportal(destinationRequest, portal, employeeId, sourceRequest).subscribe(data => {
          if (data.status == '200' && data.loginId != 'false') {
            let routingLink = environment.phpSiteUrl + "?u=" + data.loginId + "&mt=aes";
            window.open(routingLink, '_self');
          }
          else {
            alert('Something went wrong cannot proceed your request');
          }
        });
        this.messageService.sendMessage({ Component: "Header", For: "SeamlessForm", Data: { fileName: destinationRequest } });
    }
  }

  minMaxUserNotification() {
    if (this.stateUserNotification == "maximize")
      this.stateUserNotification = "minimize";

    else if (this.stateUserNotification == "minimize")
      this.stateUserNotification = "maximize";
  }

  async getUnreadNotificationCount() {
    // const response = await this.os.asPromised(this.gns.getUnreadNotificationCount());
    this.gns.getUnreadNotificationCount().subscribe((response:any)=>{

    this.unreadNotificationCount = response.payload.count;
    },err=>{
      console.log(err)
    })

  }



  decreaseCount(notification){

    if(notification['is_read'] == 0)
    {
      this.unreadNotificationCount = this.unreadNotificationCount - 1;
    }
  }
  async getAllNotifications(pageNumber = this.pageNumber,pageSize = this.pageSize) {
    // const response = await this.os.asPromised(this.gns.getUserNotifications(pageNumber, pageSize));
    this.gns.getUserNotifications(pageNumber, pageSize).subscribe((response:any) =>{
      for (const iterator of response.payload.notifications) {
        if(iterator.role == 'System') iterator.role = 'SYS'
        if(iterator.role == 'Employee') iterator.role = 'EMP'
      }

      if (this.notifications.length == 0 && response.payload.pageNumber == 1)
        this.notifications = response.payload.notifications;
      else if (
        response.payload.pageNumber == 1 &&
        response.payload.notifications.length == 0
      )
        this.notifications = [];
      else if (this.notifications.length > 0 && response.payload.pageNumber > 1)
        this.notifications = this.notifications.concat(
          response.payload.notifications
        );
      else if (
        response.payload.pageNumber > 1 &&
        response.payload.notifications.length == 0
      )
        this.disableInfinitScroll = true;
      console.log(this.notifications);
    },err=>{
      console.log(err)
    })


  }

  getDataOnScroll() {
    this.pageNumber = this.pageNumber + 1;
    this.getAllNotifications(this.pageNumber, this.pageSize);
  }

  async getSingleNotification(id) {
    const response = await this.os.asPromised(this.gns.getSingleUserNotification(id));
    this.getUnreadNotificationCount();
  }

  openDialog(id) {
    if (this.openNotificationDialog) {
      const coords: DOMRect =
        this.elementRef.nativeElement.getBoundingClientRect();
      console.log(coords);

      const dialogRef: MatDialogRef<NotificationPopupComponent, any> =
        this.dialog.open(NotificationPopupComponent, {
          data: { id },
          backdropClass: ["custom-backdrop", "module-style"],
          panelClass: ["custom-panel", "module-style"],
        });
      this.getUnreadNotificationCount();
      this.setDialogPosition(coords, dialogRef);
    }
  }

  ngOnDestroy(){
    this.dialog.closeAll()
  }

  gotoAllNotifications() {
    this.router.navigate(["nms/all-notifications"]);
  }

  navigateBack() {
    if(!this.hideBackBtn)
      this.location.back();
  }

  parseDate(date) {
    const conversion = moment(date).utc().format('DD MMM YYYY, hh:mm A');
    return conversion;
  }

  setDialogPosition(
    coords: DOMRect,
    dialogRef: MatDialogRef<NotificationPopupComponent, any>
  ) {
    let { top, left, width, height } = coords;

    const backdropCss = `top:${this.openNotificationDialog ? top : top + 11}px;left:${left+10}px;width:${width - 20}px;height:${this.openNotificationDialog ? height : height - 12}px`;
    const customPanelCss = `height:${height - 80}px;width:${width - 96}px`;
    document
      .getElementsByClassName("custom-backdrop")[0]
      .setAttribute("style", backdropCss);
    document
      .getElementsByClassName("custom-panel")[0]
      .setAttribute("style", customPanelCss);

    const dialogTop = top + 100 / 2 - 111;

    const dialogLeft = coords.left + 96 / 2;
    const position: DialogPosition = {
      top: `${dialogTop}px`,
      left: `${dialogLeft}px`,
    };
    dialogRef.updatePosition(position);
  }

  verifyImage(e, imgUrl){
    if(e.type == 'error') {
      imgUrl.micro_picture = 'assets/images/user.png'
    }
  }

}
