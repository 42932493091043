<div class="module-style">
  <notification-settings
    (onResetFilters)="onResetFilters()"
    [filters]="{ filters: filters }"
    [userCount]="userCount"
    [portal]="'hr'"
    [companyId]="clientId"
  >
    <form [formGroup]="form">
      <div class="emp-content justify-bw p-cus-0 border-0 row m-0">
        <!--
          Company
        -->
        <div class="select-wrapper w-cus-260 px-cus-0 col-3">
          <custom-select
            [form]="form"
            [isLoaded]="isLoaded.company"
            [data]="locations.company"
            [label]="'Company'"
            [control]="'companyId'"
            (onClick)="getLocation('getClientList', 'company', {})"
            (selectionChange)="
              [
                updateClientId(form.controls.companyId.value),
                getRegion('getMarket', 'market', {}),
                getUserCount()
              ]
            "
            (onResetDropDown)="onResetDropDown('company')"
          ></custom-select>
        </div>
        <!--
          Market
        -->
        <div class="select-wrapper w-cus-260 px-cus-0 col-3">
          <custom-select
            (selectionChange)="
              checkIfClusterExists({ market_id: form.get('marketId').value })
            "
            (onResetDropDown)="onResetDropDown('market')"
            [form]="form"
            [isLoaded]="isLoaded.market"
            [data]="locations.market"
            [label]="'Market'"
            [control]="'marketId'"
            (onClick)="getLocation('getMarket', 'market', {})"
          ></custom-select>
          <!-- [disabled]="!form.get('companyId').value" -->
        </div>
        <!--
          Cluster
        -->
        <div class="select-wrapper w-cus-260 px-cus-0 col-3">
          <custom-select
            (selectionChange)="
              checkIfSubClusterExists({
                market_id: form.get('marketId').value,
                cluster_id: form.get('clusterId').value
              })
            "
            (onResetDropDown)="onResetDropDown('cluster')"
            [form]="form"
            [isLoaded]="isLoaded.cluster"
            [data]="locations.cluster"
            [label]="'Cluster'"
            [control]="'clusterId'"
            (onClick)="
              getLocation('getCluster', 'cluster', {
                market_id: form.get('marketId').value
              })
            "
          ></custom-select>
          <!-- [disabled]="!form.get('marketId').value" -->
        </div>
        <!--
          Sub Cluster
        -->
        <div class="select-wrapper w-cus-260 px-cus-0 col-3">
          <custom-select
            (selectionChange)="
              checkIfCountryExists({
                market_id: form.get('marketId').value,
                cluster_id: form.get('clusterId').value,
                sub_cluster_id: form.get('subClusterId').value
              })
            "
            (onResetDropDown)="onResetDropDown('subCluster')"
            [form]="form"
            [isLoaded]="isLoaded.subCluster"
            [data]="locations.subCluster"
            [label]="'Sub Cluster'"
            [control]="'subClusterId'"
            (onClick)="
              getLocation('getSubCluster', 'subCluster', {
                market_id: form.get('marketId').value,
                cluster_id: form.get('clusterId').value
              })
            "
          ></custom-select>
          <!--  [disabled]="!form.get('clusterId').value" -->
        </div>
        <!--
          Country
        -->
        <div class="select-wrapper w-cus-260 px-cus-0 col-3">
          <custom-select
            (selectionChange)="
              getLocation('getStatesByCountry', 'state', {
                country_id: form.get('countryId').value
              })
            "
            (onResetDropDown)="onResetDropDown('country')"
            [form]="form"
            [isLoaded]="isLoaded.country"
            [data]="locations.country"
            [label]="'Country'"
            [control]="'countryId'"
            (onClick)="
              checkIfCountryExists({
                market_id: form.get('marketId').value,
                cluster_id: form.get('clusterId').value,
                sub_cluster_id: form.get('subClusterId').value
              })
            "
          ></custom-select>
        </div>
        <!-- [disabled]="
              !(
                (form.get('marketId').value &&
                  form.get('subClusterId').value) ||
                (form.get('marketId').value &&
                  !form.get('clusterId').enabled &&
                  !form.get('subClusterId').enabled) ||
                (form.get('marketId').value &&
                  form.get('clusterId').value &&
                  !form.get('subClusterId').enabled)
              )
            " -->
        <!--
          Province/State
        -->
        <div class="select-wrapper w-cus-260 px-cus-0 col-3">
          <custom-select
            (selectionChange)="
              getLocation('getCityByState', 'city', {
                state_id: form.get('stateId').value
              })
            "
            (onResetDropDown)="onResetDropDown('state')"
            [form]="form"
            [isLoaded]="isLoaded.state"
            [data]="locations.state"
            [label]="'Province/State'"
            [control]="'stateId'"
            (onClick)="
              getLocation('getStatesByCountry', 'state', {
                country_id: form.get('countryId').value
              })
            "
          ></custom-select>
          <!--  [disabled]="!form.get('countryId').value" -->
        </div>
        <!--
          City
        -->
        <div class="select-wrapper w-cus-260 px-cus-0 col-3">
          <custom-select
            (selectionChange)="
              [
                getLocation('getBranch', 'branch', {
                  city_id: form.get('cityId').value
                })
              ]
            "
            (onResetDropDown)="onResetDropDown('city')"
            [form]="form"
            [isLoaded]="isLoaded.city"
            [data]="locations.city"
            [label]="'City'"
            [control]="'cityId'"
            (onClick)="
              getLocation('getCityByState', 'city', {
                state_id: form.get('stateId').value
              })
            "
          ></custom-select>
          <!--  [disabled]="!form.get('stateId').value" -->
        </div>
        <!--
          Branch
        -->
        <div class="select-wrapper w-cus-260 px-cus-0 col-3">
          <custom-select
            (selectionChange)="getUserCount()"
            [form]="form"
            [isLoaded]="isLoaded.branch"
            [data]="locations.branch"
            [label]="'Branch'"
            [control]="'branchId'"
            (onClick)="getLocation('getBranch', 'branch', {})"
          ></custom-select>
        </div>
        <!-- [disabled]="!form.get('cityId').value" -->
        <!--
          Department
        -->
        <div class="select-wrapper w-cus-260 px-cus-0 col-3">
          <custom-select
            (selectionChange)="getUserCount()"
            (onResetDropDown)="onResetDropDown('department')"
            [form]="form"
            [isLoaded]="isLoaded.department"
            [data]="locations.department"
            [label]="'Department'"
            [control]="'departmentId'"
            (onClick)="
              getLocation('getDepartment', 'department', { type: 'abc' })
            "
          ></custom-select>
        </div>
        <!--
          Designation
        -->
        <div class="select-wrapper w-cus-260 px-cus-0 col-3">
          <custom-select
            (selectionChange)="getUserCount()"
            [form]="form"
            [isLoaded]="isLoaded.designation"
            [data]="locations.designation"
            [label]="'Designation'"
            [control]="'designationId'"
            (onClick)="
              getLocation('getDesignation', 'designation', {
                deptId: form.get('departmentId').value
              })
            "
          ></custom-select>
        </div>
        <!--
          Band
        -->
        <div class="select-wrapper w-cus-260 px-cus-0 col-3">
          <custom-select
            (selectionChange)="getUserCount()"
            [form]="form"
            [isLoaded]="isLoaded.band"
            [data]="locations.band"
            [label]="'Band'"
            [control]="'bandId'"
            (onClick)="getLocation('getBand', 'band', {})"
          ></custom-select>
          <!--  branch_id: form.get('branchId').value,
                band_id: form.get('bandId').value -->
        </div>
        <div class="select-wrapper w-cus-260 px-cus-0 col-3"></div>
      </div>
    </form>
  </notification-settings>
</div>
