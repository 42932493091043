import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: "app-bulk-file-uploader",
  templateUrl: "./bulk-file-uploader.component.html",
  styleUrls: ["./bulk-file-uploader.component.scss"],
})
export class BulkFileUploaderComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() multiple: boolean;
  @Input() templateLink: string;
  @Input() allowedExtension: Array<any> = ["csv"];
  @Output() uploadFile = new EventEmitter();

  tableDataSource;
  columnNames;
  headers;
  file: File;
  isErrors = false;

  constructor(private loader: LoaderService, private toastr: ToastrService) {

  }

  ngOnInit(): void {
    // This will return error if allowedExtension have other type than array
    if (!this.isArray(this.allowedExtension)) {
      throw new Error("allowedExtension is Required As Array");
    }
  }


  /**
   * This function is usede to check is array or not
   * @param value
   * @returns
   */
  isArray(value: any): boolean {
    return Array.isArray(value);
  }


  // fileExtension == "xlsx" ||
  // fileExtension == "xls" ||
  /**
   * This function is used to handle file extension
   * @param data
   */
  handleFileInput(data) {
    if (data.target.files.length) {
      const file: File = data.target.files[0];
      const fileExtension = file.name.split(".").pop();
      if (this.allowedExtension.includes(fileExtension)) {
        this.file = data.target.files[0] as File;
      } else {
        this.file = null;
        this.toastr.warning("Only Microsoft Excel files are allowed");
      }
    }
  }

  // Get data from parent module with using ViewChild
  setData(res: any) {
    if (res.status == "pass") {
      console.log(res);
      this.configResponse(res);
    } else {
      this.toastr.error(res.message, "error");
    }
  }

  // Used to emit file to parent component
  onSubmit() {
    this.uploadFile.emit(this.file);
  }

  downloadFile(){
    let link = document.createElement("a");
    link.download = "leaveApplication";
    link.href = "assets/bulkReportTemplate/LeaveApplication.csv";
    link.click();
    link.remove();
}
  /**
   * This function is used to show errors in table
   * @param res
   */
  configResponse(res) {
    if (res.errors.length > 0) {
      this.toastr.error("Errors", "error");
      this.isErrors = true;
      this.headers = res.headers;
      this.columnNames = Object.keys(res.errors[0]);
      this.tableDataSource = new MatTableDataSource(res.errors);
      console.log(this.columnNames);
    } else {
      this.toastr.success(res.message, "success");
    }
  }

  /**
   * This function is used to replace keys names with headers from api
   * @param colIndex
   * @returns
   */
  matColumn = (colIndex): any => {
    return this.headers[colIndex];
  };

  /**
   * Check type of value
   * @param value
   * @returns
   */
  typeOf(value) {
    return typeof value;
  }
}
