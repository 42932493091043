<!-- header component -->
<mat-card class="orgChartContainer" id="org_chart_full_view">
  <button id="full_screen" style="display: none;" (click)="full_screen_viewer()">full screen</button>
  <mat-card-header>
    <mat-card-title>
      <h2 class="orgchart_title ">People i <span id="filter_heading">(Default View)</span></h2>
      <div class="chart_nav_menu">
        <img src="../../assets/img/recenter.png" alt="recenter" title="recenter" class="top_img" (click)="_orgChartService.centerMoveChart('init', true)"/>
        <img src="../../assets/img/reload.png" alt="reload" title="reload" class="top_img" id="reset_chart" (click)="_orgChartService.centerMoveChart('init', true)"/>
        <a [routerLink]="'/orgchart'">
          <img src="../../assets/img/collapse.png" alt="collapse" title="collapse" class="top_img" id="btn_expand" />
        </a>
        <img src="../../assets/img/magnifier_zoom_in.png" alt="zoom in" title="zoom in" class="top_img" id="zoom_in" />
        <img src="../../assets/img/magnifier_zoom_out.png" alt="zoom out" title="zoom out" class="top_img"
          id="zoom_out" />
        <img src="../../assets/img/preferences2.png" alt="preferences" title="preferences" class="top_img" id="top_img"
          (click)="open_dropdown()" />

        <div id="leftnav" style="display:none; z-index: 999;">
          <!-- <form> -->
            <div id="expanded_view" style="display: block">
              <div id="expanded_view" class="row m-0 filters">
                <mat-form-field class="m-0 pt-1 pb-1 pr-1 pl-0 col-3 " id="preset_view" >
                  <!-- <mat-label>Preset Views</mat-label> -->
                  <mat-select [(ngModel)]="orgChartView" (ngModelChange)="selectViewType($event)">
                    <mat-option [value]="'1'">Approval Matrix View</mat-option>
                    <mat-option [value]="'2'">Headcount View</mat-option>
                    <mat-option [value]="'3'">Default View</mat-option>
                    <!-- <mat-option value="4">Multi LM</mat-option>
                    <mat-option value="4">Line Manager View</mat-option> -->
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="m-0 p-1 col-3 " id="themes">
                  <!-- <mat-label>Preset Views</mat-label> -->
                  <mat-select [(ngModel)]="orgChartTheme" (ngModelChange)="selectThemeType($event)">
                    <mat-option [value]="'1'">Classic</mat-option>
                    <mat-option [value]="'2'">Advanced I</mat-option>
                    <mat-option [value]="'3'">Advanced II</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="m-0 p-1 col-3 " id="image_colors">
                  <!-- <mat-label>Preset Views</mat-label> -->
                  <mat-select [(ngModel)]="orgChartImage" (ngModelChange)="selectImageType($event)">
                    <mat-option [value]="'1'">Greyscale</mat-option>
                    <mat-option [value]="'2'">Colored</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="m-0 pt-1 pb-1 pr-0 pl-1 col-3 " id="map_themes">
                  <!-- <mat-label>Preset Views</mat-label> -->
                  <mat-select [(ngModel)]="mapTheme" (ngModelChange)="selectMapTheme()">
                    <mat-option [value]="'1'">Green</mat-option>
                    <mat-option [value]="'2'">Blue</mat-option>
                    <mat-option [value]="'3'">Silver</mat-option>
                    <mat-option [value]="'4'">Default</mat-option>
                  </mat-select>
                </mat-form-field>
              <!-- <table cellspacing="0" cellpadding="0" border="0" class="">
                <thead>
                  <tr>
                    <th colspan="2" style="font-weight: 600;">Preset Views</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2">
                      <p class="heading_row">
                        <select (change)=selectViewType($event) [(ngModel)]="orgChartView" id="preset_view" name="preset_view"
                          class="input">
                          <option value="1">Approval Matrix View</option>
                          <option value="2">Headcount View</option>
                          <option value="3">Default View</option>
                        </select>
                      </p>
                    </td>
                  </tr> -->
                  <!-- <tr>
                                <td style="font-weight: normal;font-size: 8pt;">Departments</td>
                                <td>  <input style="margin-left: 10px;" type="checkbox" name="chk_review"  value="0" id="chk_review"/></td>
                            </tr>
                            <tr>
                                <td style="font-weight: normal;font-size: 8pt;">Bands</td>
                                <td> <input style="margin-left: 10px;" type="checkbox" name="chk_review"  value="0" id="chk_review"/></td>
                            </tr>
                            <tr>
                                <td style="font-weight: normal;font-size: 8pt;">Headcount</td>
                                <td> <input style="margin-left: 10px;" type="checkbox" name="chk_review"  value="0" id="chk_review"/></td>
                            </tr>
                            <tr>
                                <td colspan="2"><a href="#" id="button" style="float: right;">View</a></td>
                            </tr> -->
                <!-- </tbody>
              </table>

              <table cellspacing="0" cellpadding="0" border="0" class="">
                <thead>
                  <tr>
                    <th colspan="2" style="font-weight: 600;">Themes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2">
                      <p class="heading_row">
                        <select [(ngModel)]="orgChartTheme" (change)="selectThemeType()" id="themes" name="themes"
                          class="input">
                          <option value="1">Classic</option>
                          <option value="2">Advanced I</option>
                          <option value="3">Advanced II</option>
                        </select>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table cellspacing="0" cellpadding="0" border="0" class="">
                <thead>
                  <tr>
                    <th colspan="2" style="font-weight: 600;">Images</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2">
                      <p class="heading_row">
                        <select [(ngModel)]="orgChartImage" (change)="selectImageType()" id="image_colors"
                          name="image_colors" class="input">
                          <option value="1">Greyscale</option>
                          <option value="2">Colored</option>
                        </select>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table cellspacing="0" cellpadding="0" border="0" class="">
                <thead>
                  <tr>
                    <th colspan="2" style="font-weight: 600;">Map Themes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2">
                      <p class="heading_row">
                        <select [(ngModel)]="mapTheme" (change)="selectMapTheme()" id="map_themes" name="map_themes"
                          class="input">
                          <option value="1">Green</option>
                          <option value="2">Blue</option>
                          <option value="3">Silver</option>
                          <option value="4">Default</option>
                        </select>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table> -->

            </div>

          <!-- </form> -->
          </div>
        </div>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div id="full-map-container">
      <app-orgmap></app-orgmap>
    </div>
    <div class="chart_holder" style="display: none;">
      <a href="#" (click)="closeOrgChart()" id="close_orgchart"><img src="/assets/icons/close-button.svg"></a>
      <a href="#" style="display: none;" title="Head Count Detail" id="show_headCountDetails">
        <i class="fa fa-eye"></i>
      </a>
      <div *ngIf="mapView != 1">
        <div id="chart-container"></div>
      </div>
      <div *ngIf="mapView == 1">
        <app-ml-orgchart></app-ml-orgchart>
      </div>
    </div>
  </mat-card-content>

</mat-card>

<ng-sidebar-container style="height: 100vh;" class="left_drawer">

  <!-- A sidebar -->
  <ng-sidebar [(opened)]="_opened" position="left" id="full_view_sidebar">
    <a (click)="_toggleSidebar()" class="close_sidebar"><img src="/assets/icons/close-button.svg"></a>
    <org-chart-sidebar  [updateButtonCheck]="mapCheck"></org-chart-sidebar>
  </ng-sidebar>

  <!-- Page content -->
  <div ng-sidebar-content>
    <div class="drawer_open">
      <a (click)="_toggleSidebar()">
        <i class="fa fa-bars"></i>
      </a>
    </div>
  </div>

</ng-sidebar-container>

<app-fullchart-detail [orgChartView]="orgChartView"></app-fullchart-detail>

<div id="dynamic_css_handler"></div>
<div id="detail-modal"></div>
<div id="reporting-modal" class="modal fade" role="dialog">
  <div class="modal-dialog" style="width: 100% !important; max-width: 100% !important;">

    <!-- Modal content-->
    <div class="modalcc-content modal-content" style="width: 100% !important; max-width: 100% !important;">
      <button type="button" class="close" data-dismiss="modal">&times;</button>
      <div class="modal-body">
        <div id="reporting_chart"></div>
      </div>
    </div>

  </div>
</div>
<input type="hidden" [(ngModel)]="closeDrawer" name="close_drawer" id="close_drawer" />
