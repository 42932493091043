import * as CryptoJS from 'crypto-browserify';
import * as Crypto from 'crypto-js';

import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import * as createHmac from 'create-hmac';



@Injectable({
  providedIn: 'root'
})
export class CryptoService {
   APP_KEY :string = 'base64:sa9XM2JgyVUjLudyhlBwNw==';
   SETTINGS = {
    key: Buffer.from(this.APP_KEY.substring(7), 'base64'),
    sha: 'sha256',
    mode: 'AES-128-CBC'
  }
  encryptedBase: string;

  constructor() {


  }

 // // Encrypt Message
  encrypt(data: number) {
    let key = "base64:4hG57ByCpPm5j58lw0xwig==";
    let iv = Crypto.enc.Base64.parse("#base64IV#");
    let encrypted = Crypto.AES.encrypt(String(data), key, { iv: iv });
    this.encryptedBase = String(encrypted);
    return btoa(this.encryptedBase);
  }

  //Decrypt Message
  decrypt(data: string) {
    if (data != undefined || data != null) {
      data = atob(data);
      let password = "base64:4hG57ByCpPm5j58lw0xwig==";
      let value = data.replace(" ", "+");
      let decrypted = Crypto.AES.decrypt(String(value), password);
      let id = decrypted.toString(Crypto.enc.Utf8);
      return +id;
    }
  }
  encryptdata(data) {
    let serializedValue = this.hashSerialize(data);
    try {
      let _iv = CryptoJS.randomBytes(16);
      let base64_iv = _iv.toString('base64');
      let cipher = CryptoJS.createCipheriv(this.SETTINGS.mode, this.SETTINGS.key, _iv);
      let encrypted = cipher.update(serializedValue, 'utf8', 'base64');
      encrypted += cipher.final('base64');
      let _mac = this.hash(base64_iv, encrypted);
      let payloadObject = {
        'iv': base64_iv,
        'value': encrypted,
        'mac': _mac
      }
      let _payload = JSON.stringify(payloadObject);
      let base64_payload = Buffer.from(_payload).toString('base64');
      return base64_payload;
    } catch (e) {
      throw new Error('Cannot encrypt data provided !');
    }
  }
  decryptdata(payload) {
    let _payload = this.getJsonPayload(payload);
    let _iv = Buffer.from(_payload['iv'], 'base64');
    let decipher = CryptoJS.createDecipheriv(this.SETTINGS.mode, this.SETTINGS.key, _iv);
    let decrypted = decipher.update(_payload['value'], 'base64', 'utf8');
    decrypted += decipher.final('utf8');
    return this.hashDeserialize(decrypted);
  }
  getJsonPayload(payload) {
    if (payload === undefined || payload === '') {
      throw new Error('Payload MUST NOT be empty !');
    }
    if (typeof payload !== 'string') {
      throw new Error('Payload MUST be string !');
    }
    try {
      var _payload = JSON.parse(Buffer.from(payload,'base64').toString());
    } catch (e) {
      throw new Error('Payload cannot be parsed !');
    }
    if (!this.isValidPayload(_payload)) {
      throw new Error('Payload is not valid !');
    }
    if (!this.isValidMac(_payload)) {
      throw new Error('Mac is not valid !');
    }
    return _payload;
  }
  hash(iv, value) {
    if (iv === undefined || iv === '') {
      throw new Error('Iv is not defined !');
    }
    if (value === undefined || value === '') {
      throw new Error('Value is not defined !');
    }
    let data = String(iv) + String(value);
    return this.hashHmac(data, this.SETTINGS.key);
  }
  calculateMac(payload, key) {
    let hashedData = this.hash(payload['iv'], payload['value'])
    return this.hashHmac(hashedData, key);
  }
   isValidMac(payload) {
     let bytes = CryptoJS.randomBytes(16),
       calculatedMac = this.calculateMac(payload, bytes);
     let originalMac = this.hashHmac(payload['mac'], bytes);
     return originalMac === calculatedMac;
  }

  hashHmac(data, key) {


    const hmacStr = createHmac(this.SETTINGS.sha, key);
    hmacStr.update(data);
     return hmacStr.digest('hex');

  }
  /**
   * Payload validation function.
   * Payload must be decoded to JSON
   * @param {Object} payload
   */
   isValidPayload(payload) {
    return (payload.hasOwnProperty('iv') && payload.hasOwnProperty('value') && payload.hasOwnProperty('mac'));
  }

   hashDeserialize(data) {
    let str = String(data);
    return str.substring(str.lastIndexOf(':') + 1, str.lastIndexOf(';')).replace(/"/g, '');
  }
  hashSerialize(data) {
    if (typeof data !== 'string') {
      throw new Error('Data to be serialized must be type of string !');
    }
    let str = String(data);
    return 's:' + str.length + ':"' + str + ';"';
  }


}
