<form #myForm [formGroup]="seamlessForm" ngNoForm name="myForm" [action]="apiURL" method="POST">


  <input type="hidden" formControlName="tokenNda" name="tokenNda" >
  <input type="hidden" formControlName="token" name="token" >
  <input type="hidden" formControlName="encryptedToken" name="encryptedToken" >


  <input type="hidden" formControlName="empID" name="empID" >


  <input type="hidden" formControlName="portalLink" name="portalLink" >
  <input type="hidden" formControlName="fileLink" name="fileLink" >

  <input type="hidden" formControlName="portalID" name="portalID" >
  <input type="hidden" formControlName="parentPortalID" name="parentPortalID" >
  <input type="hidden" formControlName="subPortalID" name="subPortalID" >
  <input type="hidden" formControlName="portalName" name="portalName" >
  <input type="hidden" formControlName="selectedPortalName" name="selectedPortalName" >


  <input type="hidden" name="appToken" formControlName="appToken">


  <button type="submit" [hidden]="true">Submit</button>
</form>
