<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="page-breadcrumb">
  <div class="align-self-center">
    <div class="d-flex align-items-center">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs" let-last="last">
            <li class="breadcrumb-item" *ngIf="!last" [routerLink]="breadcrumb.url" [queryParams]="breadcrumb.params">
              <a href='javascript:void(0)'>{{breadcrumb.label}}</a>
            </li>
            <li class="breadcrumb-item active" *ngIf="last"><span>{{breadcrumb.label}}</span></li>
          </ng-template>
        </ol>
      </nav>
    </div>
  </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
