<mat-card class="filterSideBar">
    <mat-card-header>
        <mat-card-title>
            <h2 class="filter_title">{{filters_heading}}</h2>
            <span (click)="show_applied_filters()" id="applied_filter">
                <span class="applied_filters">{{total_applied_filters}}</span>
                <i class="fa fa-info-circle {{activeInfo}}" aria-hidden="true"></i>
                <div id="infoNav" class="table-responsive" style="display:none; z-index: 999;">
                    <table class="table table-striped table-bordered table_styling" id="list_table">
                        <tr>
                            <td class="first_column">
                                <p class="filter_style_change">
                                    Country
                                </p>
                            </td>
                            <td>
                                <p class="filter_style" *ngFor="let item of applied_filters.country; let i = index">
                                    {{item}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="first_column">
                                <p class="filter_style_change">
                                    City
                                </p>
                            </td>
                            <td>
                                <p class="filter_style" *ngFor="let item of applied_filters.city; let i = index">
                                    {{item}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="first_column">
                                <p class="filter_style_change">
                                    Branch
                                </p>
                            </td>
                            <td>
                                <p class="filter_style" *ngFor="let item of applied_filters.branch; let i = index">
                                    {{item}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="first_column">
                                <p class="filter_style_change">
                                    job Group
                                </p>
                            </td>
                            <td>
                                <p class="filter_style" *ngFor="let item of applied_filters.jGroup; let i = index">
                                    {{item}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="first_column">
                                <p class="filter_style_change">
                                    job Family
                                </p>
                            </td>
                            <td>
                                <p class="filter_style" *ngFor="let item of applied_filters.jFamily; let i = index">
                                    {{item}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="first_column">
                                <p class="filter_style_change">
                                    Department
                                </p>
                            </td>
                            <td>
                                <p class="filter_style" *ngFor="let item of applied_filters.department; let i = index">
                                    {{item}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="first_column">
                                <p class="filter_style_change">
                                    Designation
                                </p>
                            </td>
                            <td>
                                <p class="filter_style" *ngFor="let item of applied_filters.designation; let i = index">
                                    {{item}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="first_column">
                                <p class="filter_style_change">
                                    Band
                                </p>
                            </td>
                            <td>
                                <p class="filter_style" *ngFor="let item of applied_filters.band; let i = index">
                                    {{item}}
                                </p>
                            </td>
                        </tr>
                    </table>
                </div>
            </span>

        </mat-card-title>
    </mat-card-header>
    <form [formGroup]="appliedFilterForm" class="pt-3">
      <mat-card-content>
          <mat-form-field class="reduce_spacing col-xs-12 m-0">
              <mat-select [multiple]="true" placeholder="{{countryPlaceHolder}}" formControlName="country"
                  (ngModelChange)="changeCountry()">
                  <mat-option *ngFor="let item of countryItems" [value]="item.country_id">
                      {{item.country}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field class="m-0">
              <mat-select [multiple]="true" placeholder="{{cityPlaceHolder}}" formControlName="city"
                  (ngModelChange)="changeCity()">
                  <mat-option *ngFor="let item of cityItems" [value]="item.id">
                      {{item.city_name}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field class="m-0">
              <mat-select [multiple]="true" placeholder="{{branchPlaceHolder}}" formControlName="branch"
                  (ngModelChange)="changeBranch()">
                  <mat-option *ngFor="let item of branchItems" [value]="item.loc_id">
                      {{item.loc_desc}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field class="m-0">
              <mat-select [multiple]="true" placeholder="{{jobGroupPlaceHolder}}" formControlName="jGroup"
                  (ngModelChange)="changeJobGroup()">
                  <mat-option *ngFor="let item of jobGroupItems" [value]="item.department_group2_id">
                      {{item.department_group2}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field class="m-0">
              <mat-select [multiple]="true" placeholder="{{jobFamilyPlaceHolder}}" formControlName="jFamily"
                  (ngModelChange)="changeJobFamily()">
                  <mat-option *ngFor="let item of jobFamilyItems" [value]="item.department_group3_id">
                      {{item.department_group3}}
                  </mat-option>
              </mat-select>
          </mat-form-field>

          <mat-form-field class="m-0">
            <mat-select [multiple]="true" placeholder="{{departmentPlaceHolder}}" formControlName="department" [(ngModel)]="selectedDepartments" (ngModelChange)="changeDepartment()">
              <mat-option *ngFor="let item of departmentItems" [value]="item.id">
                {{item.department}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="m-0">
            <mat-select [multiple]="true" placeholder="{{designationPlaceHolder}}" formControlName="designation">
              <mat-option *ngFor="let item of designationItems" [value]="item.designation_id">
                {{item.designation_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <mat-form-field class="m-0">
              <mat-select [multiple]="true" placeholder="{{departmentPlaceHolder}}" formControlName="department"
                  (ngModelChange)="changeDepartment()">
                  <mat-option *ngFor="let item of departmentItems" [value]="item.id">
                      {{item.department}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field class="m-0">
              <mat-select [multiple]="true" placeholder="{{designationPlaceHolder}}"
                  formControlName="designation">
                  <mat-option *ngFor="let item of designationItems" [value]="item.designation_id">
                      {{item.designation_name}}
                  </mat-option>
              </mat-select>
          </mat-form-field> -->
          <mat-form-field class="m-0">
              <mat-select name="band" [multiple]="true" placeholder="{{bandPlaceHolder}}" formControlName="band">
                  <mat-option *ngFor="let item of bandItems" [value]="item.id">
                      {{item.band_desc}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <div class="btn-group special" role="group">
              <div class="d-flex justify-content-end">
                  <!-- <div class="col"> -->
                      <button [disabled]="updateButtonCheck | async" id="button" class="mr-1" (click)="formSubmit()">Update</button>
                  <!-- </div>
                  <div class="col"> -->
                      <button [disabled]="updateButtonCheck | async" id="button" (click)="clearForm()">Clear</button>
                  <!-- </div> -->
              </div>
          </div>

      </mat-card-content>
    </form>

</mat-card>
<input type="hidden" id="country" value="{{countrySelectedValue}}" />
<input type="hidden" id="city" value="{{citySelectedValue}}" />
<input type="hidden" id="branch" value="{{branchSelectedValue}}" />
<input type="hidden" id="job_group" value="{{jobGroupSelectedValue}}" />
<input type="hidden" id="job_family" value="{{jobFamilySelectedValue}}" />
<input type="hidden" id="department" value="{{departmentSelectedValue}}" />
<input type="hidden" id="designation" value="{{designationSelectedValue}}" />
<input type="hidden" id="band" value="{{bandSelectedValue}}" />
