import { NgModule } from '@angular/core';
import { FiltersService } from '../../../services/filters.service';
import { CookieService } from 'ngx-cookie-service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TabLinkComponent } from '../../tabs/tab-link.component';
import { TabsComponent } from '../../tabs/tabs.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { ComponentDirective } from '../../directive/component.directive';
import { BreadcrumbComponent } from '../../breadcrumb/breadcrumb.component'
import { BreadcrumbService } from '../../breadcrumb/breadcrumb.service';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
};

import {
  FontAwesomeModule,
  FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faCaretUp,
  faCaretDown,
  faExclamationTriangle,
  faFilter,
  faTasks,
  faCheck,
  faSquare,
  faLanguage,
  faPaintBrush,
  faLightbulb,
  faWindowMaximize,
  faStream,
  faBook
} from '@fortawesome/free-solid-svg-icons';
import { faMediumM, faGithub } from '@fortawesome/free-brands-svg-icons';
import { CdkTableModule } from '@angular/cdk/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { SlabActionComponet } from '../slab-action/slab-action.component';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PasswordHideDirective } from '../../directive/hidePassword.directive';
import { DigitOnlyDirective } from '../../directive/digitOnly.directive';
import { DigitWithBackSlashDirective } from '../../directive/digitWithBackSlash.directive';
import { AlphabetOnlyDirective } from '../../directive/alphabetOnly.directive';
import { FloatNumbersDirective } from '../../directive/floatNumbers.directive';
import { TruncateTextPipe } from '../../pipes/truncate-text.pipe';
import { ColumnChartsComponent } from 'src/app/modules/bl-analytics/charts/column-charts/column-charts.component';
import { LineChartComponent } from 'src/app/modules/bl-analytics/charts/line-chart/line-chart.component';
import { MatSortModule } from '@angular/material/sort';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';

@NgModule({
  declarations: [
    TabsComponent,
    TabLinkComponent,
    ComponentDirective,
    DigitOnlyDirective,
    DigitWithBackSlashDirective,
    AlphabetOnlyDirective,
    FloatNumbersDirective,
    PasswordHideDirective,
    BreadcrumbComponent,
    BaseComponentComponent,
    SlabActionComponet,
    TruncateTextPipe ,
    ColumnChartsComponent,
    LineChartComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCardModule,
    MatCheckboxModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,
    CdkTableModule,
    MatFormFieldModule,
    FontAwesomeModule,
    MatGridListModule,
    MatRadioModule,
    MatSidenavModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatToolbarModule,
    PerfectScrollbarModule,
    ConfirmDialogModule,
    MatAutocompleteModule,
    ChartModule
  ],
  exports: [
    CommonModule,
    MatPaginatorModule,
    CdkTableModule,
    MatFormFieldModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    MatChipsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    FontAwesomeModule,
    SlabActionComponet,
    TabsComponent,
    TabLinkComponent,
    ComponentDirective,
    PasswordHideDirective,
    DigitOnlyDirective,
    FloatNumbersDirective,
    DigitWithBackSlashDirective,
    AlphabetOnlyDirective,
    BreadcrumbComponent,
    MatRadioModule,
    MatSidenavModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatToolbarModule,
    PerfectScrollbarModule,
    MatAutocompleteModule,
    TruncateTextPipe,
    ColumnChartsComponent,
    LineChartComponent,
    ChartModule
  ]
})

export class SharedModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons();
  }
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [
        FiltersService,
        CookieService,
        BreadcrumbService,
        {
          provide: PERFECT_SCROLLBAR_CONFIG,
          useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        // { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting ] }
      ],
    };
  }
}

