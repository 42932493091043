<div class="module-style">
  <mat-form-field class="cus-m-0" (click)="onClickSelect()" appearance="fill">
    <mat-label>{{ label }}</mat-label>
    <mat-select
      (selectionChange)="[(searchedFilter = locData['name']), getSelectData()]"
      panelClass="options-panel"
      [formControl]="form.get(control)"
      [disabled]="disabled"
      (openedChange)="[(locData['name'] = searchedFilter)]"
    >
      <mat-option *ngIf="!isLoaded" class="module-style">
        <div class="skeleton-loader progress pulse"></div>
      </mat-option>
      <mat-option *ngIf="!isLoaded" class="module-style">
        <div class="skeleton-loader progress pulse"></div>
      </mat-option>
      <mat-option *ngIf="!isLoaded" class="module-style">
        <div class="skeleton-loader progress pulse"></div>
      </mat-option>
      <input
        [(ngModel)]="locData['name']"
        name="searchWord"
        *ngIf="data.length > 10 && isLoaded"
        [ngModelOptions]="{ standalone: true }"
        type="text"
        class="search-input"
        placeholder="Search"
      />
      <mat-option *ngIf="data.length == 0 && isLoaded"
        >No Record Found</mat-option
      >

      <mat-option
        *ngFor="let item of data | searchFilter : locData['name'] : 'name'"
        [hidden]="!isLoaded"
        [value]="item.id"
        [disabled]="item.id == -5"
        >{{ item.name || item.label }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
