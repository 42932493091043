import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import Highchart from 'highcharts';
import { Subscription } from 'rxjs';
import { ICharts } from '../chart.modal';
import { ChartService } from '../chart.service';

@Component({
  selector: 'app-column-charts',
  templateUrl: './column-charts.component.html',
  styleUrls: ['./column-charts.component.css']
})
export class ColumnChartsComponent implements OnInit, AfterViewChecked, OnDestroy{
  data : any
  subscribtion: Subscription
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _chartService: ChartService
  ) { }
  ngOnInit(): void {
   this.subscribtion = this._chartService.getColumnChartSeriesData.subscribe( data => {
      this.data = data
      this.chart();
      this._changeDetectorRef.markForCheck();
    })
  }
  ngAfterViewChecked(): void {
    // const borderRadius = require("highcharts-border-radius");
    // borderRadius(Highchart);
    // this.chart();
  }
  chart = () => {
      Highchart.chart("column-chart", {
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
          symbolHeight: 9,
          symbolWidth: 6,
          symbolRadius: 6,
          showInLegend: true,
          itemStyle: {
            fontWeight: "500",
            fontSize: "11px",
          }
        },
        xAxis: {
          type: "category",
          categories: this.data.categories,
        },
        yAxis: this.data.yAxis,
        plotOptions: {
          series: {
            marker: {
              enabled: false,
            },
          },
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          column: {
            borderRadiusTopLeft: 5,
            borderRadiusTopRight: 5,
        }
        },

        tooltip: {
          headerFormat: `<div>Date: {point.key}</div>`,
          pointFormat: `<div>{series.name}: {point.y}</div>`,
          shared: true,
          useHTML: true,
        },
        series: this.data.chartData,
      } as any);

  }

  ngOnDestroy(): void {
    this.subscribtion.unsubscribe()
  }
}
