import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MapThemeEmitterService } from '../../services/map-theme-emitter.service';
import { HeaderService } from '../../services/header.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { OrgchartService } from 'src/app/services/orgchart.service';
import { FormBuilder, FormGroup } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-orgchart',
  templateUrl: './orgchart.component.html',
  styleUrls: ['./orgchart.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OrgchartComponent implements OnInit, OnDestroy {
  mapCheck = new Subject<boolean>();
  openDrawer = 0;

  orgChartView = "3";
  selectedOptionLabel: string = '';

  options = [
    { value: '1', label: 'Approval Matrix View' },
    { value: '2', label: 'Headcount View' },
    { value: '3', label: 'Default View' },
    // Add more options as needed
  ];
  orgChartTheme =  "1";

  orgChartImage = "1";

  mapView: any = 3;
  mapTheme:any = 1;
  counterChecker = 0;
  counter = 0;

  matrix = null;
  oldId = null;
  popupViewer = false;

  closeDrawer: any = 0;
  // (click)="reload()"
  constructor(
    private mapThemeEmitter: MapThemeEmitterService,
    private headerService: HeaderService,
    private router: Router,
    public _orgChartService: OrgchartService,
    private fb : FormBuilder
  ) { }

  ngOnInit() {
    this.mapCheck.next(false);
    if (performance.navigation.type == 1) {

      this.mapView = 3;

    }
    else {

      this.mapView = this.orgChartView;

    }

    const url = this.router.url.substr(1);
    this.headerService.getMenusByPage(url);
    this.orgChartView = this._orgChartService.orgChartView.value;
    this.orgChartTheme = this._orgChartService.orgChartTheme.value;
    this.orgChartImage = this._orgChartService.orgChartImage.value;
  }

  ngDoCheck() {

    if ($("#close_drawer").val() == "1") {

      if ($('.chart_holder').is(":visible") === false) {

        this.close_dropdown();

      }
      else {

        this.close_dropdown();

      }

      $("#close_drawer").val("0");

    }

  }

  open_dropdown() {
    if (this.openDrawer == 0) {
      $('#leftnav').stop().slideDown(200);
      this.openDrawer = 1;
    }
    else {
      $('#leftnav').stop().slideUp(200);
      this.openDrawer = 0;
    }

    if ($('.chart_holder').is(":visible") !== false) {
      $("#map_themes").prop("disabled", true).addClass("disable_input");
      $("#preset_view").removeAttr("disabled").removeClass("disable_input");
      $("#themes").removeAttr("disabled").removeClass("disable_input");
      $("#image_colors").removeAttr("disabled").removeClass("disable_input");
    }
    else {
      $("#map_themes").removeAttr("disabled").removeClass("disable_input");
      $("#preset_view").prop("disabled", true).addClass("disable_input");
      $("#themes").prop("disabled", true).addClass("disable_input");
      $("#image_colors").prop("disabled", true).addClass("disable_input");
    }

  }

  close_dropdown() {
    if (this.openDrawer == 1) {
      setTimeout(() => {
        $('#leftnav').stop().slideUp(200);
        this.openDrawer = 0;
      }, 200);
    }
  }

  selectViewType(map) {
    // this.mapView = (event.target as HTMLInputElement).value;
    this._orgChartService.orgChartView.next(map)
    this._orgChartService.populateOrgChart();
    if (map == 1) {
      this.mapCheck.next(true);
    } else {

      this.mapCheck.next(false);
    }
    // this.close_dropdown();

    // Find the selected option by its value and set its label to orgchart_title
  const selectedOption = this.options.find(option => option.value === map);
  if (selectedOption) {
    this.selectedOptionLabel = selectedOption.label;
  }
  }

  // chartFilterChange(option, number, colorChange){
  //   let colorChange
  //   let filterdata
  //   this._orgChartService.populateOrgChart(option, number, colorChange, filterdata)
  // }


  selectThemeType(e) {
    this._orgChartService.orgChartTheme.next(e)
    this._orgChartService.populateOrgChart();
    // this.close_dropdown();
  }

  selectImageType(e) {
    this._orgChartService.orgChartImage.next(e)
    this._orgChartService.populateOrgChart();

    // this.close_dropdown();
  }

  selectMapTheme() {
    // this.mapThemeEmitter.changeMessage(this.mapTheme);
    this._orgChartService.selectedMapThemeNumber.next(this.mapTheme)
    // this.close_dropdown();
  }

  themeEvent(event) {
    sessionStorage.setItem('themeValue', event.value)
    let dblEvent = new MouseEvent('dblclick', {
      'view': window,
      'bubbles': true,
      'cancelable': true
    });
    document.getElementById('themes').dispatchEvent(dblEvent);
  }

 

  ViewEvent(event) {
    // sessionStorage.setItem('viewValue', event.value)
    // let dblEvent = new MouseEvent('dblclick', {
    //   'view': window,
    //   'bubbles': true,
    //   'cancelable': true
    // });
    // document.getElementById('preset_view').dispatchEvent(dblEvent);
  }

  imageEvent(event) {
    sessionStorage.setItem('imageValue', event.value)
    let dblEvent = new MouseEvent('dblclick', {
      'view': window,
      'bubbles': true,
      'cancelable': true
    });
    document.getElementById('image_colors').dispatchEvent(dblEvent);
  }

  ngOnDestroy() {
    sessionStorage.removeItem('themeValue')
    sessionStorage.removeItem('viewValue')
    sessionStorage.removeItem('imageValue')
    // this._orgChartService.orgChartView.next("3");
    // this._orgChartService.orgChartTheme.next("1");
    // this._orgChartService.orgChartImage.next("1");
  }

  closeOrgChart(){
    this._orgChartService.activeMapView.next(true)
    this._orgChartService.orgFilterData.next(null)
    this.orgChartView = "3";
    this.orgChartTheme =  "1";
    this.orgChartImage = "1";
    this._orgChartService.orgChartView.next("3");
    this._orgChartService.orgChartTheme.next("1");
    this._orgChartService.orgChartImage.next("1");
  }


}
