import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import Highcharts from "highcharts";
import { Subscription } from "rxjs";
import { ChartService } from "../chart.service";

@Component({
  selector: "app-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.css"],
})
export class LineChartComponent implements OnInit, AfterViewInit, OnDestroy {
  data : any
  subscribtion: Subscription
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _chartService: ChartService
  ) {}

  ngOnInit(): void {
    this.subscribtion =  this._chartService.getLineChartSeriesData.subscribe( data => {
      this.data = data
      this.chart();
      this._changeDetectorRef.markForCheck();
    })
  }
ngAfterViewInit(): void {

}
  chart = () =>
    Highcharts.chart("chart-line", {
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        symbolHeight: 9,
        symbolWidth: 6,
        symbolRadius: 6,
        showInLegend: true,
        itemStyle: {
          fontWeight: "500",
          fontSize: "11px",
        }
      },
      xAxis: {
        type: "category",
        categories: this.data.categories,
      },
      yAxis: this.data.yAxis,
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
        column: {
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
      }
      },

      tooltip: {
        headerFormat: `<div>Date: {point.key}</div>`,
        pointFormat: `<div>{series.name}: {point.y}</div>`,
        shared: true,
        useHTML: true,
      },
      series: this.data.chartData,
    } as any);

    ngOnDestroy(): void {
      this.subscribtion.unsubscribe()
    }
}
