import { Component, OnInit } from '@angular/core';
import * as shape from 'd3-shape';
import { Layout, Edge, Node, Graph } from '../lib/models';
import { DagreNodesOnlyLayout } from '../../../shared/form/customDagreNodesOnly';
import { OrgChartService } from '../../../services/org-chart.service';
import { Router } from '@angular/router';
import { FiltersService } from '../../../services/filters.service';

@Component({
  selector: 'app-ml-orgchart',
  templateUrl: './ml-orgchart.component.html',
  styleUrls: ['./ml-orgchart.component.scss']
})
export class MlOrgchartComponent implements OnInit {

  public nodes: Node[] = [];
  public links: Edge[] = [];
  public layoutSettings = { orientation: 'TB' };
  public curve: any = shape.curveLinear;
  public layout: Layout = new DagreNodesOnlyLayout();

  elem;
  graph: Graph;
  constructor(
    public orgChart: OrgChartService,
    public filtersService: FiltersService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.elem = document.documentElement;
    // this.nodes = this.orgChart.nodeData;
    // this.links = this.orgChart.edgesData;
    this.getChart();
  }

  public getStyles(node: Node): any {
    return {
      'background-color': node.data.backgroundColor,
    };
  }

  getChart() {
    this.orgChart.getChart()
      .subscribe((chart: any) => {
        this.nodes = chart.nodes;
        this.links = chart.conn;
      });
  }

  fullScreenChart() {
    if (this.filtersService.fullScreen) {
      this.router.navigate(['/fullscreen-org-chart']);
      this.filtersService.goToFullScreen();
      return this.filtersService.fullScreen = false;
    } else {
      this.router.navigate(['/org-chart']);
      this.filtersService.closeFullScreen();
      return this.filtersService.fullScreen = true;
    }
  }

}
