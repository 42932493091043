import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { OrgFiltersService } from '../../services/org-filters.service';
import { FilterDataEmitterService } from '../../services/filter-data-emitter.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { OrgchartService } from 'src/app/services/orgchart.service';
import { CountryFilter } from 'src/app/shared/interfaces/country_filter';

declare var $: any;

@Component({
  selector: 'org-chart-sidebar',
  templateUrl: './chart-sidebar.component.html',
  styleUrls: ['./chart-sidebar.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [OrgFiltersService]
})
export class ChartSidebarComponent implements OnInit {
  @Input() updateButtonCheck = new Subject<boolean>();
  selectedDepartments: string[] = []; 

  public countryPlaceHolder: string = 'Country';
  public countrySelectedValue: any = (typeof sessionStorage.getItem('countrySelectedValue') !== 'undefined' &&
    sessionStorage.getItem('countrySelectedValue') !== null) ? JSON.parse(sessionStorage.getItem('countrySelectedValue')) : [];
  public countryItems: any = [];

  public cityPlaceHolder: string = 'City';
  public citySelectedValue: any = (typeof sessionStorage.getItem('citySelectedValue') !== 'undefined' &&
    sessionStorage.getItem('citySelectedValue') !== null) ? JSON.parse(sessionStorage.getItem('citySelectedValue')) : [];
  public cityItems: any = [];

  public branchPlaceHolder: string = 'Branch/Location';
  public branchSelectedValue: any = (typeof sessionStorage.getItem('branchSelectedValue') !== 'undefined' &&
    sessionStorage.getItem('branchSelectedValue') !== null) ? JSON.parse(sessionStorage.getItem('branchSelectedValue')) : [];
  public branchItems: any = [];

  public jobGroupPlaceHolder: string = 'Job Group';
  public jobGroupSelectedValue: any = (typeof sessionStorage.getItem('jobGroupSelectedValue') !== 'undefined' &&
    sessionStorage.getItem('jobGroupSelectedValue') !== null) ? JSON.parse(sessionStorage.getItem('jobGroupSelectedValue')) : [];
  public jobGroupItems: any = [];

  public jobFamilyPlaceHolder: string = 'Job Family';
  public jobFamilySelectedValue: any = (typeof sessionStorage.getItem('jobFamilySelectedValue') !== 'undefined' &&
    sessionStorage.getItem('jobFamilySelectedValue') !== null) ? JSON.parse(sessionStorage.getItem('jobFamilySelectedValue')) : [];
  public jobFamilyItems: any = [];

  public departmentPlaceHolder: string = 'Department';
  public departmentSelectedValue: any = (typeof sessionStorage.getItem('departmentSelectedValue') !== 'undefined' &&
    sessionStorage.getItem('departmentSelectedValue') !== null) ? JSON.parse(sessionStorage.getItem('departmentSelectedValue')) : [];
  public departmentItems: any = [];

  public designationPlaceHolder = 'Designation';
  public designationSelectedValue: any = (typeof sessionStorage.getItem('designationSelectedValue') !== 'undefined' &&
    sessionStorage.getItem('designationSelectedValue') !== null) ? JSON.parse(sessionStorage.getItem('designationSelectedValue')) : [];
  public designationItems = [];

  public bandPlaceHolder = 'Band';
  public bandSelectedValue: any = (typeof sessionStorage.getItem('bandSelectedValue') !== 'undefined' &&
    sessionStorage.getItem('bandSelectedValue') !== null) ? JSON.parse(sessionStorage.getItem('bandSelectedValue')) : [];
  public bandItems = [];

  public filters_heading = 'map filter';

  public total_applied_filters = '0';

  public activeInfo = "black";

  public applied_filters = {
    band: [],
    designation: [],
    department: [],
    jFamily: [],
    jGroup: [],
    branch: [],
    city: [],
    country: [],
  };

  appliedFilterForm = this._fb.group ({
    band: this._fb.control(''),
    designation: this._fb.control(''),
    department: this._fb.control(''),
    jFamily: this._fb.control(''),
    jGroup: this._fb.control(''),
    branch: this._fb.control(''),
    city: this._fb.control(''),
    country: this._fb.control(''),
  });

  message
  departmentId :any;
  constructor(private _orgFilter: OrgFiltersService,
    private _filterDataEmitter: FilterDataEmitterService,
    private _route: Router,
    public _fb : FormBuilder,
    public _orgChartService : OrgchartService) { }

  ngOnInit() {

    if( sessionStorage.getItem('selectedRouter') === null
        || typeof sessionStorage.getItem('selectedRouter') === 'undefined'
      ) {

        sessionStorage.setItem('selectedRouter', this._route.url);
    }

    if( sessionStorage.getItem('selectedRouter') ==  this._route.url ) {

      sessionStorage.removeItem('countrySelectedValue');
      sessionStorage.removeItem('citySelectedValue');
      sessionStorage.removeItem('branchSelectedValue');
      sessionStorage.removeItem('jobGroupSelectedValue');
      sessionStorage.removeItem('jobFamilySelectedValue');
      sessionStorage.removeItem('departmentSelectedValue');
      sessionStorage.removeItem('designationSelectedValue');
      sessionStorage.removeItem('bandSelectedValue');
      sessionStorage.removeItem("open_chart");
      sessionStorage.removeItem("session_view");
      sessionStorage.removeItem("session_number");
      sessionStorage.removeItem("session_color");
      sessionStorage.removeItem("session_filter");

      this.countrySelectedValue     = [];
      this.citySelectedValue        = [];
      this.branchSelectedValue      = [];
      this.jobGroupSelectedValue    = [];
      this.jobFamilySelectedValue   = [];
      this.departmentSelectedValue  = [];
      this.designationSelectedValue = [];
      this.bandSelectedValue        = [];

      this._orgFilter.getCountries()
      .subscribe(data => this.countryItems = data);

      this._orgFilter.getJobGroup()
      .subscribe(data => this.jobGroupItems = data);
    
      this._orgFilter.getDesignation(this.departmentId)
      .subscribe(data => this.designationItems = data);

      this._orgFilter.getBand()
        .subscribe(data => this.bandItems = data);

    }
    else {

      sessionStorage.setItem('selectedRouter', this._route.url);


      this._orgFilter.getCountries()
        .subscribe(data => this.countryItems = data);

      this._orgFilter.getCities()
        .subscribe(data => this.cityItems = data);

      this._orgFilter.getBranches()
        .subscribe(data => this.branchItems = data);

      this._orgFilter.getJobGroup()
        .subscribe(data => this.jobGroupItems = data);

      this._orgFilter.getJobFamily()
        .subscribe(data => this.jobFamilyItems = data);

      this._orgFilter.getDepartment()
        .subscribe(data => this.departmentItems = data);

      this._orgFilter.getDesignation(this.departmentId)
        .subscribe(data => this.designationItems = data);

      this._orgFilter.getBand()
        .subscribe(data => this.bandItems = data);


      setTimeout(function(){
        this.formSubmit();
      }.bind(this), 2500);

    }

  }

  ngDoCheck() {

    if ($('.chart_holder').is(":visible") === false) {

      this.filters_heading = "map filter";

    }
    else {
      this.filters_heading = "orgchart filter";
    }

  }

  // changeCountry() {

  //   this._orgFilter.sendCountryData(this.appliedFilterForm.get('country').value)
  //     .subscribe(data => {
  //       if(this.appliedFilterForm.get('country').value.length === 0) {

  //         this.cityItems = [];
  //         this.citySelectedValue = [];
  //         this.branchItems = [];
  //         this.branchSelectedValue = [];

  //       }
  //       else {

  //         if(this.appliedFilterForm.get('city').value.length === 0) {

  //           this.cityItems = data;
  //           this.citySelectedValue = [];

  //         }
  //         else {

  //           this.cityItems = data;

  //         }

  //       }

  //     });

  // }

  changeCountry() {
    const countryFormControl = this.appliedFilterForm.get('country');
    const cityFormControl = this.appliedFilterForm.get('city');
  
    if (countryFormControl && cityFormControl) {
      this._orgFilter.sendCountryData(countryFormControl.value).subscribe(data => {
        if (countryFormControl.value === null || countryFormControl.value === undefined || countryFormControl.value.length === 0) {
          this.cityItems = [];
          this.citySelectedValue = [];
          this.branchItems = [];
          this.branchSelectedValue = [];
        } else {
          if (cityFormControl.value === null || cityFormControl.value === undefined || cityFormControl.value.length === 0) {
            this.cityItems = data;
            this.citySelectedValue = [];
          } else {
            this.cityItems = data;
          }
        }
      });
    }
  }

  

  changeCity() {

    this._orgFilter.sendCityData(this.appliedFilterForm.get('city').value)
      .subscribe(data => {

        if (data.length > 0 && data.length === 3) {

          if (typeof (data[0][0].TRUE) != 'undefined' && data[0][0].TRUE == true) {

            /*this.countryItems = data[1];

            let len = data[1].length;

            for (let i = 0; i < len; i++) {

              this.countrySelectedValue.push(data[1][i].country_id);

            }*/

            this.branchItems = data[2];


          }
          else {

            /*this.countryItems = data[1];
            this.countrySelectedValue = [];*/
            this.branchItems = [];
            this.branchSelectedValue = [];

          }

        }
        else {
        }


      });

  }

  changeBranch() {

    this._orgFilter.sendBranchData(this.appliedFilterForm.get('branch').value)
      .subscribe(data => {

        if (data.length > 0 && data.length === 3) {

          /*if (typeof (data[0][0].TRUE) != 'undefined' && data[0][0].TRUE == true) {

            this.countryItems = data[1];
            this.cityItems = data[2];

            let len = data[1].length;

            for (let i = 0; i < len; i++) {

              this.countrySelectedValue.push(data[1][i].country_id);

            }

            len = data[2].length;

            for (let i = 0; i < len; i++) {

              this.citySelectedValue.push(data[2][i].id);

            }


          }
          else {

            this.countryItems = data[1];
            this.countrySelectedValue = [];
            this.cityItems = data[2];
            this.citySelectedValue = [];

          }*/

        }
        else {
        }

      });

  }

  // changeJobGroup() {
  //   this._orgFilter.sendJobGroupData(this.appliedFilterForm.get('jGroup').value)
  //     .subscribe(data => {

  //       if(this.appliedFilterForm.get('jGroup').value.length === 0) {

  //         this.jobFamilyItems = [];
  //         this.jobFamilySelectedValue = [];

  //         this.departmentItems = [];
  //         this.departmentSelectedValue = [];

  //       }
  //       else {

  //         if(this.appliedFilterForm.get('jFamily').value.length === 0) {

  //           this.jobFamilyItems = data;
  //           this.jobFamilySelectedValue = [];

  //         }
  //         else {

  //           this.jobFamilyItems = data;

  //         }



  //       }
  //     });
  // }

  changeJobGroup() {
    const jGroupValue = this.appliedFilterForm.get('jGroup').value;
  
    if (!jGroupValue || jGroupValue.length === 0) {
      this.jobFamilyItems = [];
      this.jobFamilySelectedValue = [];
  
      this.departmentItems = [];
      this.departmentSelectedValue = [];
      return; // Exit early if jGroupValue is null or empty
    }
  
    this._orgFilter.sendJobGroupData(jGroupValue).subscribe(data => {
      if (data === null || data === undefined) {
        // Handle the case where data is null or undefined
        return;
      }
  
      const jFamilyValue = this.appliedFilterForm.get('jFamily').value;
  
      if (!jFamilyValue || jFamilyValue.length === 0) {
        this.jobFamilyItems = data;
        this.jobFamilySelectedValue = [];
      } else {
        this.jobFamilyItems = data;
      }
    });
  }
  

  changeJobFamily() {

    this._orgFilter.sendJobFamilyData(this.appliedFilterForm.get('jFamily').value)
      .subscribe(data => {
        if (data.length > 0 && data.length === 3) {

          if (typeof (data[0][0].TRUE) != 'undefined' && data[0][0].TRUE == true) {

            /*this.jobGroupItems = data[1];*/

            let len = data[1].length;

            /*for (let i = 0; i < len; i++) {

              this.jobGroupSelectedValue.push(data[1][i].department_group2_id);

            }*/

            this.departmentItems = data[2];


          }
          else {

            /*this.jobGroupItems = data[1];
            this.jobGroupSelectedValue = [];*/
            this.departmentItems = [];
            this.departmentSelectedValue = [];

          }

        }
        else {
        }
      });
  }

  changeDepartment() {

    // Send the selected department IDs to getDesignation
    this._orgFilter.getDesignation(this.selectedDepartments)
      .subscribe(data => this.designationItems = data);
  }


  // changeDepartment() {
  //   this._orgFilter.sendDepartmentData(this.appliedFilterForm.get('department').value)
  //     .subscribe(data => {
  //       if (data.length > 0 && data.length === 3) {

  //         /*if (typeof (data[0][0].TRUE) != 'undefined' && data[0][0].TRUE == true) {

  //           this.jobGroupItems = data[1];
  //           this.jobFamilyItems = data[2];

  //           let len = data[1].length;

  //           for (let i = 0; i < len; i++) {

  //             this.jobGroupSelectedValue.push(data[1][i].department_group2_id);

  //           }

  //           len = data[2].length;

  //           for (let i = 0; i < len; i++) {

  //             this.jobFamilySelectedValue.push(data[2][i].department_group3_id);

  //           }

  //         }
  //         else {

  //           this.jobGroupItems = data[1];
  //           this.jobGroupSelectedValue = [];
  //           this.jobFamilyItems = data[2];
  //           this.jobFamilySelectedValue = [];

  //         }*/

  //       }
  //       else {
  //       }
  //     });
  // }

  show_applied_filters() {

    if (this.activeInfo === "colored") {

      $("#infoNav").toggle();

    }

  }

  formSubmit() {
    sessionStorage.setItem('countrySelectedValue', JSON.stringify(this.appliedFilterForm.get('country').value));
    sessionStorage.setItem('citySelectedValue', JSON.stringify(this.appliedFilterForm.get('city').value));
    sessionStorage.setItem('branchSelectedValue', JSON.stringify(this.appliedFilterForm.get('branch').value));
    sessionStorage.setItem('jobGroupSelectedValue', JSON.stringify(this.appliedFilterForm.get('jGroup').value));
    sessionStorage.setItem('jobFamilySelectedValue', JSON.stringify(this.appliedFilterForm.get('jFamily').value));
    sessionStorage.setItem('departmentSelectedValue', JSON.stringify(this.appliedFilterForm.get('department').value));
    sessionStorage.setItem('designationSelectedValue', JSON.stringify(this.appliedFilterForm.get('designation').value));
    sessionStorage.setItem('bandSelectedValue', JSON.stringify(this.appliedFilterForm.get('band').value));

    this.getBandArray();
    this.getDesignationArray();
    this.getDepartArray();
    this.getJFamilyArray();
    this.getJGroupArray();
    this.getBranchArray();
    this.getCityArray();
    this.getCountryArray();

    this.total_applied_filters = '0';

    var shallowFilters = this.applied_filters;

    if (shallowFilters.band?.length === 0) {

      delete shallowFilters.band;

    }

    if (shallowFilters.designation?.length === 0) {

      delete shallowFilters.designation;

    }

    if (shallowFilters.department == undefined || shallowFilters.department.length === 0) {

      delete shallowFilters.department;

    }

    if (shallowFilters?.jFamily?.length === 0) {

      delete shallowFilters.jFamily;

    }

    if (shallowFilters?.jGroup?.length === 0) {

      delete shallowFilters?.jGroup;

    }

    if (shallowFilters?.branch?.length === 0) {

      delete shallowFilters?.branch;

    }

    if (shallowFilters?.city?.length === 0) {

      delete shallowFilters?.city;

    }

    if (shallowFilters?.country?.length === 0) {

      delete shallowFilters?.country;

    }

    let count = 0;

    for (var c in shallowFilters) {

      count = count + 1;

    }

    this.total_applied_filters = count.toString();

    this.activeInfo = "colored";


    this.callRequest()
    // if ($('.chart_holder').is(":visible") === false) {

    //   this.message = "true";

    //   sessionStorage.setItem('message', this.message);

    //   this._filterDataEmitter.changeMessage(this.message);
    //   //

    // }


  }

  objectToQueryParams(obj) {
    const queryParams = [];
    for (const key in obj) {
      if (obj[key] && obj.hasOwnProperty(key) && obj[key] !== '' || obj[key]?.length > 0) {
        queryParams.push(`${key}=${(obj[key]).toString()}`);
      }
    }
    return queryParams.join('&');
  }

  callRequest(){
    const queryParams = this.objectToQueryParams(this.appliedFilterForm.getRawValue());
    this._orgChartService.orgFilterData.next(queryParams)

    if(this._orgChartService.activeMapView.value == false){
      this._orgChartService.populateOrgChart()
    }else{
      this._orgChartService.orgMapComponent.mapLoader(queryParams)
    }
  }

  // clearForm() {

  //   this.appliedFilterForm.reset()
  //   this.cityItems = []
  //   this.branchItems = []
  //   this.jobGroupItems = []
  //   this.jobFamilyItems = []
  //   this.departmentItems = []

  //   this._orgChartService.orgFilterData.next(null)

  //   this.callRequest()

  //   // sessionStorage.setItem('countrySelectedValue', JSON.stringify([]));
  //   // sessionStorage.setItem('citySelectedValue', JSON.stringify([]));
  //   // sessionStorage.setItem('branchSelectedValue', JSON.stringify([]));
  //   // sessionStorage.setItem('jobGroupSelectedValue', JSON.stringify([]));
  //   // sessionStorage.setItem('jobFamilySelectedValue', JSON.stringify([]));
  //   // sessionStorage.setItem('departmentSelectedValue', JSON.stringify([]));
  //   // sessionStorage.setItem('designationSelectedValue', JSON.stringify([]));
  //   // sessionStorage.setItem('bandSelectedValue', JSON.stringify([]));

  //   this.total_applied_filters = '0';

  //   this.applied_filters = {
  //                             band: [],
  //                             designation: [],
  //                             department: [],
  //                             jFamily: [],
  //                             jGroup: [],
  //                             branch: [],
  //                             city: [],
  //                             country: [],
  //                         };

  //   this.countrySelectedValue = [];
  //   this.citySelectedValue = [];
  //   this.branchSelectedValue = [];
  //   this.jobGroupSelectedValue = [];
  //   this.jobFamilySelectedValue = [];
  //   this.departmentSelectedValue = [];
  //   this.designationSelectedValue = [];
  //   this.bandSelectedValue = [];

  //   // setTimeout(function(){
  //   //   $("#submitform").click();
  //   // }, 500);

  // }

  clearForm() {
    // Reset form values
    this.appliedFilterForm.reset();
    this._orgChartService.orgFilterData.next(null)

     this.callRequest()

    // Clear applied filters data
    this.total_applied_filters = '0';
    this.applied_filters = {
        band: [],
        designation: [],
        department: [],
        jFamily: [],
        jGroup: [],
        branch: [],
        city: [],
        country: [],
    };

    // Clear selected values
    this.countrySelectedValue = [];
    this.citySelectedValue = [];
    this.branchSelectedValue = [];
    this.jobGroupSelectedValue = [];
    this.jobFamilySelectedValue = [];
    this.departmentSelectedValue = [];
    this.designationSelectedValue = [];
    this.bandSelectedValue = [];
}


  getBandArray() {

    if (this.bandItems.length > 0) {

      let bandVal = [];

      for (let i = 0; i < this.bandItems.length; i++) {

        let val = this.checkMatchArrayValue(this.bandItems[i].id, this.appliedFilterForm.get('band').value);

        if (val === true) {

          bandVal.push(this.bandItems[i].band_desc);

        }

      }

      this.applied_filters.band = bandVal;

    }

  }

  getDesignationArray() {

    if (this.designationItems.length > 0) {

      let bandVal = [];

      for (let i = 0; i < this.designationItems.length; i++) {

        let val = this.checkMatchArrayValue(this.designationItems[i].designation_id, this.appliedFilterForm.get('designation').value);

        if (val === true) {

          bandVal.push(this.designationItems[i].designation_name);

        }

      }

      this.applied_filters.designation = bandVal;

    }

  }

  getDepartArray() {

    if (this.departmentItems.length > 0) {

      let bandVal = [];

      for (let i = 0; i < this.departmentItems.length; i++) {

        let val = this.checkMatchArrayValue(this.departmentItems[i].id , this.appliedFilterForm.get('department').value);

        if (val === true) {

          bandVal.push(this.departmentItems[i].department);

        }

      }

      this.applied_filters.department = bandVal;

    }

  }

  getJFamilyArray() {

    if (this.jobFamilyItems.length > 0) {

      let bandVal = [];

      for (let i = 0; i < this.jobFamilyItems.length; i++) {

        let val = this.checkMatchArrayValue(this.jobFamilyItems[i].department_group3_id , this.appliedFilterForm.get('jFamily').value);

        if (val === true) {

          bandVal.push(this.jobFamilyItems[i].department_group3);

        }

      }

      this.applied_filters.jFamily = bandVal;

    }

  }

  getJGroupArray() {

    if (this.jobGroupItems.length > 0) {

      let bandVal = [];

      for (let i = 0; i < this.jobGroupItems.length; i++) {

        let val = this.checkMatchArrayValue(this.jobGroupItems[i].department_group2_id , this.appliedFilterForm.get('jGroup').value);

        if (val === true) {

          bandVal.push(this.jobGroupItems[i].department_group2);

        }

      }

      this.applied_filters.jGroup = bandVal;

    }

  }

  getBranchArray() {

    if (this.branchItems.length > 0) {

      let bandVal = [];

      for (let i = 0; i < this.branchItems.length; i++) {

        let val = this.checkMatchArrayValue(this.branchItems[i].loc_id, this.appliedFilterForm.get('branch').value);

        if (val === true) {

          bandVal.push(this.branchItems[i].loc_desc);

        }

      }

      this.applied_filters.branch = bandVal;

    }

  }

  getCityArray() {

    if (this.cityItems.length > 0) {

      let bandVal = [];

      for (let i = 0; i < this.cityItems.length; i++) {

        let val = this.checkMatchArrayValue(this.cityItems[i].id, this.appliedFilterForm.get('city').value);

        if (val === true) {

          bandVal.push(this.cityItems[i].city_name);

        }

      }

      this.applied_filters.city = bandVal;

    }

  }

  getCountryArray() {
    if (this.countryItems.length > 0) {

      let bandVal = [];

      for (let i = 0; i < this.countryItems.length; i++) {

        let val = this.checkMatchArrayValue(this.countryItems[i].country_id, this.appliedFilterForm.get('country').value);

        if (val === true) {

          bandVal.push(this.countryItems[i].country);

        }

      }

      this.applied_filters.country = bandVal;

    }

  }

  checkMatchArrayValue(ele, arr) {

    if (arr?.length > 0) {

      let matched = false;

      for (let i = 0; i < arr?.length; i++) {

        if (arr[i] == ele) {

          matched = true;
        }

      }

      return matched;

    } else {
      return false;
    }
  }
}


