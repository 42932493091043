import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  headers: any;
  constructor(private http: HttpClient) {
    this.headers = new Headers({
      'Content-Type': 'multipart/form-data',
    });
  }

  public downloadFile = (url: string) => {
    return `${environment.laravelApiUrl}${url}`
  }

  public getAll(url: string): Observable<any> {
    return this.http.get(environment.laravelApiUrl + url);
  }
  public getDataById(url: string): Observable<any> {
    return this.http.get(environment.nodeApiUrl + url);
  }
  public getAll1(url: string) {
   this.http.get(environment.laravelApiUrl + url);
  }

  public postReferences(url: string, data: string): Observable<any> {
    const filter = {filter_name: data}
    return this.http.post(environment.laravelApiUrl + url, filter);
  }

  get(url: string, email: string): Observable<any[]> {
    let httpHeaders = new HttpHeaders()
      .set('Accept', 'application/json');
    let httpParams = new HttpParams()
      .set('email', email)
    return this.http.get<any[]>(environment.laravelApiUrl + url, {
      headers: httpHeaders,
      params: httpParams,
      responseType: 'json'
    });
  }

  public getById(url: string, id: number): Observable<any> {
    return this.http.get(environment.laravelApiUrl + url + '/' + id);
  }
  public getByMultipleId(url: string, id: number, id2: number): Observable<any> {
    return this.http.get(environment.laravelApiUrl + url + '/' + id + "/" + id2);
  }

  public post(url: string, body?: any): Observable<any> {

    return this.http.post(environment.laravelApiUrl + url, body);
  }
  public update(url: string, body: any): Observable<any> {

    return this.http.put(environment.laravelApiUrl + url, body);
  }
  public postByNodeUrl(url: string, body: any): Observable<any> {
    return this.http.post(environment.nodeApiUrl + url, body);
  }

  public put(url: string, id: number, body: any): Observable<any> {
    return this.http.put(environment.laravelApiUrl + url + '/' + id, body);
  }
  public putByNodeUrl(url: string, body: any): Observable<any> {
    return this.http.put(environment.nodeApiUrl + url, body);
  }

  public delete(url: string, id: number): Observable<any> {
    return this.http.delete(environment.laravelApiUrl + url + '/' + id);
  }
  public deleteNodeUrl(url: string, id: number): Observable<any> {
    return this.http.delete(environment.nodeApiUrl + url + id);
  }

  public getByBankId(url: string): Observable<any> {
    return this.http.get(environment.nodeApiUrl + url);
  }
  public getLookup(url: string): Observable<any> {
    return this.http.get(environment.nodeApiUrl + url);
  }
  public getLookupById(url: string, id: number): Observable<any> {
    return this.http.get(environment.nodeApiUrl + url + '/' + id);
  }

  public deleteList(url: string, data: any): Observable<any> {
    return this.http.request('DELETE', environment.laravelApiUrl + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ids: data }
    });
  }
  getByParams(url: string, data: any): Observable<any> {
    return this.http
      .get(url, {
        params: data
      })?.flatMap((res: any) => {
        return Observable.of(res);
      })
  }

  public uploadPayrollDataFile(url: string, body: any): Observable<any> {
    return this.http.post(environment.laravelApiUrl + url, body);
  }
  public uploadEmployeeHistoryDataFile(url: string, body: any): Observable<any> {
    return this.http.post(environment.laravelApiUrl + url, body);
  }
}
