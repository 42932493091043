<div class="row w-100 m-0 bg-white">
  <div class="module-style document-comp">
    <form #documentRefForm="ngForm" [formGroup]="documentForm" (submit)="addDocument()"
      class="w-100 col-md-12 row m-0 p-0">
      <div class="w-100 mb-2"
        style="box-shadow: 0px 3px 6px 0px #0000001a !important;border: 1px solid #eeeeee;border-radius: 4px 4px 0 0;">
        <div class="people-title-heading justify-bw pl-0">
          <h4 [ngClass]="!this.isEdit ? 'mydisable' : ''">Add Document</h4>
          <div class="" (click)="minMaxAddDocument()">
            <div *ngIf="stateAddDocument == 'maximize'">
              <i class="fas fa-caret-down"></i>
            </div>

            <div *ngIf="stateAddDocument == 'minimize'">
              <i class="fas fa-caret-up" [ngClass]="!this.isEdit ? 'mydisable' : ''"></i>
            </div>
          </div>
        </div>
        <div class="" [@openClose]="stateAddDocument">
          <div class="emp-content justify-bw row m-0" id="document-formfield-wrapper"
            style="padding: 15px 20px 0 !important;border: none !important;">
            <div class="select-wrapper w-cus-260 col px-cus-0">
              <mat-form-field appearance="fill">
                <mat-label>Document Type</mat-label>
                <mat-select #documentCategory panelClass="options-panel" formControlName="documentCategoryId"
                  (selectionChange)="
                    [
                      resetTitle('documentCategoryFieldId'),
                      getDocumentCategoryField(documentCategory.value),
                      (submitted = false),
                      (recruitementPack = null)
                    ]
                  ">
                  <div class="">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <input [(ngModel)]="filter['categoriesWithFields']" name="searchWord"
                    *ngIf="categoriesWithFields.length > 10" [ngModelOptions]="{ standalone: true }" type="text"
                    class="search-input" placeholder="Search" />
                    <mat-option *ngIf="categoriesWithFields.length > 10"></mat-option>
                  <mat-option *ngIf="categoriesWithFields.length == 0">No Record Found</mat-option>
                  <mat-option *ngFor="
                      let item of categoriesWithFields
                        | searchFilter : filter['categoriesWithFields'] : 'name'
                    " [value]="item.id">{{ item.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="
                  (validation.documentCategoryId.touched || submitted) &&
                  validation.documentCategoryId.errors?.required
                " class="error-message">
                Required*
              </div>
            </div>

            <div class="select-wrapper w-cus-260 col px-cus-0">
              <mat-form-field appearance="fill">
                <mat-label>Title</mat-label>
                <mat-select #documentCategoryField [disabled]="!documentCategory.value" panelClass="options-panel"
                  formControlName="documentCategoryFieldId" (selectionChange)="
                    [
                      (documentCategoryFieldTitle =
                        documentCategoryField.value),
                      (submitted = false),
                      (recruitementPack = null)
                    ]
                  ">
                  <input [(ngModel)]="filter['documentCategoryFields']" name="searchWord"
                    *ngIf="documentCategoryFields.length > 10" [ngModelOptions]="{ standalone: true }" type="text"
                    class="search-input" placeholder="Search" />
                    <mat-option *ngIf="documentCategoryFields.length > 10"></mat-option>
                  <mat-option *ngIf="documentCategoryFields.length == 0">No Record Found</mat-option>
                  <mat-option *ngFor="
                      let item of documentCategoryFields
                        | searchFilter
                          : filter['documentCategoryFields']
                          : 'categoryFieldName'
                    " (click)="changeSelectedItem(item)" [value]="item.documentCategoryFieldId">{{
                    item.categoryFieldName }}
                    <span style="color: #fb887a">{{
                      item.isRequired ? "*" : ""
                      }}</span></mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="
                  (validation.documentCategoryFieldId.touched || submitted) &&
                  validation.documentCategoryFieldId.errors?.required
                " class="error-message">
                Required*
              </div>
            </div>

            <!-- <div class="col w-cus-260 px-cus-0 position-relative">
              <mat-form-field class="w-100 ml-0">
                <mat-label>Issue Date </mat-label>
                <input formControlName="issueDate"  matInput [matDatepicker]="datePicker" [max]="minDate" (change)="[(submitted = false)]">
                <mat-datepicker-toggle matSuffix [for]="datePicker">
                  <mat-icon matDatepickerToggleIcon>
                    <img
                      src="/assets/images/calendar.svg"
                      class="calendar-icon"
                    />
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
              </mat-form-field>
              <mat-error *ngIf="(validation.issueDate.touched || submitted) && validation.issueDate.errors?.required" class="error-message">
                Required*
              </mat-error>
            </div> -->

            <div class="col w-cus-260 px-cus-0">
              <div class="date-field toggle-date">
                <input matInput readonly formControlName="issueDate" [matDatepicker]="varIssueDate"
                  (change)="[(submitted = false)]" [max]="minDate" />
                <label id="issue_date_label" class="date_label">Issue Date </label>
                <mat-datepicker-toggle (click)="markAsTouched('issueDate')" [disableRipple]="true" [for]="varIssueDate">
                  <mat-icon matDatepickerToggleIcon>
                    <img src="/assets/images/calendar.svg" class="calendar-icon" />
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #varIssueDate></mat-datepicker>
              </div>
              <div *ngIf="
                  (validation.issueDate.touched || submitted) &&
                  validation.issueDate.errors?.required
                " class="error-message">
                Required*
              </div>
            </div>
            <div class="check w-55 px-cus-0">
              <mat-checkbox class="validity px-cus-0" formControlName="validity" color="primary" checked="true">
                Validity
              </mat-checkbox>
            </div>
            <div class="col w-cus-260 px-cus-0">
              <div class="date-field toggle-date">
                <input readonly [disabled]="!validation.validity.value" matInput formControlName="validTill"
                  [matDatepicker]="varValidTill" [value]="
                    !validation.validity.value ? '' : validation.validTill.value
                  " (change)="[(submitted = false)]" [min]="minDate" />
                <label id="valid_date_label" class="date_label">Valid Till</label>
                <mat-datepicker-toggle (click)="markAsTouched('validTill')" [disableRipple]="true" [for]="varValidTill">
                  <mat-icon matDatepickerToggleIcon>
                    <img src="/assets/images/calendar.svg" class="calendar-icon" />
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #varValidTill></mat-datepicker>
              </div>
              <div *ngIf="
                  (validation.validTill.touched || submitted) &&
                  validation.validTill.errors?.dateLesserThen
                " class="error-message">
                Must be greater then Issue Date
              </div>
              <div *ngIf="
                  (validation.validTill.touched || submitted) &&
                  validation.validTill.errors?.requiredIfNotValid
                " class="error-message">
                Required*
              </div>
            </div>
          </div>
          <div class="emp-content justify-bw row m-0" style="padding: 0 20px !important;border: none !important;">
            <div class="col w-cus-260 px-cus-0">
              <div class="attachment">
                <label class="m-0">
                  <div class="cut-text">
                    {{
                    fileName && fileFormat
                    ? fileName + "." + fileFormat
                    : "Attachment"
                    }}
                  </div>
                  <input #attachmentRef (change)="uploadFile($event, attachmentRef)" formControlName="attachment" hidden
                    type="file" name="" />
                  <i class="bi bi-paperclip"></i>
                </label>
                <div *ngIf="
                    (validation.attachment.touched || submitted) &&
                    validation.attachment.errors?.required
                  " class="error-message">
                  Required*
                </div>
              </div>
            </div>
            <div class="col w-cus-260 pt-cus-3 px-cus-0 dl-template" *ngIf="recruitementPack?.templateUrl">
              <a download (click)="
                  downloadFile(
                    recruitementPack?.templateUrl,
                    'template',
                    'docx'
                  )
                ">Download Template</a>
            </div>
            <div class="col w-cus-260 px-cus-0"></div>
            <div class="w-55 px-cus-0"></div>
            <div class="col w-cus-260 px-cus-0"></div>
          </div>
        </div>
        <div [@openClose]="stateAddDocument">
          <div class="card-footer">
            <button [disabled]="!documentForm.valid || submitted"
              [ngClass]="{'disabled' : !documentForm.valid || submitted}" id="button" type="submit">
              Add
            </button>
            <button id="button" (click)="reset()" type="button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="col-md-12 p-0"
      style="box-shadow: 0px 3px 6px 0px #0000001a !important;border: 1px solid #eeeeee;    border-radius: 4px 4px 0 0 !important;">
      <div class="people-title-heading justify-bw m-0 pl-0">
        <h4>Document List</h4>
        <div class="" (click)="minMaxDocumentList()">
          <div *ngIf="stateDocumentList == 'maximize'">
            <i class="fas fa-caret-down"></i>
          </div>
          <div *ngIf="stateDocumentList == 'minimize'">
            <i class="fas fa-caret-up"></i>
          </div>
        </div>
      </div>
      <div [@openClose]="stateDocumentList" class="doc-content">
        <div class="is-no-record" *ngIf="isNoRecord">No records found!</div>
        <div *ngFor="let document of documentList; let i = index" class="m-0" style="padding : 5px;"
          [hidden]="document.documents.length == 0">
          <div class="people-sub-title-heading p-0">
            <h4>{{ document.name }}</h4>
          </div>
          <div class="row w-100 m-0 bg-white p-0">
            <div class="col-md-12 p-0">
              <table class="doc-records personal-docs" id="personalDocs">
                <tr>
                  <th style="width: 20px !important;">Sr. No</th>
                  <th>Document Title</th>
                  <th>Document Version</th>
                  <th>Issue Date</th>
                  <th>Valid Till</th>
                  <th>Uploaded On</th>
                  <th>Uploaded By</th>
                  <th *ngIf="canDelete"></th>
                </tr>
                <tr *ngFor="let item of document.documents; let j = index">
                  <td>{{ j + 1 }}</td>
                  <td (click)="openDoc(document.documents, item)">
                    {{ item.category_field_name }}
                  </td>
                  <td>{{ item.version }}</td>
                  <td>{{ transformDate(item.issue_date) }}</td>
                  <td>
                    {{
                    item.validity
                    ? transformDate(item.valid_till)
                    : "Not Applicable"
                    }}
                  </td>
                  <td>{{ transformDate(item.uploaded_on) }}</td>
                  <td>{{ item.uploaded_by }}</td>
                  <td *ngIf="canDelete">
                    <div class="justify-center m-0">
                      <div (click)="
                          openConfirmDialog(item.category_field_name, item.id, j, i)
                        " id="button-blue">
                        <div></div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div [@openClose]="stateDocumentList">
        <div class="card-footer">
          <button *ngIf="isUpdating" id="button" [disabled]="disabledUpdate" [ngClass]="{'disabled' : disabledUpdate}"
            (click)="update()">
            Update
          </button>
          <button [disabled]="disabledSave" [ngClass]="{'disabled' : disabledUpdate}" *ngIf="!isUpdating" id="button"
            (click)="save()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
