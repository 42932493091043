import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { LoaderService } from "./loader.service";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  portalLink: String = "";
  token: String = "";
  tokenNda: String = "";
  appToken: String = "";

  constructor(private router: Router, private loader : LoaderService, public toastr: ToastrService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (

      localStorage.getItem("token_nda") &&
      localStorage.getItem("token") &&
      localStorage.getItem("app_token")
    ) {
      // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjA2NDNkMWQ4NDNlNjI4ODBlMDkyMTk1MTIwYzAwZGI0ZmRmZjIxNTU2YWQ5NGQ1MDRkZDg5YWZjYWNhYjRhNjA4MTZmNGE4OTllYzg2ODVhIn0.eyJhdWQiOiIxIiwianRpIjoiMDY0M2QxZDg0M2U2Mjg4MGUwOTIxOTUxMjBjMDBkYjRmZGZmMjE1NTZhZDk0ZDUwNGRkODlhZmNhY2FiNGE2MDgxNmY0YTg5OWVjODY4NWEiLCJpYXQiOjE2MzI5MDYwMTcsIm5iZiI6MTYzMjkwNjAxNywiZXhwIjoxNjY0NDQyMDE3LCJzdWIiOiIxNzI2OCIsInNjb3BlcyI6W119.SPvBYqqnBUP1v5GOsE6cmWnjUEgprTJ5ngCKd7UC-N0zaOeLwifwOswWb5UE4UJAkdOSxMLxxn3V1H_yVHjBU9Fr9d7JbthtRaxDw7SaruvfctUuQnO4KJmi_xjod2sWNlIeD05op4uiJ6ZfV7XcY8PODwZ3l2opM_d9FcVyDLGBEFyL8eNrRpieI7i4IgPXvi5s9o-3r3zX8NN8vVAAMebCtVLXhkANTQmkg_-IXLygXDT5JXTHCubsnVBYuk_tX5U1ST5J92YKlcL6knzrLZHH-ghNaa8czNrnDYBdpDAAKYkTZQR7rCzNUipzMWo7FASGrZk4uKVgG9u1Qm97cxcCNYqDhBp5cYC1rXHpRvx8v6wyxtYRBuFvS9elYEo-C6WmdM45IxNnpgK0mcBW7FjG6knrqtW3J1Xwa8Wl3-u4l5FfwRpB04mH7V40F8E4wflY5ElKS22AN3EazPADbhP4dQwnAkyYWikIZzCSuraUQ4896mXHHWCLczne9WshXNqzpUWyKZ-ccy7gfOBnstx4boF3TQ1sxn98jX4atSQ_KtUJqqD757rGejVBgu-pBFdf-L0XQABTLQtkKo9vMO6Bmd-6hzqSbd2jsO48OfpuHmTPhr-9mWQR1uCeLupIRiz_7eAifeVspor-fNyCzQjloMf9M56Kk6gAnawWrco';
      // debugger
      if (localStorage.getItem("token")) {
        this.token = JSON.parse(localStorage.getItem("token"));
      }

      if (localStorage.getItem("token_nda")) {
        this.tokenNda = JSON.parse(localStorage.getItem("token_nda"));
      }

      if (localStorage.getItem("link")) {
        this.portalLink = localStorage.getItem("link");
      }

      if (localStorage.getItem("app_token")) {
        this.appToken = localStorage.getItem("app_token");
      }

      const cloned = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}|${this.appToken}|${this.tokenNda}`,
          // authorization_nda: `${this.tokenNda}`,
          PortalLink: `${this.portalLink}`,
        },
      });
      return next.handle(cloned).pipe(
        tap(
          (event) => {},
          (err) => {
            if (err.status === 440 || err.status === 401) {
              this.toastr.clear()
              this.router.navigate(["/login"])
              this.loader.isLoading.next(false)
              this.toastr.info("Session Has Been Expired")
              // this.toastr.info(err?.error?.message)
            };
          }
        )
      );
    } else {
      return next.handle(req).pipe(
        tap(
          (event) => {},
          (err) => {
            if (err.status === 440 || err.status === 401) {
              this.toastr.clear()
              this.router.navigate(["/login"])
              this.loader.isLoading.next(false)
              this.toastr.info("Session Has Been Expired")
            };
          }
        )
      );
    }
  }
}
