import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/services/message.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoaderService } from "src/app/services/loader.service";

@Component({
  selector: 'app-seamless-form',
  templateUrl: './seamless-form.component.html',
  styleUrls: ['./seamless-form.component.css']
})
export class SeamlessFormComponent implements OnInit {

  public messages: any[] = [];
  public subscription: Subscription;
  public portalLink: String = "";
  public token: String = "";
  public tokenNda: String = "";
  public encryptedToken: String = "";
  public fileLink: String = "";
  public portalID : Number = 0;
  public subPortalID : Number = 0;
  public portalName: String = "";
  public empID : String = "";
  public appToken: String = "";
  public parentPortalID: String = "";
  public selectedPortalName: String = "";
  public subPortalName: string = "";

  seamlessForm: FormGroup = this.fb.group({
    portalLink: this.fb.control(''),
    token: this.fb.control(''),
    tokenNda: this.fb.control(''),
    encryptedToken: this.fb.control(''),
    fileLink: this.fb.control(''),
    portalID: this.fb.control(0),
    subPortalID: this.fb.control(0),
    portalName: this.fb.control(''),
    empID: this.fb.control(''),
    appToken: this.fb.control(''),
    parentPortalID: this.fb.control(''),
    selectedPortalName: this.fb.control(''),
  })

  apiURL = environment.laravelAppUrl + "login-form-submit";

  @ViewChild('myForm') myForm : ElementRef;

  constructor(
    private messageService: MessageService,
    private loader: LoaderService,
    private fb: FormBuilder
  ) {

     this.subscription = this.messageService.getMessage().subscribe(message => {
       this.patchFormValues()

      if (message.For == "SeamlessForm") {
        this.fileLink = message.Data.fileName;
        this.seamlessForm.get('fileLink').patchValue(message.Data.fileName)
        this.submitForm();
      }

      if(message.For == "UpdatePortalSeamLess") {
        this.portalID = message.Data.portalId;
        this.subPortalID = message.Data.subPortalID;
        this.portalName = message.Data.portalTitle;
      }
      if(localStorage.getItem("link")) {
        this.portalLink = localStorage.getItem("link");
      }

    });

  }

  submitForm() {
    this.loader.isLoading.next(true);
    setTimeout(() => {
      this.myForm.nativeElement.submit();
    }, 500);

  }

  ngOnInit() {
    this.patchFormValues()
  }

  patchFormValues() {

    if(localStorage.getItem("portalId")) {
      this.portalID = parseInt(localStorage.getItem("portalId"));
    }

    if(localStorage.getItem("subPortalId")) {
      this.subPortalID = parseInt(localStorage.getItem("subPortalId"));
    }
    if(localStorage.getItem("subPortalName")) {
      this.subPortalName = localStorage.getItem("subPortalName");
    }
    if(localStorage.getItem("portalTitle")) {
      this.portalName = localStorage.getItem("portalTitle");
    }

    if(localStorage.getItem("selectedPortalName")) {
      this.selectedPortalName = localStorage.getItem("selectedPortalName");
    }

    if(localStorage.getItem("parentPortalId")) {
      this.parentPortalID = localStorage.getItem("parentPortalId");
    }

    if(localStorage.getItem('token')) {
      this.token = JSON.parse(localStorage.getItem('token'))
    }

    if(localStorage.getItem('token_nda')) {
      this.tokenNda = JSON.parse(localStorage.getItem('token_nda'))
    }

    if(localStorage.getItem('encryptedToken')) {
      this.encryptedToken = JSON.parse(localStorage.getItem('encryptedToken'))
    }

    if(localStorage.getItem("link")) {
      this.portalLink = localStorage.getItem("link");
    }

    if(localStorage.getItem("user_id")) {
      this.empID = localStorage.getItem("user_id");
    }

    if(localStorage.getItem("app_token")) {
      this.appToken = localStorage.getItem("app_token");
    }
    this.seamlessForm.patchValue({
      portalLink:this.portalLink,
      token:this.token,
      tokenNda:this.tokenNda,
      encryptedToken:this.encryptedToken,
      fileLink:this.fileLink,
      portalID:this.portalID,
      subPortalID:this.subPortalID,
      portalName:this.portalName,
      empID:this.empID,
      appToken:this.appToken,
      parentPortalID:this.parentPortalID,
      selectedPortalName:this.selectedPortalName,
    })
  }

}
