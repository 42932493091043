import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APIs } from "src/environments/environment";
import { ObservableService } from "../util/observablefn.service";

@Injectable({
  providedIn: "root",
})
export class GetNotificationsService {
  notificationUrl: string = "";

  headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, private os: ObservableService) {
    this.notificationUrl = APIs['notificationUrl'];
    this.headers = this.os.headers();
  }

  getNotificationsHistory(clientId, filters, pageNumber, pageSize) {
    const body:any = {
      clientId,
      filters,
      pageNumber,
      pageSize,
    };

    body.userId  = localStorage.getItem('e_number');
    body.portalId = localStorage.getItem('portalId');

    return this.http.post(`${this.notificationUrl}/getAllNotifications`, body, {
      headers: this.headers,
    });

  }

  getSingleNotificationHistory(clientId, id) {
    const params = { clientId, id , emp_id : localStorage.getItem('e_number')};

    return this.http.get(`${this.notificationUrl}/getSingleNotification`, {
      headers: this.headers,
      params,
    });
  }

  getUserNotifications(pageNumber, pageSize) {
    const body = { pageNumber, pageSize };
    const portalId = localStorage.getItem('portalId');
    if(portalId) body["portalId"] = portalId; body["empid"] =  localStorage.getItem('e_number');
    return this.http.post(`${this.notificationUrl}/getUserNotifications`, body, {
      headers: this.headers,
    });
  }

  getSingleUserNotification(id) {
    const params = { id , 'empid' : localStorage.getItem('e_number')};

    return this.http.get(`${this.notificationUrl}/singleNotification`, {
      headers: this.headers,
      params,
    });
  }

  get portal(){
    return localStorage.getItem('portalTitle');
  }

  getUnreadNotificationCount() {
    let url = 'unreadCount';
    const portalId = localStorage.getItem('portalId');
    if(portalId) url = `${url}?portalId=${portalId}&empid=${localStorage.getItem('e_number')}`;
    return this.http.get(`${this.notificationUrl}/${url}`, {
      headers: this.headers,
    });
  }
}
