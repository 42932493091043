import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MlOrgchartComponent } from './ml-orgchart.component';
import { GraphModule } from '../lib/graph/graph.module';
import { OrgChartService } from '../../../services/org-chart.service';
import { ChartSidebarModule } from '../../chart-sidebar/chart-sidebar.module';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../../shared/modules/material/material.module';

@NgModule({
  declarations: [MlOrgchartComponent],
  imports: [
    CommonModule,
    GraphModule,
    ChartSidebarModule,
    FormsModule,
    MaterialModule,
  ],
  providers: [OrgChartService],
  exports: [MlOrgchartComponent]
})
export class MlOrgchartModule { }
