import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SimpleHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
