<div class="inner-section">
  <div class="card-body p-0 pt-8px">
    <mat-accordion multi >
      <mat-expansion-panel [expanded]="true" class="mb-2 mt-0px">
        <mat-expansion-panel-header class="header-class">
          <mat-panel-title class="pr-3" (click)="$event.stopPropagation();">
          <mat-card-title class="align-self-center card-tittle">File Uploader Bulk</mat-card-title>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-inline-flex col-md-12 p-0">
          <div style="display: flex;align-items: center;margin-left: 20px;" id="insert_payroll_data">
              <mat-form-field class="input-field upload-file" style="margin-top: 15px;">
                  <mat-label *ngIf="file; else newFile">{{ file.name }}</mat-label>
                  <ng-template #newFile>
                      <mat-label>Attachment</mat-label>
                  </ng-template>
                  <input matInput disabled />
                  <button mat-icon-button matSuffix type="button" (click)="fileInput.click()">
                      <mat-icon>attach_file</mat-icon>
                  </button>
                  <input hidden (change)="handleFileInput($event)" #fileInput
                      type="file" id="file" (click)="fileInput.value='';fileInput.click();"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
              </mat-form-field>
                <div class="shift-card">
                  <div class="form-col p-20">
                    <div class="download-attachement">
                      <a class="label text-primary cursor-pointer-file font-12" href="../../../../assets/bulkReportTemplate/LeaveApplication.csv">Download Template</a>
                    </div>
                  </div>
                </div>
          </div>
      </div>
      <mat-card-actions class="card-footer">
        <a *ngIf="templateLink" class="link" [href]="templateLink" download>
          Download Template
        </a>
          <button mat-button id="button" [ngClass]="{ 'disabled' : !this.file}" (click)="onSubmit()">Upload</button>
      </mat-card-actions>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- Errors -->
    <ng-container *ngIf="isErrors">
      <mat-accordion multi >
        <mat-expansion-panel [expanded]="true" class="mb-2 mt-0px">
          <mat-expansion-panel-header class="header-class">
            <mat-panel-title class="pr-3" (click)="$event.stopPropagation();">
              <mat-card-title class="align-self-center card-tittle">File Validation Report</mat-card-title>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div id="changeLog" style="overflow-y: scroll !important; max-height: 280px !important;" class="table-responsive">
            <table mat-table [dataSource]="tableDataSource" matSort multiTemplateDataRows class="table-design" style="color: #343434;">
              <ng-container *ngFor="let disCol of columnNames; let colIndex = index" matColumnDef="{{disCol}}">
                <mat-header-cell *matHeaderCellDef>{{matColumn(colIndex) | titlecase }}</mat-header-cell>
                <!-- [ngClass]="typeOf(element[disCol]) === 'number' ? 'text-align-end' : 'text-start'" -->
                <!-- {{typeOf(element[disCol]) === 'number' ? (element[disCol] | number) : element[disCol] }} -->
                <mat-cell *matCellDef="let element">
                  <ng-container *ngIf="disCol === 'issues'; else template">
                    <ul>
                      <li *ngFor="let issue of element[disCol]">{{issue}}</li>
                    </ul>
                  </ng-container>
                  <ng-template #template>
                    {{typeOf(element[disCol]) === 'number' ? (element[disCol] | number) : element[disCol] }}
                  </ng-template>


                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="columnNames"></mat-header-row>
              <mat-row  *matRowDef="let row; columns: columnNames;"></mat-row>
          </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </div>





</div>

