import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private snackBar: MatSnackBar,private toastr : ToastrService) {}

  toast(message: string, cssClass: "success-toast" | "error-toast", duration = 2000) {
    if(cssClass == "success-toast"){
      this.toastr.success(message)
    }else if(cssClass == "error-toast"){
      this.toastr.error(message)
    }else{
      this.toastr.info(message)
    }
    // this.snackBar.open(message, '×', {
    //   duration,
    //   panelClass: cssClass
    // });
  }
}
