import * as config from "../../config.json";
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
sessionStorage.setItem("nodeApiUrl", config.nodeApiUrl);
sessionStorage.setItem("laravelApiUrl", config.laravelApiUrl);
sessionStorage.setItem("laravelAppUrl", config.laravelAppUrl);
sessionStorage.setItem("imageUIServer", config.imageUIServer);
sessionStorage.setItem("phpSiteLink", config.phpSiteLink);
sessionStorage.setItem("appBaseUrl", config.appBaseUrl);
sessionStorage.setItem("fileDirUrl", config.fileDirUrl);

export const environment = {
  production: false,

  nodeApiUrl: config.nodeApiUrl,
  nodeApiUrlPayroll: config.nodeApiUrl + "Payroll/",
  phpApiHeader: config.laravelApiUrl,
  phpApiHeader_300_23: config.laravelApiUrl,
  phpApiHeader_300: config.laravelApiUrl,

  mongoApiUrl: config.nodeApiUrl,
  laravelApiUrl: config.laravelApiUrl,
  apiUrl: config.laravelApiUrl,
  laravelAppUrl: config.laravelAppUrl,
  phpSiteUrl: config.laravelApiUrl,
  stagingHeader: config.laravelApiUrl,
  appBaseUrl: config.appBaseUrl,
  //moduleUrl :config.moduleUrl
  fileUrl: config.fileDirUrl,
  nodeFileUrl: config.nodeFileUrl,
};

// APIS
export const APIs = {
  getFilters: environment.phpApiHeader_300_23 + "getFilters",
  setFilters: environment.phpApiHeader + "setFilters",
  rosterLogin: environment.phpApiHeader + "rosterLogin",
  companyInfo: environment.nodeApiUrl + "auth/companyInfo",

  employeeAttendacneYearly:
    environment.phpApiHeader + "employeeAttendacneYearly",
  employeeAttendacneCalenderYearly:
    environment.phpApiHeader + "employeeAttendacneCalenderYearly",

  getMenus: environment.nodeApiUrl + "Menu/getMenus",
  getLandingSubMenus: environment.nodeApiUrl + "Menu/getLandingSubMenus",
  getSubMenus: environment.nodeApiUrl + "Menu/getSubMenus",
  getPortals: environment.nodeApiUrl + "Menu/getPortals",

  // Miscelleneous Settings

  getMISData:
    environment.nodeApiUrl + "Payroll/PayrollSetup/filterScreen/searchMIS",
  postLeaveWithOutPay:
    environment.nodeApiUrl + "Payroll/PayrollSetup/filterScreen/upsertMIS",
  postPayrollCutOf:
    environment.nodeApiUrl + "Payroll/PayrollSetup/filterScreen/upsertMIS",

  // ------------------------ Leave phase - 2
  // Leave Setup
  getGeoGroups: environment.phpApiHeader_300_23 + "getGeoGroups",
  getCompanies: environment.phpApiHeader_300_23 + "getCompanies",
  getCountriesByGeoGroups:
    environment.phpApiHeader_300_23 + "getCountriesByGeoGroups",
  getGeoFilterDataByParent:
    environment.phpApiHeader_300_23 + "getGeoFilterDataByParent",
  getLeaveSetupList: environment.phpApiHeader_300_23 + "getLeaveSetupList",
  getLeaveSetupDetail: environment.phpApiHeader_300_23 + "getLeaveSetupDetail",
  getNewLeaveSetupList: environment.laravelApiUrl + "getLeaveSetupListing",
  getLeaveViolations: environment.laravelApiUrl + "leave/getLeaveViolations",

  // Leave Type & Rules Setup
  getLeaveTypes: environment.phpApiHeader_300_23 + "user/getLeaveTypes",
  getSandwichRuleTypes:
    environment.phpApiHeader_300_23 + "getSandwichRuleTypes",
  getShortLeaveDailyLimit:
    environment.phpApiHeader_300_23 + "getShortLeaveDailyLimit",
  getYearEndBasis: environment.phpApiHeader_300_23 + "getYearEndBasis",
  getShortLeaveCriteriaOptions:
    environment.phpApiHeader_300_23 + "getShortLeaveCriteriaOptions",
  getHalfLeaveCriteriaOptions:
    environment.phpApiHeader_300_23 + "getHalfLeaveCriteriaOptions",
  getCalculationBasis: environment.phpApiHeader_300_23 + "getCalculationBasis",
  saveLeaveSetup: environment.phpApiHeader_300_23 + "saveLeaveSetup",

  // Reasons
  getSetupReason: environment.phpApiHeader_300_23 + "mobile/getSetupReason",

  // Eligibility & Entitlement Setup
  getCriteriaSetupFeildsBySetupType:
    environment.phpApiHeader_300_23 + "getCriteriaSetupFeildsBySetupType",
  getSubDepartmentsByDepartment:
    environment.phpApiHeader_300_23 + "getSubDepartmentsByDepartment",

  // Leave List
  getHRLeaveApplicationList:
    environment.phpApiHeader_300_23 + "hr/GetHRLeaveApplicationList",
  getLMLeaveApplicationList:
    environment.phpApiHeader_300_23 + "lm/GetLMLeaveApplicationLIst",
  getEmployeeLeaveApplicationList:
    environment.phpApiHeader_300_23 + "user/EmployeeLeaveApplicationList",

  // Apply Leave
  applyLeave: environment.laravelApiUrl + "user/applyLeave",
  getApplicableLeaveByEmployee:
    environment.phpApiHeader_300_23 + "getApplicableLeaveByEmployee",
  getShiftTimming: environment.laravelApiUrl + "user/GetShiftTimming",

  // Leave Detail
  getLeaveBalanceByEmployee:
    environment.phpApiHeader_300_23 + "getLeaveBalanceByEmployee",
  getApplicationApprovelDetail:
    environment.phpApiHeader_300_23 + "user/GetApplicationApprovelDetail",
  getLeaveApplicationDetail:
    environment.phpApiHeader_300_23 + "user/GetLeaveApplicationDetail",
  getApplicationLog: environment.phpApiHeader_300_23 + "user/getApplicationLog",
  lMLeaveStatus: environment.phpApiHeader_300_23 + "lm/LMLeaveStatus",
  setLeaveStatusByHR: environment.phpApiHeader_300_23 + "hr/SetLeaveStatusByHR",
  WithdrawApplication:
    environment.phpApiHeader_300_23 + "user/WithdrawApplication",

  //Payroll Setup
  postSetup: environment.nodeApiUrl + "Payroll/PayrollSetup/filterScreen",
  searchSetup:
    environment.nodeApiUrl + "Payroll/PayrollSetup/filterScreen/search",
  addUpdateSetup:
    environment.nodeApiUrl + "Payroll/PayrollSetup/filterScreen/update",
  getDropdowns:
    environment.nodeApiUrl + "Payroll/PayrollSetup/filterScreen/dropdowns",
  getSubDepartments:
    environment.nodeApiUrl + "Payroll/PayrollSetup/filterScreen/subDept",

  //MIS Headers
  getMISHeaders:
    environment.nodeApiUrl + "Payroll/PayrollSetup/filterScreen/headers",
  updateHeaders:
    environment.nodeApiUrl + "Payroll/PayrollSetup/filterScreen/headers/update",
  searchHeaders:
    environment.nodeApiUrl + "Payroll/PayrollSetup/filterScreen/headers/search",

  //Insurance Setup
  updateInsurance:
    environment.nodeApiUrl +
    "Payroll/PayrollSetup/filterScreen/insuranceUpdate",

  //payroll Dashboard
  getNewHires: environment.nodeApiUrl + "Payroll/PayrollDashboard/getNewHires",
  getLeavers: environment.nodeApiUrl + "Payroll/PayrollDashboard/getLeavers",
  getSalaryChanges:
    environment.nodeApiUrl + "Payroll/PayrollDashboard/getSalaryChanges",
  getExpenses: environment.nodeApiUrl + "Payroll/PayrollDashboard/getExpenses",
  getAttendance:
    environment.nodeApiUrl + "Payroll/PayrollDashboard/getAttendance",
  getLoanDeduction:
    environment.nodeApiUrl + "Payroll/PayrollDashboard/getLoanDeduction",

  getHeadCount:
    environment.nodeApiUrl + "Payroll/PayrollDashboard/getHeadCountV2",
  getOtherChanges:
    environment.nodeApiUrl + "Payroll/PayrollDashboard/getOtherChanges",
  getEMFPopupData:
    environment.nodeApiUrl + "Payroll/PayrollDashboard/PopUpDetails",
  editEMFPopupData:
    environment.nodeApiUrl + "Payroll/PayrollDashboard/UpdatePopUpDetails",
  getSalaryCost:
    environment.nodeApiUrl + "Payroll/PayrollDashboard/getSalaryCost",
  getStatutoryData:
    environment.nodeApiUrl + "Payroll/PayrollDashboard/getStatutoryBenifits",
  getOtherDeduction:
    environment.nodeApiUrl + "Payroll/PayrollDashboard/getOtherDeduction",
  getOvertimeAndBonus:
    environment.nodeApiUrl + "Payroll/PayrollDashboard/getOvertimeAndBonus",

  //payroll compensation Dashboard
  getSalaryWages:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/getSalaryWages",
  getStaffTurover:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/getStaffTurover",
  getSalaryDetails:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/salaryDeatils",
  getCompensationExpenses:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/getExpenses",
  getSalaryLoan:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/getSalaryLoan",
  getSalaryLoanPorfolio:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/getSalaryLoanCount",

  //payroll benefits dashboard
  getPerksBenefits:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/perks",
  getTerminalBenefits:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/terminalBenefits",
  getStatutoryBenefits:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/statutoryBenefits",
  getOtherBenefits:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/otherBenefits",

  //payroll com & ben dashboard
  getAverageSalaryByDepartment:
    environment.nodeApiUrl +
    "Payroll/CompAndBenDashboard/getAverageSalaryByDepartment",
  getHeadcount:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/getJobFamily",
  getsalaryChanges:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/getsalaryChanges",

  //payroll invoicing dashboard
  getInvoices:
    environment.nodeApiUrl + "Payroll/Payroll/invoicing/dashboard/invoices",
  overdueInvoices:
    environment.nodeApiUrl +
    "Payroll/Payroll/invoicing/dashboard/overdueInvoices",
  receivables:
    environment.nodeApiUrl + "Payroll/Payroll/invoicing/dashboard/receivables",
  invoiceByClient:
    environment.nodeApiUrl +
    "Payroll/invoicing/dashboard/popups/invoiceByClient",
  invoiceByCategory:
    environment.nodeApiUrl +
    "Payroll/invoicing/dashboard/popups/invoiceByCategory",
  invoiceByDate:
    environment.nodeApiUrl + "Payroll/invoicing/dashboard/popups/invoiceByDate",

  //access matrix
  matrixPortals: environment.nodeApiUrl + "AccessMatrix/getPortalsByClientId",
  getMatrixPortals: environment.nodeApiUrl + "AccessMatrix/getPortalsV2",
  getMatrixPortals2: environment.nodeApiUrl + "AccessMatrix/getPortalsV3",
  getMatrixSubPortals: environment.nodeApiUrl + "AccessMatrix/getSubPortalsV2",
  updatePortals:
    environment.nodeApiUrl + "AccessMatrix/updatePortalsByClientV2",
  getMenuTypes: environment.nodeApiUrl + "AccessMatrix/getMenuType",
  getAllMenuWithAction:
    environment.nodeApiUrl + "AccessMatrix/getAllMenuWithAction",
  getAllMenu: environment.nodeApiUrl + "Menu/getAllMenu",
  getAllMenuV2: environment.nodeApiUrl + "AccessMatrix/getAllMenu",
  getAllMenuOnlyRelatable: environment.nodeApiUrl + "AccessMatrix/getAllMenuV2",
  addMenu: environment.nodeApiUrl + "AccessMatrix/addMenu",
  menuByPortal: environment.nodeApiUrl + "AccessMatrix/menu",

  updateMenu: environment.nodeApiUrl + "AccessMatrix/updateMenu",
  deleteMenu: environment.nodeApiUrl + "AccessMatrix/deleteMenu",
  getRoleWiseMenuActions:
    environment.nodeApiUrl + "AccessMatrix/getRoleWiseMenuActions",
  fileUpload: environment.nodeApiUrl + "AccessMatrix/fileUpload",
  addMenuWithPortal:
    environment.nodeApiUrl + "AccessMatrix/associateMenuWithPortalsWise",
  addFeatureAvailability:
    environment.nodeApiUrl + "AccessMatrix/addFeatureAvailability",
  checkForPortalApproval:
    environment.nodeApiUrl + "AccessMatrix/checkForPortalApproval",
  getApprovalHistory:
    environment.nodeApiUrl + "AccessMatrix/getApprovalHistory",
  getApprovalHistoryForMenu:
    environment.nodeApiUrl + "AccessMatrix/getApprovalHistoryForMenu",
  getApprovalHistoryForPortals:
    environment.nodeApiUrl + "AccessMatrix/getApprovalHistoryForPortals",
  addApprover: environment.nodeApiUrl + "AccessMatrix/addApprover",
  deleteMenuPermanently:
    environment.nodeApiUrl + "AccessMatrix/deleteMenuPermanently",
  deleteMenuPermanentlyWithSubMenus:
    environment.nodeApiUrl + "AccessMatrix/deleteMenuPermanentlyWithSubMenus",
  getPortalAndFeatureApprovalList:
    environment.nodeApiUrl + "AccessMatrix/getPortalAndFeatureApprovalList",
  addPortalAndFeatureApproval:
    environment.nodeApiUrl + "AccessMatrix/addPortalAndFeatureApproval",
  getRoleName: environment.nodeApiUrl + "AccessMatrix/getRoleName",
  checkForEmployeeExistence:
    environment.nodeApiUrl + "AccessMatrix/checkForEmployeeExistence",
  getAllGroupRoleNames:
    environment.nodeApiUrl + "AccessMatrix/getAllGroupRoleNames",
  getApprovalHistoryForRoleAccess:
    environment.nodeApiUrl + "AccessMatrix/getApprovalHistoryForRoleAccess",
  getFilterAccessRoleGroup:
    environment.nodeApiUrl + "AccessMatrix/getFilterAccessRoleGroup",
  addGroupRoles: environment.nodeApiUrl + "AccessMatrix/addGroupRoles",
  getAllMenuActionByPortalIdAndGroupRoleId:
    environment.nodeApiUrl +
    "AccessMatrix/getAllMenuActionByPortalIdAndGroupRoleId",
  addClone: environment.nodeApiUrl + "AccessMatrix/addClone",
  getAccessSpecialFields:
    environment.nodeApiUrl + "AccessMatrix/getSpecialFields",
  addRoleForCloning: environment.nodeApiUrl + "AccessMatrix/addRoleForCloning",
  getAccessRoleApprovalHistory:
    environment.nodeApiUrl + "AccessMatrix/getAccessRoleApprovalHistory",
  updateSpecialFieldAccessControl:
    environment.nodeApiUrl + "AccessMatrix/updateSpecialFieldAccessControl",
  getDepartments: environment.nodeApiUrl + "AccessMatrix/getDepartments",
  getSearchEmployee: environment.nodeApiUrl + "AccessMatrix/getSearchEmployee",
  upsetEmpRoleGroupAssociation:
    environment.nodeApiUrl + "AccessMatrix/upsetEmpRoleGroupAssociation",
  getPortalAgainstRoleName:
    environment.nodeApiUrl + "AccessMatrix/getPortalAgainstRoleName",

  //payroll Dashboard
  getSalaryWagesBenefits:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/getSalaryWages",
  getStaffTurnOverBenefits:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/getStaffTurover",
  // getStatutoryBenefits:environment.nodeApiUrl+'Payroll/CompAndBenDashboard/statutoryBenefits',
  getExpenseBenefits:
    environment.nodeApiUrl + "Payroll/CompAndBenDashboard/getExpenses", // MongoDB Apis
  creatMenusCache: environment.mongoApiUrl + " ",

  //Home Dashboard
  getEmployeeProfileDetail:
    environment.nodeApiUrl + "HomeDashboard/getEmployeeProfileDetails",
  getNewApplicationDetails:
    environment.nodeApiUrl + "HomeDashboard/getEmployeeDetails",
  getNewApplicationStatus:
    environment.nodeApiUrl + "HomeDashboard/getApplicationStatusDetails",
  getLatestApplications:
    environment.nodeApiUrl + "HomeDashboard/GetLatestApplicationRequests",

  // Expense
  expenseTypeList: environment.laravelApiUrl + "expense/list",
  reimbursementTypeList:
    environment.laravelApiUrl + "expense/setup/get_reimbursement_type",
  currencyList: environment.laravelApiUrl + "common/get_currency",
  saveExpenseSetup: environment.laravelApiUrl + "expense/setup",
  getExpenseRefrenceDropDown:
    environment.laravelApiUrl + "expense/getRefrenceDropDown",
  getExpenseSetupList: environment.laravelApiUrl + "expense/setup/list",
  getExpenseSetupDetails: environment.laravelApiUrl + "expense/setup/details",
  getExpenseApplicationList:
    environment.laravelApiUrl + "expense/application/list",
  getAssignedEntitlements: environment.laravelApiUrl + "expense/getEntitlement",
  getEmpExpenseApplicationList:
    environment.laravelApiUrl + "expense/application/list",
  getHRExpenseApplicationList:
    environment.laravelApiUrl + "expense/hr/get_hr_expense_app_list",
  getLMExpenseApplicationList:
    environment.laravelApiUrl + "expense/lm/get_lm_expense_app_list",
  LMUpdateStatus: environment.laravelApiUrl + "expense/lm/lm_update_status",
  HRUpdateStatus: environment.laravelApiUrl + "expense/hr/hr_update_status",
  getExpenseReasons: environment.laravelApiUrl + "expense/getExpenseReasons",
  applyExpense: environment.laravelApiUrl + "expense/application",
  expenseApplicationDetails:
    environment.laravelApiUrl + "expense/application/details",
  getMilageSlabs: environment.laravelApiUrl + "expense/getmilageslabs",
  getNewExpenseSetupList: environment.laravelApiUrl + "expense/setup/listing",
  getCostCenter: environment.laravelApiUrl + "expense/get_cost_center",

  // Letter and Certificates
  getSetupDetail: environment.nodeApiUrl + "LetterCertificates/getSetupDetail/",
  getSetupById: environment.nodeApiUrl + "LetterCertificates/getSetupById/",
  checkForLetterDrafting:
    environment.nodeApiUrl + "LetterCertificates/checkForLetterDrafting",
  saveSetupData: environment.nodeApiUrl + "LetterCertificates/saveSetup/",
  getSetupList: environment.nodeApiUrl + "LetterCertificates/getSetupList",
  getSignatureList:
    environment.nodeApiUrl + "LetterCertificates/getSignaturiesList",
  getEmployeeData:
    environment.nodeApiUrl + "LetterCertificates/getSignaturyInfoByEmpId",
  saveSignatureSetup:
    environment.nodeApiUrl + "LetterCertificates/saveSignatureSetup",
  getSpecificEmployeeRequests:
    environment.nodeApiUrl +
    "LetterCertificates/specificEmployeeLetterRequests",
  getRequestInitialData:
    environment.nodeApiUrl + "LetterCertificates/getRequestInitialData",
  saveEmployeeLetterRequest:
    environment.nodeApiUrl + "LetterCertificates/LetterCertificateRequest",
  getEmployeeRequestDetailForApproval:
    environment.nodeApiUrl + "LetterCertificates/getRequestsForApproval",
  changeRequestStatusLM:
    environment.nodeApiUrl + "LetterCertificates/updateRequestStatusLM",
  changeRequestStatushr:
    environment.nodeApiUrl + "LetterCertificates/updateRequestStatusHR",
  getCategoryWiseOverview:
    environment.nodeApiUrl + "LetterCertificates/getCategoryWiseOverView",
  getFilterValues:
    environment.nodeApiUrl + "LetterCertificates/getGenericDropdownValues",
  getGeoDropdownValues:
    environment.nodeApiUrl + "LetterCertificates/getGeoDropdownValues",
  getComapnyWiseGeoData:
    environment.nodeApiUrl + "LetterCertificates/getComapnyWiseGeoData",
  getSentLettersCertificatesList:
    environment.nodeApiUrl +
    "LetterCertificates/getSentLettersCertificatesList",
  getSentLettersCertificatesCandidateList:
    environment.nodeApiUrl +
    "LetterCertificates/getSentLettersCertificatesListForCandidate",
  getBookmarks: environment.nodeApiUrl + "LetterCertificates/getTags",
  getPreview:
    environment.nodeApiUrl + "LetterCertificates/getPreviewWithoutTagData",
  getLetterGenerationList:
    environment.nodeApiUrl + "LetterCertificates/getLetterGenerationList",
  bookMarkFileUpload: environment.nodeApiUrl + "LetterCertificates/docsFile",
  getAllLettersData:
    environment.nodeApiUrl + "LetterCertificates/getAllLettersData",
  generateRequest:
    environment.nodeApiUrl + "LetterCertificates/generateRequest",
  generateRequestByFile:
    environment.nodeApiUrl + "LetterCertificates/bulkLetterUpload",
  checkForTagsData:
    environment.nodeApiUrl + "LetterCertificates/checkForTagsData",
  checkForTagsDataForEmployee:
    environment.nodeApiUrl + "LetterCertificates/checkForTagsDataForEmployee",
  checkForTagsDataByFile:
    environment.nodeApiUrl + "LetterCertificates/checkForTagsDataByFile",
  deleteUnconfirmedRequests:
    environment.nodeApiUrl + "LetterCertificates/deleteUnconfirmedRequests",
  getAllLetterRequestsForLM:
    environment.nodeApiUrl + "LetterCertificates/getAllLetterRequestsForLM",
  getCategories: environment.nodeApiUrl + "LetterCertificates/getCategories",
  getAllLetterRequestsForHR:
    environment.nodeApiUrl + "LetterCertificates/getAllLetterRequestsForHR",
  getSignaturiesSetupbyId:
    environment.nodeApiUrl + "LetterCertificates/getSignaturiesSetupbyId",
  getNonEmployeeSingleLetters:
    environment.nodeApiUrl + "LetterCertificates/getNonEmployeeSingleLetters",
  getLetterGenerationListForSending:
    environment.nodeApiUrl +
    "LetterCertificates/getLetterGenerationListForSending",
  sendLetters: environment.nodeApiUrl + "LetterCertificates/sendLetters",
  getDownloadLettersByEmployeeIDs:
    environment.nodeApiUrl +
    "LetterCertificates/getDownloadLettersByEmployeeIDs",
  getTemplateWithDummyData:
    environment.nodeApiUrl + "LetterCertificates/getTemplateWithDummyData",
  getCandidateDownloadLetterTemplates:
    environment.nodeApiUrl +
    "LetterCertificates/getCandidateDownloadLetterTemplates",
  getDepartmentAndDesignationWiseEmployees:
    environment.nodeApiUrl +
    "LetterCertificates/getDepartmentAndDesignationWiseEmployees",
  getRequestById: environment.nodeApiUrl + "LetterCertificates/getRequestById",
  widrawRequest: environment.nodeApiUrl + "LetterCertificates/widrawRequest",
  resubmitApplication:
    environment.nodeApiUrl + "LetterCertificates/resubmitApplication",
  getDepartmentWiseDesignations:
    environment.nodeApiUrl + "LetterCertificates/getDepartmentWiseDesignations",
  getSingleFileMergeNames:
    environment.nodeApiUrl + "LetterCertificates/fileMerge",
  getSingleFileMergeNames2:
    environment.nodeApiUrl + "LetterCertificates/fileMerge2",
  getGeneratedFileCount:
    environment.nodeApiUrl + "LetterCertificates/getGeneratedFileCount",
  getGeneratedFileCount2:
    environment.nodeApiUrl + "LetterCertificates/getGeneratedFileCount2",
  getDownloadLettersByIds:
    environment.nodeApiUrl + "LetterCertificates/getDownloadLettersByIds",
  getDownloadLettersByIds2:
    environment.nodeApiUrl + "LetterCertificates/getDownloadLettersByIds2",
  generatePdfThroughWorkerQueue:
    environment.nodeApiUrl + "LetterCertificates/generatePdfThroughWorkerQueue",
  getCandidateDownloadLettersByIds:
    environment.nodeApiUrl +
    "LetterCertificates/getCandidateDownloadLettersByIds",
  getCandidateDownloadLettersByIds2:
    environment.nodeApiUrl +
    "LetterCertificates/getCandidateDownloadLettersByIds2",
  getLettersByCategory:
    environment.nodeApiUrl + "LetterCertificates/getLettersByCategory",
  getNextApproverFromQueue:
    environment.nodeApiUrl + "LetterCertificates/getNextApproverFromQueue",
  checkForCustomTags:
    environment.nodeApiUrl + "LetterCertificates/checkForCustomTags",
  uploadTagDataThroughUploader:
    environment.nodeApiUrl + "LetterCertificates/uploadTagDataThroughUploader",
  // Encode & Decode
  encryptAesCode: environment.phpApiHeader_300 + "EncryptAesCode",
  decryptAesCode: environment.phpApiHeader_300 + "DecryptAesCode",
  moveRequestsToHistory:
    environment.nodeApiUrl + "LetterCertificates/moveRequestsToHistory",
  deleteRecordFromWorkerQueue:
    environment.nodeApiUrl + "LetterCertificates/deleteRecordFromWorkerQueue",
  moveToHistoryCandidate:
    environment.nodeApiUrl + "LetterCertificates/moveToHistoryCandidate",
  getApplicationApprovelQueueDetail:
    environment.nodeApiUrl +
    "LetterCertificates/getApplicationApprovelQueueDetail",
  getApplicationApprovelQueueComments:
    environment.nodeApiUrl +
    "LetterCertificates/getApplicationApprovelQueueComments",
  getEmployeesAgainstRequest:
    environment.nodeApiUrl + "LetterCertificates/getEmployeesAgainstRequest",
  showSpecificFilters:
    environment.nodeApiUrl + "LetterCertificates/showSpecificFilters",
  getSpecificFilterData:
    environment.nodeApiUrl + "LetterCertificates/getSpecificFilterData",
  saveCategory: environment.nodeApiUrl + "LetterCertificates/saveCategory",
  getAllCategories:
    environment.nodeApiUrl + "LetterCertificates/getAllCategories",
  changeCategoryStatus:
    environment.nodeApiUrl + "LetterCertificates/updateCategory",
  getCategoryWiseCount:
    environment.nodeApiUrl + "LetterCertificates/getCategoryWiseCount",
  /* DMS Modules Api */
  // Add Documents
  document: environment.nodeApiUrl + "Document", // Setup Physical Location
  imageUpload: environment.nodeApiUrl + "file/upload",
  getFile: environment.nodeApiUrl + "file",

  // get company location filters
  staticData: environment.nodeApiUrl + "StaticData",

  // get branches based on cities
  orgChart: environment.nodeApiUrl + "Orgchart",

  // Personal Files Setup
  createLocation: environment.nodeApiUrl + "Document/locationList",
  getLocation: environment.nodeApiUrl + "Document/locationList",
  createDocumentCategoryField:
    environment.nodeApiUrl + "DocumentCategories/:id/fields",
  getDocumentCategoryField:
    environment.nodeApiUrl + "DocumentCategories/:id/fields",
  documentCategories: environment.nodeApiUrl + "DocumentCategories",

  /* NMS Module Api */

  notificationUrl: environment.nodeApiUrl + "Notification",

  // Bank Disbursement
  getDisbursementQueueData:
    environment.nodeApiUrl + "BankDisbursement/getDisbursementQueueData",
  getDashboardStats:
    environment.nodeApiUrl + "BankDisbursement/getDashboardStats",
  getBankWiseEmployeeCount:
    environment.nodeApiUrl + "BankDisbursement/getBankWiseEmployeeCount",
  getSpecificBankAllEmployees:
    environment.nodeApiUrl + "BankDisbursement/getSpecificBankAllEmployees",
  getQueueTicketsForFilter:
    environment.nodeApiUrl + "BankDisbursement/getQueueTicketsForFilter",
  processDisbursement:
    environment.nodeApiUrl + "BankDisbursement/processDisbursement",
  updateEmployeesDisbursementStatus:
    environment.nodeApiUrl +
    "BankDisbursement/updateEmployeesDisbursementStatus",
  viewSpecificBankQueueData:
    environment.nodeApiUrl + "BankDisbursement/viewSpecificBankQueueData",
  startDisbursement:
    environment.nodeApiUrl + "BankDisbursement/startDisbursement",
  sendApprovalToGroup2:
    environment.nodeApiUrl + "BankDisbursement/approval/sendApprovalToGroup2",
  disapproveDisbursement:
    environment.nodeApiUrl + "BankDisbursement/approval/disapproveDisbursement",
  startFileDisbursementProcess:
    environment.nodeApiUrl + "BankDisbursement/startFileDisbursementProcess",
  markBankAsComplete:
    environment.nodeApiUrl + "BankDisbursement/markBankAsComplete",
  checkForPendingChildQueue:
    environment.nodeApiUrl + "BankDisbursement/checkForPendingChildQueue",
  getApprovalEmployees:
    environment.nodeApiUrl + "BankDisbursement/approval/getApprovalEmployees",
  getApprovalSetup:
    environment.nodeApiUrl + "BankDisbursement/approval/getApprovalSetup",
  saveApprovalSetup:
    environment.nodeApiUrl + "BankDisbursement/approval/saveApprovalSetup",
  markMainQueueAsCompleted:
    environment.nodeApiUrl + "BankDisbursement/markMainQueueAsCompleted",
  getCompletedMainQueueRecords:
    environment.nodeApiUrl + "BankDisbursement/getCompletedMainQueueRecords",
  getMarkedCompletedQueueDetail:
    environment.nodeApiUrl + "BankDisbursement/getMarkedCompletedQueueDetail",
  getSpecificBankAllEmployeesFromSummary:
    environment.nodeApiUrl +
    "BankDisbursement/getSpecificBankAllEmployeesFromSummary",
  getFilterData: environment.nodeApiUrl + "BankDisbursement/getFilterData",
  checkForCheckerAuthorization:
    environment.nodeApiUrl + "BankDisbursement/checkForCheckerAuthorization",
  startManualFlowDisbursement:
    environment.nodeApiUrl + "BankDisbursement/startManualFlowDisbursement",
  startFileFlowDisbursement:
    environment.nodeApiUrl + "BankDisbursement/startFileFlowDisbursement",

  savePaymentEvidenceFiles:
    environment.nodeApiUrl + "BankDisbursement/savePaymentEvidenceFiles",
  savePaymentEvidenceFilesBKIP:
    environment.nodeApiUrl + "BankDisbursement/savePaymentEvidenceFilesBKIP",
  manualDisbursementThroughResponseFile:
    environment.nodeApiUrl +
    "BankDisbursement/manualDisbursementThroughResponseFile",
  getBankDocuments:
    environment.nodeApiUrl + "BankDisbursement/getBankDocuments",
  canManualRecordBeCompleted:
    environment.nodeApiUrl + "BankDisbursement/canManualRecordBeCompleted",
  belongsToBankDisbursement:
    environment.nodeApiUrl + "BankDisbursement/belongsToBankDisbursement",
  getApprovalQueuePopupDetail:
    environment.nodeApiUrl +
    "BankDisbursement/approval/getApprovalQueuePopupDetail",
  getErrorReportData:
    environment.nodeApiUrl + "BankDisbursement/getErrorReportData",
  bd_sendOtp: environment.nodeApiUrl + "Notification/sendOtp",
  bd_verifyOtp: environment.nodeApiUrl + "Notification/verifyOtp",
  generateFinancialPin:
    environment.nodeApiUrl + "Notification/generateFinancialPin",
  verifyFinancialPin:
    environment.nodeApiUrl + "Notification/verifyFinancialPin",
  checkForResponse:
    environment.nodeApiUrl + "BankDisbursement/checkForResponse",
  // Bank Disbursement

  getCompaniesBanks:
    environment.nodeApiUrl + "SecurityPolicy/disbursement/company-banks",
  // getBankDisbursementDetails:
  //   environment.nodeApiUrl +
  //   "SecurityPolicy/disbursement/employee-disbursements",
  // banksGrouping:
  //   environment.nodeApiUrl + "SecurityPolicy/disbursement/banks-grouping",
  // destinationBanksWiseDetail:
  //   environment.nodeApiUrl +
  //   "SecurityPolicy/disbursement/destination-banks-wise-detail",
  //disbursementRequest: environment.nodeApiUrl + "SecurityPolicy/disbursement",
  // bankingGroupDisbursementRequest: environment.nodeApiUrl + 'SecurityPolicy/disbursement',
  // getOtpCode: environment.nodeApiUrl + 'SecurityPolicy/otp/generateOTP',
  // verifyOtpCode: environment.nodeApiUrl + 'SecurityPolicy/otp/verifyOTP',
  // getDisburseErrorDetails: environment.nodeApiUrl + 'SecurityPolicy/disbursement/error-details',
  disbursementRequest: "SecurityPolicy/disbursement",
  bankingGroupDisbursementRequest: "SecurityPolicy/disbursement",
  getOtpCode: "SecurityPolicy/otp/generateOTP",
  verifyOtpCode: "SecurityPolicy/otp/verifyOTP",
  getDisburseErrorDetails: "SecurityPolicy/disbursement/error-details",

  // payment Disbursement
  getDisbursementSummary:
    environment.nodeApiUrl + "Payroll/PaymentDisbursement/disbursement-summary",

  // payment Disbursement
  getPostJVRecord: environment.nodeApiUrl + "Payroll/erp/get?infoId=",

  //Employee Roster getEmpRoster
  getEmpRoster: environment.nodeApiUrl + "Roster/GetEmpRoster",

  //forget Password
  forgetPassword: environment.nodeApiUrl + "Auth/ForgetPassword",
  generateOTP: environment.nodeApiUrl + "SecurityPolicy/otp/generateOTP",
  verifyOtp: environment.nodeApiUrl + "SecurityPolicy/otp/verifyOTP",
  changePassword: environment.nodeApiUrl + "Auth/ForgetPassword/changePassword",

  /***************************************************************************************** */

  // DANGER                             ARHAM SOFT - START

  /****************************************************************************************** */

  getAward: environment.laravelApiUrl + "performancereward/award/awards",
  getCeoAward:
    environment.laravelApiUrl + "performancereward/award/ceo-award-list",
  getCeoPromotion:
    environment.laravelApiUrl + "performancereward/award/ceo-promotion-list",
  getBulkApproval:
    environment.laravelApiUrl +
    "performancereward/award/change-multiple-award_status",
  getDepartment: environment.laravelApiUrl + "performancereward/departments",
  getDesignation: environment.laravelApiUrl + "performancereward/designations",
  getDesignationOnDepartment: environment.laravelApiUrl + "common/designations",
  getAwardType:
    environment.laravelApiUrl + "performancereward/award/award-types",
  getNameForAwards:
    environment.laravelApiUrl + "performancereward/award/employee-for-reward",
  getEmployeeAwards:
    environment.laravelApiUrl + "performancereward/award/show-award",
  postAward: environment.laravelApiUrl + "performancereward/award/awards-store",
  postAwardLetter:
    environment.laravelApiUrl + "performancereward/award/submit_awards",
  getStatus: environment.laravelApiUrl + "performancereward/award/recent-award",
  withdraw:
    environment.laravelApiUrl + "performancereward/award/withdraw-award",
  changeStatus:
    environment.laravelApiUrl + "performancereward/award/change-award-status",
  attachment:
    environment.laravelApiUrl + "performancereward/award/award-attachments",
  promotionAttachment:
    environment.laravelApiUrl +
    "performancereward/promotion/promotion-attachments",
  chartData: environment.laravelApiUrl + "performancereward/award/award-status",
  uploadFile:
    environment.laravelApiUrl + "performancereward/award/award-upload-csv",
  approvalQueueAwards:
    environment.laravelApiUrl +
    "performancereward/award/get-approval-queue-details",
  templateDownload: environment.phpApiHeader_300 + "award-csv-template",

  //Promotion Apis
  getPromotions:
    environment.laravelApiUrl + "performancereward/promotion/promotions",
  getAppraisals:
    environment.laravelApiUrl + "performancereward/promotion/appraisal-types",
  getEmployees:
    environment.laravelApiUrl +
    "performancereward/promotion/promotion-employees",
  getRecentPromotion:
    environment.laravelApiUrl + "performancereward/promotion/recent-promotion",
  getBands: environment.laravelApiUrl + "performancereward/promotion/bands",
  getPerformanceLocation:
    environment.laravelApiUrl + "performancereward/promotion/location",
  getPromotionReason:
    environment.laravelApiUrl + "performancereward/promotion/promotion-reason",
  getPromotionTypes:
    environment.laravelApiUrl + "performancereward/promotion/promotion-types",
  getEmployeePromotion:
    environment.laravelApiUrl + "performancereward/promotion/show-promotion",
  postPromotions:
    environment.laravelApiUrl + "performancereward/promotion/promotion-store",
  withDrawPromotions:
    environment.laravelApiUrl +
    "performancereward/promotion/withdraw-promotion",
  changePromotionStatus:
    environment.laravelApiUrl +
    "performancereward/promotion/change-promotion-status",
  approvalQueuePromotion:
    environment.laravelApiUrl +
    "performancereward/promotion/get-approval-queue-details",

  setEmpAssets:
    environment.laravelApiUrl + "exitmanagement/employee/save-employee-assets",
  setFinanceClearance:
    environment.laravelApiUrl +
    "exitmanagement/employee/save-finance-clearance",
  setEmpResponsibility:
    environment.laravelApiUrl +
    "exitmanagement/employee/save-employee-responsibility",
  setResignation:
    environment.laravelApiUrl +
    "exitmanagement/resignation/save-Resignation-application",
  getResignation:
    environment.laravelApiUrl + "exitmanagement/resignation/get-resignation",
  changeResignationStatus:
    environment.laravelApiUrl +
    "exitmanagement/resignation/change-resignation-status",
  getAssests: environment.laravelApiUrl + "exitmanagement/employee/get-assets",
  getEmployee: environment.laravelApiUrl + "exitmanagement/employees",
  getResponsibility:
    environment.laravelApiUrl + "exitmanagement/employee/get-responsibility",
  getEmpAssets:
    environment.laravelApiUrl + "exitmanagement/employee/get-employee-assets",
  approvalQueueExit:
    environment.laravelApiUrl +
    "exitmanagement/resignation/get-approval-queue-details",
  approvalQueueAttendanceCorrection:
    environment.laravelApiUrl + "user/GetApplicationApprovelDetail",
  getEmployeeContractStatus:
    environment.laravelApiUrl +
    "exitmanagement/employee/get-employee-contract-status",
  updateResignationApplication:
    environment.laravelApiUrl +
    "exitmanagement/resignation/update-resignation-application",

  // to get resignation with respect to employee id
  getEmpResignation:
    environment.laravelApiUrl +
    "exitmanagement/resignation/get-employee-resignation-application",

  getECF:
    environment.laravelApiUrl + "exitmanagement/ecf/get-ecf-launch-resignation",
  getEmpResponsibility:
    environment.laravelApiUrl +
    "exitmanagement/employee/get-employee-responsibility",
  updateEmpAssets:
    environment.laravelApiUrl +
    "exitmanagement/employee/update-employee-assets",
  updateEmpResponsibility:
    environment.laravelApiUrl +
    "exitmanagement/employee/update-employee-responsibility",
  getQuestion:
    environment.laravelApiUrl + "exitmanagement/exitInterview/get-questions",
  getResignationStatus:
    environment.laravelApiUrl +
    "exitmanagement/resignation/get-resignation-application",
  handedOverTo:
    environment.laravelApiUrl +
    "exitmanagement/employee/get-same-dept-employees",

  getDropdownValues:
    environment.laravelApiUrl + "exitmanagement/get_drop_down_value",
  submitResignationApplication:
    environment.laravelApiUrl +
    "exitmanagement/resignation/save-resignation-application",
  saveEcfApprovel:
    environment.laravelApiUrl + "exitmanagement/ecf/save-ecf-approvel",
  getApprovedEcfs:
    environment.laravelApiUrl +
    "exitmanagement/ecf/get-ecf-approved-application",
  deleteResponsibilty:
    environment.laravelApiUrl + "exitmanagement/employee/delete-responsibility",
  deleteAsset:
    environment.laravelApiUrl + "exitmanagement/employee/delete-asset",
  submitFinalSettlement:
    environment.laravelApiUrl + "exitmanagement/employee/save-final-settlement",
  getEmpSalary:
    environment.laravelApiUrl + "exitmanagement/employee/get-employee-salary",
  saveResDecision:
    environment.laravelApiUrl +
    "exitmanagement/resignation/save-resignation-decision",
  updateAssetsStatus:
    environment.laravelApiUrl +
    "exitmanagement/employee/add-update-assets-status",
  getExitInterview:
    environment.laravelApiUrl +
    "exitmanagement/exitInterview/get-exit-interview",
  saveExitInterviewQuestions:
    environment.laravelApiUrl +
    "exitmanagement/exitInterview/save-exit-interview",
  submitFinanceClearance:
    environment.laravelApiUrl +
    "exitmanagement/employee/save-finance-clearance",
  getEmpResApplications:
    environment.laravelApiUrl +
    "exitmanagement/resignation/get-employee-resignation-applications",
  getHrRemarks:
    environment.laravelApiUrl +
    "exitmanagement/resignation/get-resignation-decision",
  getFinalSettlement:
    environment.laravelApiUrl + "exitmanagement/employee/get-final-settlement",
  getEmpDues:
    environment.laravelApiUrl + "exitmanagement/employee/get-employee-dues",
  getEmpFinanceClearance:
    environment.laravelApiUrl +
    "exitmanagement/employee/get-employee-finance-clearance",

  /***************************************************************************************** */

  // DANGER                             ARHAM SOFT - END

  /****************************************************************************************** */

  // Write here

  /*__________________________________________*/
  // Attendance Correction
  /*__________________________________________*/

  // get attendance Correction montly status
  getEmpMonthlyCorrectionStatus:
    environment.laravelApiUrl +
    "attendance_correction/employee_portal/monthly_correction_status",
  getLmMonthlyCorrectionStatus:
    environment.laravelApiUrl +
    "attendance_correction/lm_portal/monthly_correction_status",
  getHrMonthlyCorrectionStatus:
    environment.laravelApiUrl +
    "attendance_correction/hr_portal/monthly_correction_status",

  // get attendance  Application List
  getEmpCorrectionApplicationList:
    environment.laravelApiUrl +
    "attendance_correction/employee_portal/correction_application_list",
  getLmCorrectionApplicationList:
    environment.laravelApiUrl +
    "attendance_correction/lm_portal/correction_application_list",
  getHrCorrectionApplicationList:
    environment.laravelApiUrl +
    "attendance_correction/hr_portal/correction_application_list",

  // get attendance details/meta API
  getEmpRosterDetails:
    environment.laravelApiUrl +
    "attendance_correction/employee_portal/get_roster_details",
  getEmpShiftDetails:
    environment.laravelApiUrl +
    "attendance_correction/employee_portal/get_shift_details",
  getAllLeaveTypes:
    environment.laravelApiUrl +
    "attendance_correction/employee_portal/get_all_leave_types",
  getEmpShiftTypes:
    environment.laravelApiUrl +
    "attendance_correction/employee_portal/get_shift_types",
  getAttendApplicationDetails:
    environment.laravelApiUrl + "attendance_correction/get_application_details",
  getEmpLeaveTypes:
    environment.laravelApiUrl +
    "attendance_correction/employee_portal/get_leave_types",

  // Line Manger Correction application
  createLmAttendanceCorrectionRemarks:
    environment.laravelApiUrl +
    "attendance_correction/lm_portal/attendance_correction_remarks",
  createLmshiftChangeRemarks:
    environment.laravelApiUrl +
    "attendance_correction/lm_portal/shift_change_remarks",

  // HR Correction application
  createHRAttendanceCorrectionRemarks:
    environment.laravelApiUrl +
    "attendance_correction/hr_portal/attendance_correction_remarks",
  createHRLeaveReversalRemarks:
    environment.laravelApiUrl +
    "attendance_correction/hr_portal/leave_reversal_remarks",
  //HR update Hr Bulk Upload
  updateHrBulkUpload:
    environment.laravelApiUrl +
    "attendance_correction/hr_portal/attendance_bulk_upload",
  hrdirectAttendance:
    environment.laravelApiUrl +
    "attendance_correction/hr_portal/hr_direct_attendance_approved",

  // Employee Correction application
  createEmpAttendCorrection:
    environment.laravelApiUrl +
    "attendance_correction/employee_portal/attendance_correction",
  createEmpLeaveReversalRequest:
    environment.laravelApiUrl +
    "attendance_correction/employee_portal/leave_reversal_request",
  createEmpShiftChangeRequest:
    environment.laravelApiUrl +
    "attendance_correction/employee_portal/shift_change_request",
  // get Grievance Meta data

  // Roster Module Api
  rosterUrl: environment.nodeApiUrl + "roster",
  // Shift Allocation
  shiftAllocationUrl: environment.nodeApiUrl + "ShiftAllocation",
  //ShiftRequestAndSetup
  ShiftRequestAndSetupUrl: environment.nodeApiUrl + "ShiftRequestAndSetup",
  workCalendarUrl: environment.nodeApiUrl + "WorkCalendar",
  rosterAttendance: environment.nodeApiUrl + "Attendance",

  //ATS Module API */

  ATS: environment.nodeApiUrl + "ATS",
  VacantJobList: environment.phpSiteUrl,
  ATSVacantJobList: environment.nodeApiUrl,

  // HR Lm Interview
  lmInterview: environment.phpSiteUrl + "org",

  // get Employee Details

  empDetails: environment.phpSiteUrl + "employeprofile/employeeProfileDetail",

  // get Job Details

  jobDescription: environment.phpSiteUrl + "employeprofile/getVacantJobDetails",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
