/**
 * @author Usman Ali <usman.ali@people.com.pk>
 */
import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "input[floatNumbers]",
})
export class FloatNumbersDirective {
  constructor(private _el: ElementRef) {}
  @HostListener("input", ["$event"]) onInputChange(event): void {
    const initalValue = this._el.nativeElement.value;
    var floatValues: RegExp =  /[+-]?([0-9]*[.])?[0-9]+/;
    if (!initalValue.match(floatValues) && !isNaN(initalValue)) {
      event.stopPropagation();
    }
  }
}
