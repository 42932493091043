<ngx-graph *ngIf="links.length" class="chart-container" [links]="links" [nodes]="nodes" [curve]="curve" [layout]="layout" [nodeWidth]=250
  [nodeHeight]=100 [layoutSettings]="layoutSettings" [enableZoom]="true" [autoZoom]="false" [zoomLevel]="0.2" [panToNode]="'0'"
  [draggingEnabled]="false" [autoCenter]="true">

  <ng-template #nodeTemplate let-node>
    <svg:g class="node" xmlns="http://www.w3.org/2000/xhtml" width="150" height="100">
      <svg:foreignObject width="250" height="200px">
        <xhtml:div class="cardContainer" xmlns="http://www.w3.org/1999/xhtml" [attr.ngStyle]=getStyles(node)>
          <label class="node_name" [ngClass]="node.data.department == 'Operations - CEO' ? 'ceo' : 'head-' + node.data.lm_lvl">{{node.label}}</label>
          <div class="node_meta">
            <img [attr.src]=" 'assets/emp_pictures/' + node.data.img" onerror="this.src = '../../../../assets/emp_pictures/male.png';" alt=""
              class="node_img" />
            <div class="node_meta_info">
              <label><b>{{node.data.role}}</b></label>
              <label>{{node.data.department}}</label>
            </div>
          </div>
        </xhtml:div>
      </svg:foreignObject>
    </svg:g>
  </ng-template>

  <!-- [attr.d]="link.data.expectedLevel ? orgChart.refactorPath(link, link.line, link.data.currentLevel, link.data.expectedLevel) : link.line" -->
  <ng-template #linkTemplate let-link>
    <svg:g class="edge">
      <svg:path class="line" [attr.stroke]="link.data.strokeColor" [attr.stroke-dasharray]="link.data.strokeDash"
        stroke-width="3" [attr.d]="link.line" marker-end="url(#arrow)">
      </svg:path>
      <!-- <svg:text class="edge-label" text-anchor="middle">
        <textPath class="text-path" [attr.href]="'#' + link.id" [style.dominant-baseline]="link.dominantBaseline"
          startOffset="50%">{{link.label}}</textPath>
      </svg:text> -->
      <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="3" markerHeight="4" orient="auto">
        <svg:path [attr.fill]="link.data.strokeColor" [attr.stroke]="link.data.strokeColor" d="M0,-5L10,0L0,5"
          class="arrow-head" />
      </svg:marker>
    </svg:g>
    <!-- <svg:g class="linkMidpoint" *ngIf="link.midPoint"
      [attr.transform]="'translate(' + (link.midPoint.x) + ',' + (link.midPoint.y) + ')'">
      <ellipse rx="30" ry="10" />
      <svg:text alignment-baseline="central">{{ link.data.linkText }}</svg:text>
    </svg:g> -->
  </ng-template>
</ngx-graph>
