import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  public isLoading = new BehaviorSubject(false);
  public appBackground = new BehaviorSubject("");
  public appFooterBackground = new BehaviorSubject("");
  public appMenuBackground = new BehaviorSubject("");
  public appSecondTierMenuBackground = new BehaviorSubject("");
  public appSecondTierMenuHover = new BehaviorSubject("");
  public respMessage = new BehaviorSubject(null);
  public profile = new BehaviorSubject(localStorage.getItem("profile"));
  public companyLogo = new BehaviorSubject(localStorage.getItem("companyLogo"));

  codeSuccess = [
    200,
    "200",
    201,
    "201",
    202,
    "202",
    203,
    "203",
    204,
    "204",
    205,
    "205",
    206,
    "206",
    207,
    "207",
    208,
    "208",
    226,
    "226",
    "success",
    "Success",
  ];
  codeInfo = [
    300,
    "300",
    301,
    "301",
    302,
    "302",
    303,
    "303",
    304,
    "304",
    305,
    "305",
    306,
    "306",
    307,
    "307",
    308,
    "308",
    "Info",
    "info",
  ];
  codeWarning = ["warning", "Warning"];
  codeInvalid = [
    400,
    500,
    "500",
    "400",
    401,
    "401",
    402,
    "402",
    403,
    "403",
    404,
    "404",
    405,
    "405",
    406,
    "406",
    407,
    "407",
    408,
    "408",
    "408",
    "failed",
    "error",
  ];

  constructor(private toastr: ToastrService) {
    let appBackground = "full-page-background";
    let appFooterBackground = "footer_wrapper";
    let appMenuBackground = "main_navigation";
    let appSecondTierMenuHover = "hover_color";
    let appSecondTierMenuBackground = "second_tier_menu";
    if (localStorage.getItem("dashboard_color"))
      appBackground = localStorage.getItem("dashboard_color");
    this.appBackground.next(appBackground);
    if (localStorage.getItem("footer_color"))
      appFooterBackground = localStorage.getItem("footer_color");
    this.appFooterBackground.next(appFooterBackground);
    if (localStorage.getItem("menu_color"))
      appMenuBackground = localStorage.getItem("menu_color");
    this.appMenuBackground.next(appMenuBackground);
    if (localStorage.getItem("second_tier_menu_color"))
      appSecondTierMenuBackground = localStorage.getItem(
        "second_tier_menu_color"
      );
    this.appSecondTierMenuBackground.next(appSecondTierMenuBackground);
    if (localStorage.getItem("second_tier_menu_hover_color"))
      appSecondTierMenuHover = localStorage.getItem(
        "second_tier_menu_hover_color"
      );
    this.appSecondTierMenuHover.next(appSecondTierMenuHover);

    this.respMessage.subscribe((res: any) => {
      if (res || res?.length) {
        if (res?.code && this.codeSuccess.includes(res.code)) {
          this.toastr.success(res.message);
        } else if (res?.code && this.codeInfo.includes(res.code)) {
          this.toastr.info(res.message);
        } else if (res?.code && this.codeWarning.includes(res.code)) {
          this.toastr.warning(res.message);
        } else if (res?.code && this.codeInvalid.includes(res.code)) {
          this.toastr.error(res.message);
        } else {
          this.toastr.error(res.message);
        }
      }
    });
  }
}
