import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ChartFullViewComponent } from './chart-full-view.component';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../../shared/modules/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartSidebarModule } from '../chart-sidebar/chart-sidebar.module';
import { OrgmapModule } from '../orgmap/orgmap.module';
import { FullchartDetailComponent } from '../chart-full-view/fullchart-detail/fullchart-detail.component';
import { SidebarModule } from 'ng-sidebar';
import { MlOrgchartModule } from '../ng-org-chart/ml-orgchart/ml-orgchart.module';

const route: Routes = [
  { path: '', component: ChartFullViewComponent }
];

@NgModule({
  declarations: [
    ChartFullViewComponent,
    FullchartDetailComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ChartSidebarModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MlOrgchartModule,
    SidebarModule,
    OrgmapModule,
    RouterModule.forChild(route),
  ]
})
export class ChartFullViewModule { }
