import { Directive, EventEmitter, Output, Self } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { SPACE } from "@angular/cdk/keycodes";

@Directive({
  selector: "[no-space]",
})
export class NoSpaceDirective {
  @Output("onRestrictSpace") onRestrictSpace: EventEmitter<any> =
    new EventEmitter<any>();

  constructor(@Self() private select: MatSelect) {
    this.select._handleKeydown = (event: KeyboardEvent) => {
      if (event.keyCode == SPACE) {
        if (!this.select.disabled) {
          this.select.panelOpen
            ? (this.select as any)._handleOpenKeydown(event)
            : (this.select as any)._handleClosedKeydown(event);
        }
      } else {
        if (!this.select.disabled) {
          this.select.panelOpen
            ? (this.select as any)._handleOpenKeydown(event)
            : (this.select as any)._handleClosedKeydown(event);
        }
      }
    };
  }
}
