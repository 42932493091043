import { Component, OnInit, ViewEncapsulation,
  AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import MarkerClusterer from '@google/markerclusterer';
import { OrgMapService } from '../../services/org-map.service';
import { FilterDataEmitterService } from '../../services/filter-data-emitter.service';
import * as mapThemes from '../../shared/config/map_themes.json';
import { MapThemeEmitterService } from '../../services/map-theme-emitter.service';
import { OrgchartService } from 'src/app/services/orgchart.service';

declare var $: any;

@Component({
  selector: 'app-orgmap',
  templateUrl: './orgmap.component.html',
  styleUrls: ['./orgmap.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ OrgMapService ]
})
export class OrgmapComponent implements OnInit, AfterViewInit {

  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;

  map: google.maps.Map;
  lat = null;
  lng = null;

  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {}

  mapPopupWindow = null;

  /*map filters objects init*/

  public filterBranch:string        =   '';
  public filterCountry:string       =   '';
  public filterCity:string          =   '';
  public filterJobGroup:string      =   '';
  public filterJobFamily:string     =   '';
  public filterDepartment:string    =   '';
  public filterDesignation:string   =   '';
  public filterBand:string          =   '';

  message: boolean;

  themeNumber: number;

  themes: any = (mapThemes as any).default;

  selectedMaptheme: any;

  /**
   * orgchart predefine data
   */

    orgChartView:string = (typeof sessionStorage.getItem('session_view') !== 'undefined' &&
    sessionStorage.getItem('session_view') !== null) ? sessionStorage.getItem('session_view') : "3";

    orgChartTheme:string = (typeof sessionStorage.getItem('session_number') !== 'undefined' &&
    sessionStorage.getItem('session_number') !== null) ? sessionStorage.getItem('session_number') : "1";

    orgChartImage:string = (typeof sessionStorage.getItem('session_color') !== 'undefined' &&
    sessionStorage.getItem('session_color') !== null) ? sessionStorage.getItem('session_color') : "1";

  constructor(
    private _orgMap: OrgMapService,
    // private filterDataEmitter: FilterDataEmitterService,
    private mapThemeEmitter: MapThemeEmitterService,
    private _orgChartService: OrgchartService )
    {
      this._orgChartService.orgMapComponent = this
    }

  ngOnInit() {

    this._orgChartService.selectedMapTheme.next(this.themes[0].green_map);

    this._orgChartService.activeMapView.subscribe(message => this.message = message);

    this._orgChartService.selectedMapThemeNumber.subscribe(message => this.themeNumber = message);

  }

  ngAfterViewChecked() {

    if($('#init_zoom').val() == 1) {

        this.mapPopupWindow.close();
        this.map.setZoom( this.map.getZoom() + 1 );
        $('#init_zoom').val("0");
    }

    if(this.message === false) {

        this.filterCountry = $("#country").val();
        this.filterCity = $("#city").val();
        this.filterBranch = $("#branch").val();
        this.filterJobGroup = $("#job_group").val();
        this.filterJobFamily = $("#job_family").val();
        this.filterDepartment = $("#department").val();
        this.filterDesignation = $("#designation").val();
        this.filterBand = $("#band").val();

        var filterData =  "filter=1&branch="+this.filterBranch+"&country="+this.filterCountry+"&city="+this.filterCity
                        +"&group="+this.filterJobGroup+"&family="+this.filterJobFamily+"&department="+this.filterDepartment
                        +"&designation="+this.filterDesignation+"&band="+this.filterBand;

        this.mapLoader(filterData);

        this.message = true;

    }

    if(this.themeNumber !== 0) {

        this.applyMapTheme( this.themeNumber );

        this.themeNumber = 0;

    }

  }

  ngAfterViewInit() {

    this.mapLoader();

  }

  receiveMessage($event) {

    this.message = $event;

  }

  mapLoader(filters:any = '') {

    this._orgMap.getMapBranches(filters).subscribe( data => {

        if(data.length > 0) {

        this.lat = data["0"].latitude;
        this.lng = data["0"].longitude;

        this.coordinates = new google.maps.LatLng(this.lat, this.lng);
        let styles:any = this._orgChartService.selectedMapTheme.value
        this.mapOptions = {
            center: this.coordinates,
            zoom: 5,
            disableDefaultUI: false, // a way to quickly hide all controls
            mapTypeControl: false,
            scaleControl: false,
            zoomControl: true,
            fullscreenControl: false,
            styles: styles,
        };

        var locations = [];
        for(var i in data) {
            locations.push( [
                `<div class='option_map'>
                    <div class='title'>
                        `+data[i].loc_desc+`
                    </div>
                    <ul><li>
                        <a href="#" class="show_orgchart"
                                data-country="`+data[i].country_id+`" data-city="`+data[i].city_id+`"
                                data-branch="`+data[i].loc_id+`" data-employees="`+data[i].employees+`">
                                    Show Branch Org Chart
                        </a>
                    </li>
                    <li>
                        <a href="#" class="show_orgchart">
                                Show Company Org Chart
                        </a>
                    </li>
                    </ul></div>`,
                data[i].latitude,
                data[i].longitude,
                data[i].loc_id,
                "img/green_pin.svg"
              ], );

        }

        this.mapInitializer( locations );

        }
        else {
            alert("No Record found for your applied filters");
        }

    });
  }

  mapInitializer( locations ) {
      let marker;
      let i;
      let markers = [];

      var infowindow = new google.maps.InfoWindow();

      this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);

      for (i = 0; i < locations.length; i++) {

        marker = new google.maps.Marker({
            position: new google.maps.LatLng(locations[i]['1'], locations[i]['2']),
            map: this.map
        });

        google.maps.event.addListener(marker, 'click', ((marker, i) => {
            return function() {
            infowindow.setContent(locations[i]['0']);
            infowindow.open(this.map, marker);
            // this.map.setZoom(8);
            this.map.setCenter(marker.getPosition());
            }
        })(marker, i));

        /*google.maps.event.addListener(marker, 'mouseover', ((marker, i) => {
            return function() {
            infowindow.setContent(locations[i]['0']);
            infowindow.open(this.map, marker);
            }
        })(marker, i));*/

        // assuming you also want to hide the infowindow when user mouses-out
        /*marker.addListener('mouseout', function() {
            infowindow.close();
        });*/
        markers.push( marker );

        }

        var c_map = this.map;
        var c_coordinates = this.coordinates

        google.maps.event.addListener(infowindow, 'closeclick', function() {

            c_map.setZoom(5);
            c_map.setCenter(c_coordinates);

        });

        const markerCluster = new MarkerClusterer(c_map, markers,
            {
                imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                zoomOnClick: true
            });

        google.maps.event.addListener(markerCluster, "click", function(cluster) {
            event.preventDefault();

              infowindow.setContent(`<div class='option_map'>
              <ul><li>
                  <a href="#" onclick="mapZoomIn(event)" class="zoomin_map" data-branch="0">
                              Zoom In
                  </a>
              </li>
              <li>
                  <a href="#" class="show_orgchart" data-branch="0">
                          Show Company Org Chart
                  </a>
              </li>
              </ul></div>`);
              infowindow.setPosition(cluster.getCenter());
              infowindow.open(c_map);

        });

        const that = this;

        infowindow.addListener('domready', () => {
            let item:any = document.getElementsByClassName('show_orgchart');
            for (const iterator of item) {

                iterator.addEventListener("click", ($event) => {
                  that.showOrgchart($($event.target).data());
                })

            }
        })

        this.mapPopupWindow = infowindow;
  }

  applyMapTheme( mapTheme:number ) {

    if(mapTheme == 4) {
        this._orgChartService.selectedMapTheme.next(this.themes[0].default_map);
    }
    else if(mapTheme == 1) {
        this._orgChartService.selectedMapTheme.next(this.themes[0].green_map);
    }
    else if(mapTheme == 2) {
        this._orgChartService.selectedMapTheme.next(this.themes[0].blue_map);
    }
    else if(mapTheme == 3) {
        this._orgChartService.selectedMapTheme.next(this.themes[0].silver_map);
    }
    else {
        this._orgChartService.selectedMapTheme.next(this.themes[0].default_map);
    }

    this.mapLoader();

  }

  showOrgchart(data: any) {
   
    if(Object.keys(data).length > 0) {
      const queryParams = this.objectToQueryParams(data);
      this._orgChartService.orgFilterData.next(queryParams);
      sessionStorage.setItem('branchData',queryParams);
    }
 
    else {

      this._orgChartService.orgFilterData.next(null);
      sessionStorage.removeItem('branchData');
    }

    this._orgChartService.populateOrgChart()
    this._orgChartService.activeMapView.next(false)
  }

  objectToQueryParams(obj) {
    const queryParams = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== '' || obj[key]?.length > 0) {
        queryParams.push(`${key}=${(obj[key]).toString()}`);
      }
    }

    return queryParams.join('&');
  }

  @HostListener('document:click', ['$event.target'])
  verifyClick(event: Event) {

   if($(event).hasClass('menu-0-0')) {

    this._orgChartService.activePopupDetails(event);

   }

  }

}
