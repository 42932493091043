<div class="header-wrapper row position-relative">
  <div class="col-md-12 p-0">
    <div class="statusMenu">
      <ul class="main-list">
        <li><img class="_profile_img" [src]="profile" (error)="verifyImage($event)" /></li>
        <li>
          <b class="align-middle">{{ employeeName }}</b>
        </li>
        <li><img src="assets/img/login_statusbar_saperator.gif" /></li>
        <li class="select m-0">
          <div class="btn-group" dropdown>
            <button type="button" class="btn btn-white label-btn portal-name" dropdownToggle>
              {{ selectedPortal?.name }}
              <span class="caret-img"></span>
            </button>
            <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-split">
              <li role="menuitem" *ngFor="let portal of portals">
                <a class="dropdown-item"
                  (click)="selectPortal(e, portal, null); portal.has_child ? $event.stopPropagation() : null"
                  [ngClass]="{
                    'dropdown-menu-selected': selectedPortal?.name === portal.name
                  }"
                  [ngClass]="portal.is_enable == 0 && portal.has_child == 0  ? 'portal-icon-disable' : ''"
                  [title]="portal.name"
                >
                    {{portal.name }}
                </a>
                <ul *ngIf="portal?.subPortals?.length" class="sub-portal sub-portals-list">
                  <li role="menuitem" *ngFor="let subPortal of portal.subPortals">
                    <a class="dropdown-item"
                      (click)="selectPortal(e, portal , subPortal)"
                      [ngClass]="{'dropdown-menu-selected': selectedPortal?.name == subPortal.name}"
                      [ngClass]="subPortal.is_enable == 0 ? 'portal-icon-disable' : ''"
                      [title]="subPortal.name">
                      {{subPortal.name }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div class="btn-group disabledDiv ml-2" dropdown>
            <button type="button" class="btn btn-white label-btn portal-name" dropdownToggle>
              {{companyName}}
              <span class="caret-img company-spa"></span>
            </button>
            <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-split">
              <li role="menuitem">
                <a class="dropdown-item" title="People">People</a>
                <a class="dropdown-item" title="Keystone">Keystone</a>
                <a class="dropdown-item" title="Heritage">Heritage</a>
              </li>
            </ul>
          </div>
        </li>
        <li><img src="assets/img/login_statusbar_saperator.gif" /></li>
        <li>
          <a [href]="policyManualLink" target="_blank" title="HR Policy Manual" target="_blank">
            <img src="assets/img/book.png" />
          </a>
        </li>
        <li><img src="assets/img/login_statusbar_saperator.gif" /></li>
        <li>
          <a class="disabled" href="#" title="Help Center" target="_blank">
            <img src="assets/img/help.png" />
          </a>
        </li>
        <li><img src="assets/img/login_statusbar_saperator.gif" /></li>
        <li>
          <a [href]="userManualLink" id="link_user_manual" title="User Manual" target="_blank">
            <img src="assets/img/ico_info.png" />
          </a>
        </li>
        <li><img src="assets/img/login_statusbar_saperator.gif" /></li>
        <li>
          <a class="disabled" href="#" title="Change Password">
            <img src="assets/img/ico_logout.png" />
          </a>
        </li>
        <li><img src="assets/img/login_statusbar_saperator.gif" /></li>
        <li><a href="javascript:void(0);" (click)="logout()" title="Logout">Logout</a></li>
      </ul>
      <div class="statusMenuDate">
        {{ currentDate | date: "EEEE, d LLLL, yyyy" }} &nbsp;&nbsp;|&nbsp;&nbsp;
        <span id="miniclock">{{ clock }}</span>
      </div>
    </div>
    <img (click)="navigateHome()" [src]="companyLogo" (error)="verifyImage2($event)" alt="People" class="cropLogo"
      title="people" height="60" />
  </div>

  <div class="info-logo">
    <img src="../../../../../assets/images/logo32.svg" usemap="#image-map" alt="People" title="people" />
    <map name="image-map">
      <area target="" alt="" title="" href="javascript:void(0);" (click)="toggle('blue')" coords="0,13,31,1"
        shape="rect" />
      <area target="" alt="" title="" href="javascript:void(0);" (click)="toggle('blue')" coords="1,26,10,13"
        shape="rect" />

      <area target="" alt="" title="" href="javascript:void(0);" (click)="toggle('green')" coords="14,30,31,17"
        shape="rect" />
      <area target="" alt="" title="" href="javascript:void(0);" (click)="toggle('green')" coords="0,26,19,31"
        shape="rect" />
    </map>
  </div>

  <div class="w-100 px-0 this" (mouseleave)="openLink('', '', false)">
    <ul [class]="loader.appMenuBackground.value">
      <ng-container *ngFor="let main of headerService.header | async; let ind = index;">
        <li *ngIf="main.isDeleted===0" [ngClass]="[
            main.status === 1 ? '' : 'disabled_top',
            headerService.levelOneActive == main.id ? 'current' : '',
            ind == 0 ? 'first_item ml-0' : 'inner_item',
            ind == (headerService.header | async)?.length - 1
              ? 'last_item inner_item'
              : ''
          ]" (mouseenter)="headerService.menuHoverSelection(main.id, 1)"
          (click)="openLink(main.component_name, main.file_name, true)">
          <a [ngClass]="main.status === 1 ? '' : 'disabled'">
            <span class="image" *ngIf="headerService.levelOneActive == main.id">
              <img src="assets/icons/white-{{ main.icon }}" />
            </span>
            <span class="image" *ngIf="headerService.levelOneActive != main.id">
              <img src="assets/icons/{{ main.icon }}" />
            </span>
            {{ main.menu_title }}
          </a>
        </li>
      </ng-container>
    </ul>
    <ul [class]="loader.appSecondTierMenuBackground.value" class="second_tier_menu_height">
      <ng-container *ngFor="let mainSubMenu of headerService.levelOne | async">
        <li *ngIf="mainSubMenu.isDeleted===0" [ngClass]="mainSubMenu.status === 1 ? 'current' : 'disabled_top'"
          (mouseenter)="headerService.menuHoverSelection(mainSubMenu.id, 2)"
          (click)="openLink(mainSubMenu.component_name, mainSubMenu.file_name, true)">
          <a [ngClass]="[ mainSubMenu.status === 1 ? '' : 'disabled', mainSubMenu.id === headerService.levelTwoActive ? 'active' : '',
              mainSubMenu.id === headerService.levelTwoHover ? loader.appSecondTierMenuHover.value : ''
            ]">
            <span>{{ mainSubMenu.menu_title }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
    <ul [class]="loader.appSecondTierMenuBackground.value">
      <ng-container *ngFor="let menu of headerService.levelTwo | async">
        <li *ngIf="menu.menu_type_id < 5 && menu.isDeleted===0" [ngClass]="[
            menu.status === 1 ? 'current' : 'disabled_top',
            menu.component_name === 'lm_employee_attendance' ||
            menu.component_name === 'hr_employee_attendance'
              ? 'd-none'
              : ''
          ]" (click)="openLink(menu.component_name, menu.file_name, true)">
          <a [ngClass]="[
              menu.status === 1 ? '' : 'disabled',
              menu.id === headerService.levelThreeActive ? 'active' : '',
              menu.id === 118 && router.url === '/hr_employee_attendance'
                ? 'active'
                : ''
            ]">
            <span>{{ menu.menu_title }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="path mt-2 mb-2 hide-bread-crumbs">
    <span class="gray" *ngIf="headerService.breadCrumbHead" [ngClass]="{ active: !headerService.breadCrumbSecond }">{{
      headerService.breadCrumbHead }}</span>
    <span class="gray" *ngIf="headerService.breadCrumbSecond" [ngClass]="{ active: !headerService.breadCrumbThird }">
      / {{ headerService.breadCrumbSecond }}
    </span>
    <span class="gray" *ngIf="router.url === '/hr_employee_attendance'">
      / Profiles</span>
    <span class="gray" *ngIf="headerService.breadCrumbThird" [ngClass]="{
        active: !(headerService.breadCrumbForth | async).length && !empName
      }">
      / {{ headerService.breadCrumbThird }}
    </span>

    <ng-template ngIf="(headerService.breadCrumbForth | async).length">
      <span class="gray" *ngFor="
          let forth of headerService.breadCrumbForth | async;
          let ind = index
        " [ngClass]="{
          active: ind + 1 === (headerService.breadCrumbForth | async).length
        }">
        / {{ forth }}
      </span>
    </ng-template>
    <span class="gray" *ngIf="empName"> / {{ empName }} </span>
  </div>
</div>

<app-seamless-form></app-seamless-form>
