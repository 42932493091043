<!-- website footer -->
<div [class]="loaderService.appFooterBackground.value">
  <div class="container">
    <div id="footer" class="row">
      <div class="content col">Powered by People™ ©{{year}}. All Rights Reserved
        <!--&nbsp;&nbsp;&nbsp;|<a href="#">Privacy Policy</a>|<a href="#">Terms and Conditions</a-->
      </div>
    </div>
  </div>
</div>
