import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JQueryLoaderComponent } from './jquery-loader.component';

@NgModule({
  declarations: [JQueryLoaderComponent],
  imports: [CommonModule],
  exports: [JQueryLoaderComponent]
})
export class JqueryLoaderModule { }
