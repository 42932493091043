<div class="module-style category-field">
  <div class="m-0 justify-bw people-title-heading px-cus-10">
    <h4 class="px-cus-0">Add New Category Field</h4>
    <div class="col-1 justify-end px-cus-0 close" (click)="close()"><img src="assets/images/close.svg" /></div>
  </div>
  <div class="p-cus-20">
    <form
      [formGroup]="addCategoryFieldForm"
      (submit)="addCategoryField($event, input.value)"
    >


      <div class="row m-0 border-0 modal-popup emp-content pt-cus-0">
        <div class="col px-cus-0 modal-field mr-cus-6" id="model-input" style="height: 41px;">
          <input

            name="name"
            #input
            formControlName="categoryField"
            type="text"
            placeholder=" "
            class="emp-input input-float"
          />
          <p class="input-label">Category Field</p>
          <!-- <div
            *ngIf="
                  (validation.categoryField.touched || submitted) &&
                  validation.categoryField.errors?.required
                "
            class="error-message col-9 pr-0 text-right h-auto"
          >
            Required*
          </div> -->
        </div>
        <div class="px-cus-0 modal-btn justify-center">
          <button
            [disabled]="!addCategoryFieldForm.valid"
            [ngClass]="{'disabled' : !addCategoryFieldForm.valid}"
            type="submit"
            id="button"
          >
            Add More
          </button>
        </div>
      </div>
    </form>
    <div>
      <div><h4 class="" style="padding: 1px 0 10px 0 !important">Category Fields:</h4></div>
      <div class="add-address">
        <div
          *ngFor="let item of categoryFields; let i = index;"
          class="justify-bw p-6-5-10"
        >
          <div style="color: #343434 !important;">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button
      [disabled]="newCategoryFields.length == 0"
      [ngClass]="{'disabled' : newCategoryFields.length == 0}"
      type="button"
      (click)="saveCategoryFields()"
      id="button"
    >
      Save
    </button>
    <button
      type="button"
      (click)="close()"
      id="button">
      Cancel
    </button>
  </div>
</div>
