<div class="dashboard-wrraper bg-white">
    <div>

 
        <div  class="minHeight">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 text-center page-logo">
                        <img src="assets/img/logo2.png" alt="People" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center">
                        <h1 class="heading-font">Page not found</h1>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center">
                        <p class="heading-text">We are very sorry for the inconvenience. it looks like you're trying to <br /> access a page that has been deleted or even existed.</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" class="btn btn-primary" (click)="goBackToHomePage()">Go Back</button>
                    </div>
                </div>

                
            </div>
        </div>


    </div>
</div>