import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';
import { Subscription } from 'rxjs';
import { LoaderService } from "src/app/services/loader.service";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  public messages: any[] = [];
  public subscription: Subscription;

  constructor(private messageService: MessageService, private loader: LoaderService, public router: Router,
    public activatedRoute: ActivatedRoute) { 
  
     this.subscription = this.messageService.getMessage().subscribe(message => {
    
      

    });

  }


  ngOnInit() {
   
  }

  goBackToHomePage() {
    this.router.navigate(['/hrportal']);
  }

}
