<div class="confirmation-box">
    <h4 class="people-title-heading">
      {{ data.title }}
      <img (click)="onNoClick()" src="assets/images/close.svg">
    </h4>

    <mat-dialog-content class="p-3" style="font: normal normal normal 13px/15px Roboto">{{ data.message }}</mat-dialog-content>
    <div class="card-footer">
      <button id="button" (click)="onYesClick()">Confirm</button>
      <button id="button" (click)="onNoClick()">Cancel</button>
    </div>  
  </div>