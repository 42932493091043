import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { AuthGuard } from "../../guard/auth.guard";
import { PeopleFrameComponent } from "../../shared/components/people-frame/people-frame.component";
import { SimpleHeaderComponent } from "../../shared/components/simple-header/simple-header.component";
import { MaterialModule } from "../../shared/modules/material/material.module";
import { NotFoundComponent } from "../not-found/not-found.component";
import { OrgchartModule } from "../orgchart/orgchart.module";
import { ChartFullViewModule } from "../chart-full-view/chart-full-view.module";
import { IncrementDistributionFileUploaderComponent } from "../performance-management/hr/performance-reward/increment-distribution-file-uploader/increment-distribution-file-uploader.component";
import { IncrementBudgetFileUploaderComponent } from "../performance-management/hr/setup/increment-budget-file-uploader/increment-budget-file-uploader.component";
import { ApprasialHistoryFileUploaderComponent } from "../performance-management/hr/appraisal/apprasial-history-file-uploader/apprasial-history-file-uploader.component";
import { ApprasialSettingFileUploaderComponent } from "../performance-management/hr/appraisal/apprasial-setting-file-uploader/apprasial-setting-file-uploader.component";

const routerOptions: ExtraOptions = {
  // scrollPositionRestoration: 'enabled',
  anchorScrolling: "enabled",
  scrollOffset: [0, 64],
  onSameUrlNavigation: "reload",
  useHash: false,
  // relativeLinkResolution: 'legacy'
};
const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "",
    component: PeopleFrameComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: "roster",
        loadChildren: () =>
          import("../roster/roster.module").then((m) => m.RosterModule),
      },

      {
        path: "ats",
        loadChildren: () =>
          import("../ats/ats.module").then((m) => m.ATSModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("../dashboards/portals-home/portals-home.module").then(
            (m) => m.PortalsHomeModule
          ),
      },
      {
        path: "employee-documents",
        loadChildren: () =>
          import("../employee-documents/employee-documents.module").then(
            (m) => m.EmployeeDocumentsModule
          ),
      },
      {
        path: "nms",
        loadChildren: () =>
          import("../nms/nms.module").then((m) => m.NMSModule),
      },

      {
        path: "emp_employee_attendance",
        loadChildren: () =>
          import("../employee-attendance/employee-attendance.module").then(
            (m) => m.EmployeeAttendanceModule
          ),
      },
      {
        path: "not-found",
        component: NotFoundComponent,
      },
      {
        path: "hr_employee_attendance",
        loadChildren: () =>
          import("../employee-attendance/employee-attendance.module").then(
            (m) => m.EmployeeAttendanceModule
          ),
      },
      {
        path: "lm_employee_attendance",
        loadChildren: () =>
          import("../employee-attendance/employee-attendance.module").then(
            (m) => m.EmployeeAttendanceModule
          ),
      },

      {
        path: "orgchart",
        loadChildren: () => OrgchartModule,
      },
      {
        path: "",
        loadChildren: () =>
          import("../policy-setup-list/policy-setup-list.module").then(
            (m) => m.PolicySetupListModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("../policy-setup-form/policy-setup-form.module").then(
            (m) => m.PolicySetupFormModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("../leave/leave.module").then((m) => m.LeaveModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("../expense/expense.module").then((m) => m.ExpenseModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("../self-onboarding/self-onboarding.module").then(
            (m) => m.SelfOnboardingModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("../example/example.module").then((m) => m.ExampleModule),
      },
      {
        path: "policy-manual",
        loadChildren: () =>
          import("../policy-manual/policy-manual.module").then(
            (m) => m.PolicyManualModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("../payroll/payroll.module").then((m) => m.PayrollModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("../payroll-processing/payroll-processing.module").then(
            (m) => m.PayrollProcessingModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("../payment-disbursement/payment-disbursement.module").then(
            (m) => m.paymentDisbursementModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import(
            "../letters-and-certificates/letters-and-certificates.module"
          ).then((m) => m.LettersAndCertificatesModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("../bank-disbursement/bank-disbursement.module").then(
            (m) => m.BankDisbursementModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("../exit-management/exitmanagement.module").then(
            (m) => m.ExitManagementModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("../performance-reward/performance.module").then(
            (m) => m.PerformanceModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("../payroll-invoicing/payroll-invoicing.module").then(
            (m) => m.PayrollInvoicingModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("../access-control/access-control.module").then(
            (m) => m.AccessControlModule
          ),
      },
      {
        path: "employee_histroy",
        loadChildren: () =>
          import("../employee-histroy/employee-histroy.module").then(
            (m) => m.EmployeeHistroyModule
          ),
      },
      {
        path: "org-emp",
        loadChildren: () =>
          import("../org-emp/org-emp.module").then((m) => m.OrgEmpModule),
      },
      {
        path: "training",
        loadChildren: () =>
          import("../training/training.module").then((m) => m.TrainingModule),
      },
      {
        path: "comp-ben",
        loadChildren: () =>
          import("../comp-ben/comp-ben.module").then((m) => m.CompBenModule),
      },
      {
        path: "bl-analytics",
        loadChildren: () =>
          import("../bl-analytics/bl-analytics.module").then(
            (m) => m.BlAnalyticsModule
          ),
      },
      {
        path: "bl-file-utils",
        loadChildren: () =>
          import(
            "../bl-analytics/bl-file-uploader/bl-file-uploader.module"
          ).then((m) => m.BlFileUploaderModule),
      },
      // {
      //     path: "leave-balance-utils",
      //     loadChildren: () =>
      //       import(
      //         "../leave/leave-balance-file-uploader/leave-balance-file-uploader.module"
      //       ).then((m) => m.LeaveBalanceFileUploaderModule),
      //   },
      // {
      //     path: "expense-utils",
      //     loadChildren: () =>
      //       import(
      //         "../expense/file-uploader/file-uploader.module"
      //       ).then((m) => m.FileUploaderModule),
      //   },

      {
        path: "increment-distribution-utils",
        component: IncrementDistributionFileUploaderComponent,
      },
      {
        path: "increment-budget-utils",
        component: IncrementBudgetFileUploaderComponent,
      },
      {
        path: "apprasial-setting-utils",
        component: ApprasialSettingFileUploaderComponent,
      },
      {
        path: "apprasial-history-utils",
        component: ApprasialHistoryFileUploaderComponent,
      },

      {
        path: "hr-services",
        loadChildren: () =>
          import("../hr-services/hr-services.module").then(
            (m) => m.HrServicesModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("../travel/travel.module").then((m) => m.TravelModule),
      },
      {
        path: "attendance",
        loadChildren: () =>
          import("../correction/attendance/attendance.module").then(
            (m) => m.AttendanceModule
          ),
      },
      {
        path: "",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("../bank-disbursement/bank-disbursement.module").then(
            (m) => m.BankDisbursementModule
          ),
      },
      {
        path: "pm",
        loadChildren: () =>
          import(
            "../../modules/performance-management/performance-management.module"
          ).then((m) => m.PerformanceManagementModule),
      },
      {
        path: "pm/hr",
        loadChildren: () =>
          import("../../modules/performance-management/hr/hr.module").then(
            (m) => m.HRModule
          ),
      },
      {
        path: "pm/lm",
        loadChildren: () =>
          import("../../modules/performance-management/hr/hr.module").then(
            (m) => m.HRModule
          ),
      },
      {
        path: "pm/ceo",
        loadChildren: () =>
          import("../../modules/performance-management/hr/hr.module").then(
            (m) => m.HRModule
          ),
      },
      {
        path: "pm/techconfig",
        loadChildren: () =>
          import(
            "../../modules/performance-management/tech-config/tech-config.module"
          ).then((m) => m.TechConfigModule),
      },
      {
        path: "pm/emp",
        loadChildren: () =>
          import(
            "../../modules/performance-management/employee/employee.module"
          ).then((m) => m.EmployeeModule),
      },
    ],
  },
  {
    path: "fullvieworgchart",
    canActivate: [AuthGuard],
    loadChildren: () => ChartFullViewModule,
  },

  {
    path: "login",
    loadChildren: () =>
      import("../login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "forget-password",
    loadChildren: () =>
      import("../forget-password/forget-password.module").then(
        (m) => m.ForgetPasswordModule
      ),
  },
  {
    path: "",
    canActivate: [AuthGuard],
    children: [
      {
        path: "portals",
        loadChildren: () =>
          import("../dashboards/portals/portals.module").then(
            (m) => m.PortalsModule
          ),
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, routerOptions),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    MaterialModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const routingComponents = [];
