import { Component, OnInit,Input,TemplateRef, Output, EventEmitter } from '@angular/core';
import { ConfirmDialogService } from 'src/app/shared/modules/confirm-dialog/confirm-dialog.service';



@Component({
  selector: 'app-slab-action',
  templateUrl: './slab-action.component.html',
  styleUrls: ['./slab-action.component.scss']
})
export class SlabActionComponet implements OnInit {
  @Input() text: string;
  @Input() isDisable: boolean;
  @Input() actionText: any;
  @Output() action: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  onClick(name: string): void {
      this.action.emit(name);
  }

}
