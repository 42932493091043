import { Pipe, PipeTransform } from '@angular/core';
/**
 * @author Usman Ali <usman.ali@people.com.pk>
 *  Truncate Text
 *  Send count for truncate
 *  --- truncateText: '30' ---
 */
@Pipe({
    name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {

    transform(value: string, count = 25, ...args: any[]): string {
      if (value) {
        const limit = args?.length > 0 ? parseInt(args[0], 10) : count;
        const trail = args?.length > 1 ? args[1] : '...';
        return value.length > limit ? value.substring(0, limit) + trail : value;
      }
    }

}
