<div class="module-style">
  <div class="row w-100 m-0">
    <div style="width: 40vw; height: 25vw">
      <app-notifications-panel
        [hideBackBtn]="true"
        [showViewAll]="true"
        [openNotificationDialog]="true"
        [sliceData]="true"
        [ellipsisText]="true"
      ></app-notifications-panel>
    </div>
  </div>
</div>
