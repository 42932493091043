import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { HeadcountSummaryService } from '../../../services/headcount-summary.service';
import { FilterDataEmitterService } from '../../../services/filter-data-emitter.service';

declare var $: any;

@Component({
  selector: 'app-orgchart-detail',
  templateUrl: './orgchart-detail.component.html',
  styleUrls: ['./orgchart-detail.component.css'],
  providers: [HeadcountSummaryService],
  encapsulation: ViewEncapsulation.None
})
export class OrgchartDetailComponent implements OnInit {

  @Input('orgChartView') orgChartView: string;

  @ViewChild('summaryData', {static: false}) summaryData: ElementRef;

  counterData:number = 0;

  message: string;

  constructor( private headcountSummaryService: HeadcountSummaryService,
               private filterDataEmitter: FilterDataEmitterService ) { }

  ngOnInit() {

    this.filterDataEmitter.currentMessage.subscribe(message => this.message = message);

  }

  ngDoCheck() {
   
    if(this.orgChartView == "2") {

      if(this.counterData == 0) {

        this.headcountSummary();
        $(".summary_detail").show();

      }

      this.counterData++;

    }

    if(this.message === "true") {

      this.headcountSummary();

      $(".summary_detail").show();
      this.message = "false";

    }

    if(this.orgChartView != "2") {

      this.counterData = 0;
      $(".summary_detail").hide();

    }

    if($('.chart_holder').is(":visible") == false) {

      this.counterData = 0;
      $(".summary_detail").hide();

    }

  }

  headcountSummary(): void {

    var filterCountry     = (typeof sessionStorage.getItem('countrySelectedValue') !== 'undefined' &&
                              sessionStorage.getItem('countrySelectedValue') !== null) ?
                              JSON.parse( sessionStorage.getItem('countrySelectedValue') ) :
                              $("#country").val();

    var filterCity        = (typeof sessionStorage.getItem('citySelectedValue') !== 'undefined' &&
                              sessionStorage.getItem('citySelectedValue') !== null) ?
                              JSON.parse( sessionStorage.getItem('citySelectedValue') ) :
                              $("#city").val();

    var filterBranch      = (typeof sessionStorage.getItem('branchSelectedValue') !== 'undefined' &&
                              sessionStorage.getItem('branchSelectedValue') !== null) ?
                              JSON.parse( sessionStorage.getItem('branchSelectedValue') ) :
                              $("#branch").val();

    var filterJobGroup    = (typeof sessionStorage.getItem('jobGroupSelectedValue') !== 'undefined' &&
                              sessionStorage.getItem('jobGroupSelectedValue') !== null) ?
                              JSON.parse( sessionStorage.getItem('jobGroupSelectedValue') ) :
                              $("#job_group").val();

    var filterJobFamily   = (typeof sessionStorage.getItem('jobFamilySelectedValue') !== 'undefined' &&
                              sessionStorage.getItem('jobFamilySelectedValue') !== null) ?
                              JSON.parse( sessionStorage.getItem('jobFamilySelectedValue') ) :
                              $("#job_family").val();

    var filterDepartment  = (typeof sessionStorage.getItem('departmentSelectedValue') !== 'undefined' &&
                              sessionStorage.getItem('departmentSelectedValue') !== null) ?
                              JSON.parse( sessionStorage.getItem('departmentSelectedValue') ) :
                              $("#department").val();

    var filterDesignation = (typeof sessionStorage.getItem('designationSelectedValue') !== 'undefined' &&
                              sessionStorage.getItem('designationSelectedValue') !== null) ?
                              JSON.parse( sessionStorage.getItem('designationSelectedValue') ) :
                              $("#designation").val();

    var filterBand        = (typeof sessionStorage.getItem('bandSelectedValue') !== 'undefined' &&
                            sessionStorage.getItem('bandSelectedValue') !== null) ?
                            JSON.parse( sessionStorage.getItem('bandSelectedValue') ) :
                            $("#band").val();

    var filterData        =  "&branch="+filterBranch+"&country="+filterCountry+"&city="+filterCity
                              +"&group="+filterJobGroup+"&family="+filterJobFamily
                              +"&department="+filterDepartment
                              +"&designation="+filterDesignation+"&band="+filterBand;

    this.headcountSummaryService.getHeadcountSummaryData( filterData )
                                .subscribe( data => {

                                    this.summaryData.nativeElement.innerHTML = this.summaryProcess(data);

                                });

  }

  summaryProcess(res): String {

    var html = "";

    if( typeof(res) !== 'undefined' && res.length > 0 ) {

      html = `<div>
      <h6 class="people-title-heading">Headcount View</h6>
            <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Designation Name</th>
                        <th>Total Employees</th>
                      </tr>
                    </thead>
                    <tbody>`;

        var serail = 1;

        for(var i = 0; i < res.length; i++) {

          html += `<tr>
                      <td>`+serail+`</td>
                      <td>`+res[i].designation_name+`</td>
                      <td>`+res[i].total_employee+`</td>
                    </tr>`;

          serail++;

        }

      html += `</tbody>
                </table>
              </div>`;

    }
    else {

      html = "<p>No Record Found</p>";

    }

    return html;

  }

}
