<div class="module-style physical-location-setup">
  <div class="m-0 justify-bw people-title-heading px-cus-10">
    <h4 class="px-cus-0">Disabled Locations</h4>
    <div class="col-1 justify-end px-cus-0 close" (click)="dialogRef.close()">
      <img src="assets/images/close.svg" />
    </div>
  </div>
  <div class="p-cus-20">
    <form>
      <div class="row m-0 border-0 modal-popup emp-content p-cus-0">
        <div class="col px-cus-0 modal-field mr-cus-6">
          <input
            #input
            value="{{branchName}}"
            type="text"
            placeholder=" "
            class="emp-input input-float"
            readonly
          />
          <p class="input-label">Branch</p>
        </div>
      </div>
    </form>
    <div>
      <div><h4 class="p-cus-20-0-10-0"></h4></div>
      <div class="add-address">
        <div
          *ngFor="let item of locationData"
          class="justify-bw p-6-5-10 {{(item.disabled == 1) ? 'location-del' : ''}}"
          (click)="changeStatus(item)"
        >
          <!-- <div

            class="justify-bw p-6-5-10 location-del"

          > -->

          <div class="{{item.disabled == 1 ? 'disabled' : ''}}">
            {{item.fieldValue}}: {{item.value}}
          </div>
          <!-- <div class="disabled">Flap Disabled</div> -->
        </div>
      </div>
    </div>
  </div>

  <div
    class="justify-right btn-modal-wrapper btn-wrapper"
    *ngIf="!showConfirmationButtons"
  >
    <button type="button" id="button" (click)="showValues()">Save</button>
    <button type="button" (click)="dialogRef.close()" id="button">
      Cancel
    </button>
  </div>

  <div
    class="btn-modal-wrapper btn-wrapper confirmation-action"
    *ngIf="showConfirmationButtons"
  >
    <span>Are you sure you want to enable?</span>
    <div class="action-buttons">
      <button type="button" id="button" (click)="submit()">Yes</button>
      <button type="button" id="button" (click)="cancel()">No</button>
    </div>
  </div>
</div>
