<div class="module-style notification-reciever-popup">
  <div class="row w-100 m-0 bg-white">
    <div class="col-md-12 p-0 mb-2 row m-0">
      <div class="justify-bw people-title-heading mt-0 col-md-12 py-2 px-0">
        <div
          class="row emp-content noti-reciever noti-header justify-bw col-md-12 m-0"
        >
          <div class="fields-content col-2 px-1">Receivers List</div>
          <div class="fields-content col-4 row just-end m-0 px-1 justify-around">
            <div class="p-0" style=" font-size: 11px;font-weight: 500;">Target Receivers Count</div>
            <div class="col-1 p-0 text icon-wrapper">
              <i class="bi bi-people-fill"></i>
            </div>
            <div class="col-2 text" style="padding-left: 6px;font-size: 12px;">{{ userCount }}</div>
            <div class="col-1 text-right p-0 " (click)="closeButton()"><img src="assets/images/close.svg" /></div>
          </div>
        </div>
      </div>
      <div class="doc-content col-12" style="padding: 10px 10px 0px 10px;">
        <div class="m-0">
          <div
            class="people-sub-title-heading emp-content noti-reciever row justify-bw m-0 p-0"
          >
            <div class="col-2 p-0">
              <h4 style="color:#343434;">Target Receivers</h4>
            </div>
            <!-- <div *ngIf="isCheckAll" class="fields-content col px-1 select-all">
              <span *ngIf="isSelectAll">
                All {{ userCountAll }} recievers are selected.
                <span class="select-recievers" (click)="clearSelection()"
                  >Clear selection</span
                >
              </span>
              <span *ngIf="!isSelectAll">
                All {{ userCount }} recievers on this page are selected.
                <span class="select-recievers" (click)="selectAll()"
                  >Select all {{ userCountAll }} recievers</span
                >
              </span>
            </div> -->
            <div class="col custom-input-wrapper">
              <input
                class="emp-input custom-input"
                type="text"
                placeholder="Search"
                [(ngModel)]="searchTerm"
                (keyup)="search(searchTerm)"
              />
            </div>
          </div>
          <div
            class="scroll-results table-scroll w-100 col-md-12 doc-content comp-card p-0"
            infiniteScroll

            [infiniteScrollDistance]="3"
            [infiniteScrollThrottle]="50"
            [alwaysCallback] = "true"
            [scrollWindow]="false"
            (scrolled)="getDataOnScroll()"
          >
            <table class="doc-records">
              <tr>
                <th>
                  <div class="emp-content noti-reciever p-0">
                    <div class="check pl-0">
                      <!-- <mat-checkbox
                        class="cus-pad-top-2 p-0"
                        #checkAll
                        (change)="toggleCheck(checkAll.checked)"
                        color="primary"
                        [indeterminate]="indeterminate"
                        [checked]="check"
                      >
                      </mat-checkbox> -->
                    </div>
                  </div>
                </th>
                <th>Name</th>
                <th>Code</th>
                <th>Designation</th>
                <th>Department</th>
                <th>Branch</th>
                <th>Company</th>
              </tr>
              <tr *ngFor="let employee of employeesList; let i = index">
                <td>
                  <div class="emp-content noti-reciever p-0">
                    <div class="check pl-0">
                      <mat-checkbox
                        class="cus-pad-top-2 p-0"
                        #empCheck
                        (change)="[ selectionChanged($event, empCheck.checked, i)]"
                        color="primary"
                        [checked]="employee.checked"
                      >
                      </mat-checkbox>
                    </div>
                  </div>
                </td>
                <td>{{ employee.name }}</td>
                <td>{{ employee.code }}</td>
                <td>{{ employee.designation }}</td>
                <td>{{ employee.department }}</td>
                <td>{{ employee.branch }}</td>
                <td class="text-truncate">{{ employee.company }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer justify-content-between">
    <span>*Unchecked will exclude relavent person from reveiving notification.</span>
    <div>
      <button [disabled]="userCount == 0" [ngClass]="{'disabled' : userCount == 0}"  (click)="submit()" id="button">Submit</button>
      <button (click)="closeButton()" id="button">Cancel</button>
    </div>
  </div>
</div>
