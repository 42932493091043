import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { BulkFileUploaderComponent } from 'src/app/shared/modules/bulk-file-uploader/bulk-file-uploader.component';

@Component({
  selector: 'app-apprasial-setting-file-uploader',
  templateUrl: './apprasial-setting-file-uploader.component.html',
  styleUrls: ['./apprasial-setting-file-uploader.component.css']
})
export class ApprasialSettingFileUploaderComponent implements OnInit {
  @ViewChild(BulkFileUploaderComponent) BulkFileUploaderComponent: BulkFileUploaderComponent;
  templateLink = ''
  constructor(    private httpService: HttpService,
    private loader: LoaderService,) { }

  ngOnInit(): void {
  }

  uploadFile(event) {
    this.onSubmit(event)
  }

  onSubmit(event) {
    this.loader.isLoading.next(true);
    let emp_Code = localStorage.getItem("e_code")
    const formData = new FormData();
    formData.append('import_file',  event,  event.name);
    formData.append('change_by',  emp_Code);

    this.httpService
      .post(
        "performance_management/settings/appraisal/appraisal_setting_utility",formData
      )
      .subscribe((res) => {
        if (res.status == "pass") {
          this.loader.isLoading.next(false);
          // this.errors = res.errors;
          // console.log(this.errors)
          this.BulkFileUploaderComponent.setData(res);
        } else {
          this.BulkFileUploaderComponent.setData(res);
          this.loader.isLoading.next(false);
        }
      }, error => {
        // this.BulkFileUploaderComponent.setData(error);
        this.loader.isLoading.next(false);
      }
      );
  }
}
