<div class="module-style notification-popup">
  <div class="m-0 justify-bw people-title-heading py-1 px-2">
    <h4 class="p-0">Updates & Notifications</h4>
    <div (click)="close()" class=""><img src="assets/images/close.svg" /></div>
  </div>
  <div class="px-3 bg-fff">
    <div class="popup-card row col m-0 px-0 py-2">
      <div class="avatar mr-2">
        <!-- <img src="assets/images/logo.PNG" alt="" /> -->
        <img
          class="user-profile-avatar mt-0"
          [src]="notification?.micro_picture"
          (error)="verifyImage($event, notification)"
          alt="..."
        />
      </div>
      <div class="notification-desc">
        <div class="row m-0">
          <div class="badge-color mr-1">
            <div
              class="badge m-auto user-{{ notification?.role }}"
              [style.background]="colors[notification['role']]"
            >
              {{
                notification["role"] == "System" ? "SYS" : notification["role"]
              }}
            </div>
          </div>
          <div
            class="name mr-1"
            [style.color]="colors[notification['role']]"
            *ngIf="notification['role'] != 'System'"
          >
            {{ notification["name"] }}
          </div>

          <div
            class="name mr-1"
            [style.color]="colors[notification['role']]"
            *ngIf="notification['role'] == 'System'"
          >
            {{ notification["notification_subject"] }}
          </div>
          <div class="date">
            {{ parseData(notification["completion_date"]) }}
          </div>
        </div>
        <ng-container *ngIf="notification && notification?.link">
          <a
            (click)="redirect(notification)"
            [href]=""
            [aria-label]="'Open Application'"
            [matTooltip]="'Open Application'"
            class="notification-link"
          >
            <i class="material-icons" style="font-size: 16px; font-weight: bold"
              >arrow_forward_ios</i
            >
          </a>
        </ng-container>
      </div>
    </div>
    <div class="popup-body py-2 justify-bw">
      <span>{{ notification["notification_body"] }}</span>
    </div>
  </div>
</div>
