import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { LoaderService } from "./loader.service";

declare var $: any;

@Injectable({
  providedIn: "root",
})
export class OrgchartService {
  private _url: string = environment.nodeApiUrl;
  private popupViewer = false;
  private matrix = null;
  private oldId = null;
  // private orgChartView = "1";
  counterChecker = 0;
  // orgChartImage
  orgChartView = new BehaviorSubject('3')
  orgChartImage = new BehaviorSubject('1')
  orgChartTheme = new BehaviorSubject('1')
  orgFilterData = new BehaviorSubject('')

  selectedMapThemeNumber = new BehaviorSubject(1)

  selectedMapTheme = new BehaviorSubject({})
  activeMapView = new BehaviorSubject(true)
  orgMapComponent: any
  isHRorCRorCEO: boolean;
  user_id: string;

  constructor(
    private loader: LoaderService,
    private http: HttpClient,
  ) {
    sessionStorage.setItem('session_view', '3')
    sessionStorage.setItem('session_number', '1')
    sessionStorage.setItem('session_color', '1')
    // this.showDataModal = this.showDataModal.bind(this);
  }
  isHRorCR(): boolean {
    let portal = localStorage.getItem("link");
    this.user_id = localStorage.getItem("user_id")
    if (portal == 'hrportal' || portal == 'crportal' || portal == 'ceoportal' || portal == 'lmportal') {
      return true
    } else {
      return false
    }
  }
  // populateOrgChart(option = "1", number = "1", color_change = "1", filter_data:any = "") {
  populateOrgChart() {
    this.isHRorCRorCEO = this.isHRorCR();
    this.loader.isLoading.next(true)
    let option = this.orgChartView.value
    let number = this.orgChartTheme.value
    let color_change = this.orgChartImage.value
    let filter_data = this.orgFilterData.value

    sessionStorage.setItem('session_view', option)
    sessionStorage.setItem('session_number', number)
    sessionStorage.setItem('session_color', color_change)

    this.popupViewer = false;

    $("#close_drawer").val("1");

    let current_target_url =
      environment.nodeApiUrl + "Orgchart/get_approval_matrix_data";

    $(".chart_holder").show();
    // this.loader.isLoading.next(true)

    if (number == "1") {
      $("#dynamic_css_handler").html(
        '<link id="remove_classic" rel="stylesheet" type="text/css" href="assets/orgchart/css/theme_classic.css">'
      );
      $("#remove_advance1").remove();
      $("#remove_advance2").remove();
    } else if (number == "2") {
      $("#dynamic_css_handler").html(
        '<link id="remove_advance1" rel="stylesheet" type="text/css" href="assets/orgchart/css/theme_advance.css">'
      );
      $("#remove_classic").remove();
      $("#remove_advance2").remove();
    } else if (number == "3") {
      $("#dynamic_css_handler").html(
        '<link id="remove_advance2" rel="stylesheet" type="text/css" href="assets/orgchart/css/theme_advance_2.css">'
      );
      $("#remove_classic").remove();
      $("#remove_advance1").remove();
    }

    if (option == "1" || option == "2") {
      $("#dynamic_css_handler").append(
        '<link rel="stylesheet" type="text/css" href="assets/orgchart/css/hide_first_node.css">'
      );
    }

    if (option == "3") {
      $("#dynamic_css_handler").append(
        '<link rel="stylesheet" id="remove_css" type="text/css" href="assets/orgchart/css/department_expand_view.css">'
      );
    }

    if (option == "4") {
      $("#dynamic_css_handler").append(
        '<link rel="stylesheet" type="text/css" href="assets/orgchart/css/department_only_expand_view.css">'
      );
      $("#remove_css").remove();
    }

    var parentNodeId = null;

    this.centerMoveChart("init", true);

    $("#chart-container").empty();

    var filterDetails = "";
    let branchData = sessionStorage.getItem('branchData');
    if (filter_data == "" && branchData) {
      filterDetails = "&filter=1&" + filter_data + branchData
    }
    else if (filter_data == "" && !branchData) {
      filterDetails = "&filter=0&" + filter_data
    }
    else if (filter_data != 'department=') {
      filterDetails = "&filter=1&" + filter_data;
    }
    else {
      filterDetails = "&filter=1&" + branchData;
    }

    const that = this;

    const nodeTemplate = function (data) {
      var numberNo = "1";

      var departmentViewPopupShower = null;
      if (this.orgChartView.value == 3 || this.orgChartView.value == 4) {
        numberNo = number;
        departmentViewPopupShower = true;
      } else {
        numberNo = number;
        departmentViewPopupShower = false;
      }

      return that.getNodeTemplate(data, numberNo, departmentViewPopupShower);
    }.bind(this);

    var oc = $("#chart-container").orgchart({
      data: current_target_url + "?action=1&op=" + option + filterDetails,
      nodeContent: "title",
      zoomDuration: "0.99s",
      draggable: false,
      pan: true,
      zoom: true,
      toggleSiblingsResp: false,
      hideLeftChild: true,
      hideRightChild: true,
      hideTopChild: true,
      zoominLimit: 9,
      zoomoutLimit: 0.1,
      visibleLevel: 9999,
      // 'verticalLevel': 3,
      nodeTemplate: nodeTemplate,
      dropCriteria: function ($draggedNode, $dragZone, $dropZone) {
        return true;
      },
      createNode: function ($node, data) {
        if (option != "3") {
          let randomId = that.getId() + "_" + data.id;
          $node[0].id = randomId;
          $($node[0])
            .find("a.node_options")
            .attr("id", "act_" + randomId);
          $($node[0])
            .find(".menu")
            .attr("id", "men_" + randomId);

          if (null !== parentNodeId) {
            setTimeout(function () {
              $("#" + parentNodeId)
                .parents(".nodes")
                .parents("table")
                .eq(0)
                .children("tr")
                .eq(0)
                .find(".fa-plus-circle")
                .addClass("fa-minus-circle")
                .removeClass("fa-plus-circle");
            }, 50);
          }

          parentNodeId = randomId;
        }
      },
      initCompleted: function () {
        setTimeout(function () {
          if ("1" == color_change || null == color_change) {
            $(".orgchart")
              .find(".colored_images")
              .addClass("black_image")
              .removeClass("colored_images");
          } else {
            $(".orgchart")
              .find(".black_image")
              .addClass("colored_images")
              .removeClass("black_image");
          }
        }, 2500);
      },
    });

    // node functions customizations popups

    oc.$chart.on("nodedrop.orgchart", function (event, extraParams) {

    });

    oc.$chart.on("chartcompleted.orgchart", function (event) {
      that.centerMoveChart("init", true);
    });

    oc.$chart.on("hidechildnode.orgchart", function (event) {
      var transformedVal = $(".orgchart").css("transform");

      that.matrix = transformedVal;

      that.centerMoveChart("init", false);
    });

    oc.$chart.on("showchildnode.orgchart", function (event) {
      var transformedVal = $(".orgchart").css("transform");

      that.matrix = transformedVal;

      that.centerMoveChart("init", false);
    });

    oc.$chart.on("panEndHandler", function (e) {
      var transformedVal = $(".orgchart").css("transform");

      this.matrix = transformedVal;
    });

    $(document).on("click", ".menu-0-0", function (e) {
      if (!e) e = window.event;

      var $node = $(e.currentTarget).parents("div.node").eq(0);

      var action = $(e.target).data("action");

      e.preventDefault();

      // var counterChecker = 0;
      // var orgChartView = new BehaviorSubject('3'); 
      if (action === "add") {
        var currentNodeId = this.getId();

        var nodeData = [
          {
            name: "",
            title: "vacant position",
            desc: "",
            image: '<span style="background-image: url(assets/images/dummy-user.svg)"></span>',
            id: currentNodeId,
          },
        ];

        var hasChild = $node.parent().attr("colspan") > 0 ? true : false;

        if (!hasChild) {
          oc.addChildren($node, nodeData);
        } else {
          oc.addSiblings(
            $node.closest("tr").siblings(".nodes").find(".node:first"),
            nodeData
          );
        }
      } else if (action === "delete") {
        if (
          !window.confirm("Are you sure you want to delete the whole chart?")
        ) {
          return;
        }
        oc.removeNodes($node);
        $("#selected-node").val("").data("node", null);
      }

      else if (action === "details") {
        if (parseInt(that.orgChartView?.value) === 0 || that.counterChecker % parseInt(that.orgChartView?.value) === 0) {
          var id = "#" + $($node).attr("id");
          var get_modal_data = that.showDataModal(id);
        }
      }
      // else if (action === "details") {
      //   if (this.orgChartView?.value === 0) {
      //     var id = "#" + $($node).attr("id");
      //   var get_modal_data = this.showDataModal(id);
      //   } else if (this.counterChecker % this.orgChartView?.value === 0) {
      //     var id = "#" + $($node).attr("id");
      //      var get_modal_data = this.showDataModal(id);
      //   }

      // } 
      else if (action === "op_popup") {
        var id = "#" + $($node).attr("id");

        that.show_reporting_popup(id);
      }

      $(".orgchart").find(".menu-show").removeClass("menu-show");

      this.oldId = null;

      this.counter = 0;
      this.counterChecker++;
    });

    // this.orgChartView.next(typeof sessionStorage.getItem("session_view") !== "undefined" && sessionStorage.getItem("session_view") !== null ? sessionStorage.getItem("session_view") : this.orgChartView.value)
    $(".loading1.spinner1").hide();
    setTimeout(() => {
      that.centerMoveChart('init', true)
      that.loader.isLoading.next(false)
    }, 2000);
  }

  activePopupDetails(node) {
    var $node = $(node).parents("div.node").eq(0);

    var action = $(node).data("action");

    var view: number = +this.orgChartView.value;

    if (action === "details") {
      if (view === 0) {
        var id = "#" + $($node).attr("id");

        var get_modal_data = this.show_reporting_popup(id);
      } else if (this.counterChecker % view == 0) {
        var id = "#" + $($node).attr("id");

        var get_modal_data = this.show_reporting_popup(id);
      }

      //$("#detail-modal").modal("show");
    }

    this.oldId = null;
    this.counterChecker++;
  }

  show_reporting_popup(id) {
    $("#reporting_chart").empty();

    var options = { percent: 100 };

    this.popupViewer = true;

    var splittedId = id.split("_");

    $("#reporting-modal").show();
    // $("#reporting-modal").modal("show");

    let current_target_url =
      environment.nodeApiUrl + "Orgchart/get_approval_matrix_data";

    const nodeTemplate = function (data) {
      var numberNo = 1;

      var departmentViewPopupShower = null;

      if (this.orgChartView.value == 3 || this.orgChartView.value == 4) {
        numberNo = this.orgChartView.value;
        departmentViewPopupShower = true;
      } else {
        numberNo = this.orgChartView.value;
        departmentViewPopupShower = false;
      }

      return this.getNodeTemplate(data, numberNo, departmentViewPopupShower);
    };

    var oc2 = $("#reporting_chart").orgchart({
      data: current_target_url + "?action=1&op=1&s=" + splittedId["1"],
      nodeContent: "title",
      zoomDuration: "0.45s",
      draggable: false,
      pan: true,
      zoom: true,
      toggleSiblingsResp: false,
      hideLeftChild: true,
      hideRightChild: true,
      hideTopChild: true,
      zoominLimit: 9,
      zoomoutLimit: 0.1,
      visibleLevel: 9999,
      //            'verticalLevel': 3,
      nodeTemplate: nodeTemplate,
      dropCriteria: function ($draggedNode, $dragZone, $dropZone) {
        return true;
      },
      createNode: function ($node, data) { },
      initCompleted: function () {
        setTimeout(function () {
          if ("1" == this.orgChartImage || null == this.orgChartImage) {
            $(".orgchart")
              .find(".colored_images")
              .addClass("black_image")
              .removeClass("colored_images");
          } else {
            $(".orgchart")
              .find(".black_image")
              .addClass("colored_images")
              .removeClass("black_image");
          }
        }, 2500);
      },
    });
  }

  getNodeTemplate(data, template_no, departmentView = null) {
    var template = null;
    let img = data.image == 'n' ? 'assets/images/dummy-user.svg' : data.image
    if (template_no == 1) {
      template = `<div class="node_container_1 menu-0-0" data-action="details">`;
      if (departmentView) {
        //template += `<span class="menu-0"><a href="#" data-action="op_popup" class="menu-0-0">View Reporting</a></span>`;
      }

      if (this.orgChartView.value === '1' || this.orgChartView.value === '3') {

        template += `<div class="node_header _style_1 _one_">
                          <p class="menu-0-0 " data-action="details">${data.name}</p>
                      </div>
                      <a href="#" data-action="details" class="node_content">
                          <div class="node_image_1" style="font-size:70px" data-action="details">
                          <span class="black_image" style="background-image: url(${img})"></span>
                          </div>
                          <div class="node_details" data-action="details">
                              <p data-action="details">${data.title}</p>
                              <p class="comp_name" data-action="details" title="${data.companyname}">${data.companyname}</p>
                              <p class="bands" data-action="details" title="${data.band}">${data.band}</p>
                          </div>
                      </a>
                  </div>`;

      }
      else {

        template += `<div class="node_header _style_1 _one_">
                          <p class="menu-0-0" data-action="details">${data.name}</p>
                      </div>
                      <a href="#" data-action="details" class="node_content">
                          <div class="node_image_1" style="font-size:70px" data-action="details">
                          ${img}
                          </div>
                          <div class="node_details" data-action="details">
                              <p data-action="details">${data.title}</p>
                              <p class="comp_name" data-action="details" title="${data.companyname}">${data.companyname}</p>
                              <p class="bands" data-action="details" title="${data.band}">${data.band}</p>
                          </div>
                      </a>
                  </div>`;

      }


    } else if (template_no == 2) {

      if (this.orgChartView.value === '1' || this.orgChartView.value === '3') {

        template = `<div class="node_container menu-0-0 _style_1 _two_" data-action="details">
                    <a href="#" data-action="details" style="text-decoration:none;color:initial;">
                    <div class="node_image" data-action="details">
                      <div data-action="details" class="border-div" style="font-size:70px;">
                      <span class="black_image" style="background-image: url(${img})"></span>
                      </div>
                    </div>
                    <div class="node_content" data-action="details">
                      <p data-action="details" class="node_title" title="${data.name}">${data.name}</p>
                      <p data-action="details" class="node_position" title="${data.title}">${data.title}</p>
                    </div>
                    </a>
                  </div>`;

      }
      else {

        template = `<div class="node_container menu-0-0 _style_1 _two_" data-action="details">
                    <a href="#" data-action="details" style="text-decoration:none;color:initial;">
                    <div class="node_image" data-action="details">
                      <div data-action="details" class="border-div" style="font-size:70px;">
                      ${img}

                      </div>
                    </div>
                    <div class="node_content" data-action="details">
                      <p data-action="details" class="node_title" title="${data.name}">${data.name}</p>
                      <p data-action="details" class="node_position" title="${data.title}">${data.title}</p>
                    </div>
                    </a>
                  </div>`;

      }

    }
    if (template_no == 3) {

      if (this.orgChartView.value === '1' || this.orgChartView.value === '3') {

        template = `<div class="node_container menu-0-0 _style_1 _three_" data-action="details">
                    <a href="#" data-action="details" style="text-decoration:none;color:initial;">
                    <div class="node_image" data-action="details">
                      <div data-action="details" class="border-div" style="font-size:70px;">
                      <span class="black_image" style="background-image: url(${img})"></span>

                      </div>
                    </div>
                    <div class="node_content" data-action="details">
                      <p data-action="details" class="node_title" title="${data.name}">${data.name}</p>
                      <p data-action="details" class="node_position" title="${data.title}">${data.title}</p>
                    </div>
                    </a>
                  </div>`;

      }
      else {

        template = `<div class="node_container menu-0-0 _style_1 _three_" data-action="details">
                    <a href="#" data-action="details" style="text-decoration:none;color:initial;">
                    <div class="node_image" data-action="details">
                      <div data-action="details" class="border-div" style="font-size:70px;">
                      ${img}
                      </div>
                    </div>
                    <div class="node_content" data-action="details">
                      <p data-action="details" class="node_title" title="${data.name}">${data.name}</p>
                      <p data-action="details" class="node_position" title="${data.title}">${data.title}</p>
                    </div>
                    </a>
                  </div>`;

      }


    }

    return template;
  }

  getId() {
    return new Date().getTime() * 1000 + Math.floor(Math.random() * 1001);
  }

  centerMoveChart(checkAction, move_scrolls = true) {
    let matrix = null;
    if ($(".chart_holder").is(":visible") === false) {
    } else {
      this.matrix = null;

      var outerContent = $("#chart-container");
      var innerContent = $("#chart-container > div.orgchart");

      var x_axis = (innerContent.width() - outerContent.width()) / 2;
      var y_axis = (innerContent.height() - outerContent.height()) / 2;

      if (this.matrix === null) {
        this.matrix = "matrix(0.2, 0, 0, 0.2, 0, 0)";
        matrix = "matrix(0.2, 0, 0, 0.2, 0, 0)";
      }

      setTimeout(function () {
        if (move_scrolls) {
          if (checkAction == "show") {
            outerContent.scrollLeft(x_axis);
            // outerContent.scrollTop( y_axis );
          } else if (checkAction == "hide") {
            outerContent.scrollRight(x_axis);
            // outerContent.scrollTop( y_axis );
          } else {
            outerContent.scrollLeft(x_axis);
            // outerContent.scrollTop( y_axis );
          }
        }

        $(".orgchart").css({
          transform: matrix,
          "-moz-transform": matrix,
          "-webkit-transform": matrix,
          "-o-transform": matrix,
          "transform-origin": "center top",
          cursor: "move -webkit-grab grab",
        });
      }, 100);
    }
  }


  getSingleEmployeeData(id) {
    // let params = new HttpParams().set("action", "2");
    // params = params.append("details", id);
    let body = {
      "action": 2,
      "details": id
    }
    return this.http.post(
      environment.nodeApiUrl + "Orgchart/get_single_employee_data", body);
  }

  showDataModal(id) {
    this.getSingleEmployeeData(id).subscribe(
      (res: any) => {
        let fullWidth = "";
        let desLink =
          typeof localStorage.getItem("link") !== "undefined" &&
            localStorage.getItem("link") !== null
            ? localStorage.getItem("link")
            : "";

        let routingLink = "/login.php" + "?"; "mt=aes&des=empinformationhr.php" + "&route=" + desLink;
        if (res.length > 1) {
          fullWidth = "width: 100% !important; max-width: 100% !important";
        }

        let html =
          `<div id="dialogModal" class="modal modalcc fade show" role="dialog" >
                <div class="modal-dialog" style="` +
          fullWidth +
          `" >

                  <!-- Modal content-->
                  <div class="modalcc-content modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" onclick="myFunction()">&times;</button>`;

        if (res.length > 1) {
          html += `<div class="modal-body" id="detail-modal-datacontainer">
                      <table class="table table-striped">
                      <thead>
                          <tr>
                            <td style="border-top: none; font-size: 12px; font-weight: 500; color: #3080ea;padding-left: 5px;">Sr No.</td>
                              <td style="border-top: none; font-size: 12px; font-weight: 500; color: #3080ea; padding-left: 0;">Picture</td>
                              <td style="border-top: none; font-size: 12px; font-weight: 500; color: #3080ea; padding-left: 0;">Name</td>
                              <td style="border-top: none; font-size: 12px; font-weight: 500; color: #3080ea; padding-left: 0;">Employee Code</td>
                              <td style="border-top: none; font-size: 12px; font-weight: 500; color: #3080ea; padding-left: 0;">Designation</td>
                              <td style="border-top: none; font-size: 12px; font-weight: 500; color: #3080ea; padding-left: 0;">Band</td>
                              <td style="border-top: none; font-size: 12px; font-weight: 500; color: #3080ea; padding-left: 0;">Department</td>
                              <td style="border-top: none; font-size: 12px; font-weight: 500; color: #3080ea; padding-left: 0;">Date of Joining</td>
                              <td style="border-top: none; font-size: 12px; font-weight: 500; color: #3080ea; padding-left: 0;">Gender</td>
                             ${this.isHRorCRorCEO ? `<td style="border-top: none; font-size: 12px; font-weight: 500; color: #3080ea; padding-left: 0;">Actions</td>` : ''}
                          </tr>
                      </thead>
                      <tbody>`;

          var counter = 1;

          for (var k in res) {
            let picture = "";

            if (
              res[k]["picture"] == "" ||
              res[k]["picture"] == null ||
              res[k]["picture"] == "undefined" ||
              res[k]["picture"]?.includes(null)
            ) {
              if (
                res[k]["picture"]?.toLowerCase() == "male" ||
                res[k]["picture"]?.toLowerCase() == "mr."
              ) {
                picture = "assets/images/dummy-user.svg";
              } else {
                picture = "assets/images/user.png";
              }
            } else {
              picture = res[k]["picture"];
            }
            let extraParams = { empid: window.btoa(res[k]["id"]) };
            let url_link = "";
            if (desLink == "hr") {
              url_link =
                `<a href="" onclick="return ajaxRouteBack(event)" data-extraParams="` +
                encodeURIComponent(JSON.stringify(extraParams)) +
                `"
                  class="card-link">View Profile</a>`;
            } else {
              url_link = `<p class="card-link">View Profile</p>`;
            }

            html +=
              `<tr>
                              <td>` +
              counter +
              `</td>
                              <td>
                                  <div class="card-img-top node_image_1" style="width: 100px; height: 100px;">
                                      <span class="black_image" style="background-image: url(` +
              picture +
              `)"></span>
                                  </div>
                              </td>
                              <td>` +
              res[k]["name"] +
              `</td>
                              <td>` +
              res[k]["empcode"] +
              `</td>
                              <td>` +
              res[k]["designation_name"] +
              `</td>
                              <td>` +
              res[k]["band"] +
              `</td>
                              <td>` +
              res[k]["department"] +
              `</td>
                              <td>` +
              res[k]["doj"] +
              `</td>
                              <td>` +
              res[k]["gender"] +
              `</td>
                              <td>` +
              url_link +
              `</td>
                          </tr>`;

            counter++;
          }

          html += `</div>`;
        } else {
          let data = res[0];

          let picture = "";
          let isValidPic = false
          if (
            (!data["picture"] && data["picture"] == "") ||
            data["picture"] == null ||
            data["picture"] == "undefined" ||
            data["picture"]?.includes(null)
          ) {
            if (
              data["gender"]?.toLowerCase() == "male" ||
              data["name_salute"]?.toLowerCase() == "mr."
            ) {
              picture = "assets/images/dummy-user.svg";
            } else {
              picture = "assets/images/dummy-user.svg";
            }
          } else {
            picture = data["picture"];
            isValidPic = true
          }
          let extraParams = { empid: window.btoa(data["id"]) };
          let url_link = "";
          if (desLink == "hr") {
            url_link =
              `<a href="" onclick="return ajaxRouteBack(event)" data-extraParams="` +
              encodeURIComponent(JSON.stringify(extraParams)) +
              `"
                  class="card-link">View Profile</a>`;
          } else {
            url_link = `<p class="card-link">View Profile</p>`;
          }

          html +=
            `<div class="modal-body" id="detail-modal-datacontainer">
                    <div class="card" class="col-xs-12">

                    <div class="card-img-top node_image_1">
                        <span class="black_image valid_image_${isValidPic} vacant_${data['vacant']}" style="background-image: url(${picture})"></span>
                    </div>
                        <div class="card-body">
                            <h6 class="card-title text-primary h4">
                                <span style="font-size:13px">Name : <strong style="font-size:13px">${data["name"]}</strong></span>
                                <span style="font-size:13px">Emp Code : <strong style="font-size:13px" class="empcode pull-right">${data["empcode"]}</strong></span>
                            </h6>
                        </div>

                        <ul class="list-group list-group-flush">

                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-sm-9">Designation: <strong> ` + data["designation_name"] + `</strong></div>
                            <div class="col-sm-3">Band: <strong>` + data["band"] + `</strong></div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-sm-12">
                              Job Group: <strong>` + data["job_grouop"] + `</strong>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-sm-12">
                              Job Family: <strong>` +
            data["job_families"] +
            `</strong>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-sm-8">Department: <strong>` +
            data["department"] +
            `</strong></div>
                            <div class="col-sm-4">Sub Depart: <strong>` +
            data["sub_department"] +
            `</strong></div>
                          </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row">
                              <div class="col-sm-8">Date of Joining: <strong>` +
            data["doj"] +
            `</strong></div>
                              <div class="col-sm-4">Gender: <strong>` +
            data["gender"] +
            `</strong></div>
                            </div>
                        </li>
                        </ul>
                        <div class="card-body">

                        </div>
                    </div>`
          html +=
            ` <div class="modal_footer">
                      ${this.isHRorCRorCEO || this.user_id == res[0].id ? `<a href="org-emp/employee-profile/profile/view-profile?employeeId=${data?.id}" id="button">View Profile</a>` : ''}
                    </div>
                  </div>`
        }

        html += `</div>
                  </div>
                    </div>`;

        $("#detail-modal").html(html);

        setTimeout(function () {
          // $("#dialogModal").modal();
          $("#dialogModal").show();
        }, 500);

        if ("1" == this.orgChartImage.value) {
          $(".card-img-top")
            .find(".colored_images")
            .addClass("black_image")
            .removeClass("colored_images");
        } else {
          $(".card-img-top")
            .find(".black_image")
            .addClass("colored_images")
            .removeClass("black_image");
        }
      },
      (error) => {
      }
    );
  }
}
