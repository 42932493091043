import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import moment from "moment";
import { GetNotificationsService } from "../../services/get-notifications.service";
import { HelpersService } from "../../util/helpers.service";
import { ObservableService } from "../../util/observablefn.service";
import { Location } from "@angular/common";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";
import { MessageService } from "src/app/services/message.service";
import * as config from "../../../../../../config.json";


@Component({
  selector: "app-notification-popup",
  templateUrl: "./notification-popup.component.html",
  styleUrls: ["./notification-popup.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationPopupComponent implements OnInit {
  notification :any= {};
  post = "";

  colors = {};

  constructor(
    public dialogRef: MatDialogRef<NotificationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private os: ObservableService,
    private gns: GetNotificationsService,
    private hs: HelpersService,
    private router: Router,
    public authService: AuthService, private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getSingleNotification(this.data.id);
    // this.getColors();
  }
  async getColors() {
    const colorsArray = (await this.os.asPromised(this.hs.getColors())).payload.colors;

    colorsArray.forEach((color) => {
      this.colors[`${color.color_key}`] = `${color.color_code}`;
    });
  }
  async getSingleNotification(id) {
    const response = await this.os.asPromised(this.gns.getSingleUserNotification(id));
    // for (const iterator of response.payload.notification) {
    if(response.payload.notification.role == 'System') response.payload.notification.role = 'SYS'
    if(response.payload.notification.role == 'Employee') response.payload.notification.role = 'EMP'
    // }
    this.notification = response.payload.notification;
  }

  parseData(date) {
    let time = moment(date).utc().format('DD MMM YYYY, hh:mm A');
    return time
  }

  close() {
    this.dialogRef.close();
  }
  verifyImage(e, imgUrl){
    if(e.type == 'error') {
      this.notification.micro_picture = 'assets/images/user.png'
    }
  }

  redirect(notification){
    notification.is_read == 0
    if(notification.is_angular == 2){
      document.location.href = config.fileDirUrl+notification.link;
    }
    else if(notification.is_angular == 1){
      this.router.navigate([notification.link])
    }
    else{
      const getLink = localStorage.getItem('link');
        const getENumber = localStorage.getItem('e_number');
        const destinationRequest = notification.link;
        const portal = (typeof getLink != 'undefined' && getLink != 'null') ? getLink : '';
        const employeeId = (typeof getENumber != 'undefined' && getENumber != 'null') ? getENumber : '';
        const sourceRequest = this.router.url.split('/').pop();
        this.authService.switchSeamlessportal(destinationRequest, portal, employeeId, sourceRequest).subscribe(data => {
          if (data.status == '200' && data.loginId != 'false') {
            let routingLink = environment.phpSiteUrl + "?u=" + data.loginId + "&mt=aes";
            window.open(routingLink, '_self');
          }
          else {
            alert('Something went wrong cannot proceed your request');
          }
        });
        this.messageService.sendMessage({ Component: "Header", For: "SeamlessForm", Data: { fileName: destinationRequest } });
    }
  }

}
