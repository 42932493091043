import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SidebarModule } from 'ng-sidebar';
import { DatePipe,DecimalPipe } from '@angular/common';

import { AppRoutingModule, routingComponents } from '../routing_module/app-routing.module';
import { AppComponent } from './app.component';

import { PeopleFrameComponent } from '../../shared/components/people-frame/people-frame.component';
import { HeaderComponent } from '../../shared/components/people-frame/header/header.component';
import { SimpleHeaderComponent } from '../../shared/components/simple-header/simple-header.component';
import { LoaderService } from '../../services/loader.service';
import { MaterialModule } from '../../shared/modules/material/material.module';
import { SharedModule } from '../../shared/modules/shared/shared.module';
import { FooterModule } from '../../shared/modules/footer/footer.module';
import { EmployeeAttendanceForm } from '../../shared/form/employee-attendance.form';
import { HttpLoaderModule } from 'src/app/shared/components/http-loader/http-loader.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from 'src/app/shared/modules/material/custom-mat-paginator-int';
import { JqueryLoaderModule } from 'src/app/shared/components/jquery-loader/jquery-loader.module';
import { TokenInterceptor } from 'src/app/services/token-interceptor.service';
import { AgGridModule } from 'ag-grid-angular';
import { GridModule } from '@progress/kendo-angular-grid';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ChartModule } from 'angular-highcharts';
import { HttpService } from 'src/app/shared/http-service/http.service';
import { CryptoService } from 'src/app/services/crypto.service';
import { ToastrModule } from 'ngx-toastr';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { SeamlessFormComponent } from '../../shared/components/people-frame/seamless-form/seamless-form.component';
import { MessageService } from 'src/app/services/message.service';
import { NotFoundModule } from '../../modules/not-found/not-found.module';
import { NotFoundRoutingModule } from '../../modules/not-found/not-found-routing.module';
import { EmployeeDocumentsModule } from '../employee-documents/employee-documents.module';
import { NMSModule } from '../nms/nms.module';

import { EmployeeDocumentsRoutingModule } from '../employee-documents/employee-documents-routing.module';
import { NMSRoutingModule } from '../nms/nms-routing.module';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import  { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component'
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    HeaderComponent,
    PeopleFrameComponent,
    SimpleHeaderComponent,
    SeamlessFormComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ChartModule,
    ReactiveFormsModule,
    SidebarModule.forRoot(),
    SharedModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: "toast-bottom-center"
    }),
    FooterModule,
    HttpLoaderModule,
    JqueryLoaderModule,
    GridModule,
    BsDropdownModule.forRoot(),
    AgGridModule.withComponents([]),
    FullCalendarModule,
    NotFoundRoutingModule,
    EmployeeDocumentsModule,
    NMSModule,
    NotFoundModule,
    EmployeeDocumentsRoutingModule,
    NMSRoutingModule,
    MatDialogModule,
    MatButtonModule,
  ],
  providers: [
    LoaderService,
    EmployeeAttendanceForm,
    HttpService,
    CryptoService,
    MessageService,
    DatePipe,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    ConfirmationDialogService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})

export class AppModule { }
