<div
  class="modal fade"
  [id]="modalName"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    [ngClass]="modalSize"
  >
    <div class="modal-content">
      <div class="modal-header">
        <ng-content select=".popup-header"></ng-content>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content select=".popup-body"></ng-content>
      </div>
      <div class="modal-footer">
        <ng-content select=".popup-footer"> </ng-content>
      </div>
    </div>
  </div>
</div>
