import {
    Component,
    Inject,
    OnInit,
    ViewChild,
    ViewEncapsulation,
  } from "@angular/core";
  import {

    FormControl,
    FormGroup,
    FormGroupDirective,
    Validators,
  } from "@angular/forms";
  import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";

  import { DropDownFieldService } from "../../services/dropdown-fields-service";
  import { PhysicalLocationSetupService } from "../../services/physical-location-setup/physical-location-setup.service";
  import { ToastService } from "../../services/toast.service";
  import { LoaderService } from "../../util/loader.service";
  import { ObservableService } from "../../util/observablefn.service";

  //   import { IResponse } from "../models/response.interface";

  @Component({
    selector: "physical-location",
    templateUrl: "disabledLocationModal.html",
    styleUrls: ["disabledLocationModal.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [FormGroupDirective],
  })


  export class DisabledPhysicalLocationModal {

    modalData : any;
    branchName : string = '';
    constructor(
        public dialogRef: MatDialogRef<DisabledPhysicalLocationModal>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private plSetup: PhysicalLocationSetupService,
    private os: ObservableService,
    private dropDownService: DropDownFieldService,
    // private toastService: ToastService,
    private toastService: ToastrService,
    ) {
     this.modalData = data;
    }
    locationData = [];
    ngOnInit() {

        this.branchName = this.modalData.branchName;
        console.log('modal Data',this.modalData);
        for (const property in this.modalData) {
            if(property == 'boxDisabled' && this.modalData[property]){
                this.locationData.push({
                    fieldValue : 'Box',
                    id : this.modalData.box_id ,
                    value : this.modalData.box_number ,
                    disabled : 0
                })
            }
            if(property == 'buildingDisabled' && this.modalData[property]){
                this.locationData.push({
                    fieldValue : 'Building',
                    id : this.modalData.building_id ,
                    value : this.modalData.building_name ,
                    disabled : 0
                })
            }
            if(property == 'cabinetDisabled' && this.modalData[property]){
                this.locationData.push({
                    fieldValue : 'Cabinet',
                    id : this.modalData.cabinet_id ,
                    value : this.modalData.cabinet_number ,
                    disabled : 0
                })
            }
            if(property == 'drawerDisabled' && this.modalData[property]){
                this.locationData.push({
                    fieldValue : 'Drawer',
                    id : this.modalData.drawer_id ,
                    value : this.modalData.drawer_number ,
                    disabled : 0
                })
            }
            if(property == 'floorDisabled' && this.modalData[property]){
                this.locationData.push({
                    fieldValue : 'Floor',
                    id : this.modalData.floor_id ,
                    value : this.modalData.floor_number ,
                    disabled : 0
                })
            }

            if(property == 'flapDisabled' && this.modalData[property]){
                this.locationData.push({
                    fieldValue : 'Flap',
                    id : this.modalData.flap_id ,
                    value : this.modalData.flap_number ,
                    disabled : 0
                })
            }
          }

    }



    showConfirmationButtons = false;
    currentLocationItem : any;
    currentlyOperation : boolean = false;
    changeStatus(item){
        if(this.currentlyOperation) return;
        item.disabled = 1;
        this.currentLocationItem = item;
        this.showConfirmationButtons = true;
        this.currentlyOperation = true;
    }
    locationsList = [];
    submit(){
        this.currentlyOperation = false;
        this.locationsList.push({
            key : this.currentLocationItem.fieldValue.toLowerCase(),
            ids : [this.currentLocationItem.id]
        });
        this.showConfirmationButtons = false;
        this.currentLocationItem = null;
    }
    cancel(){
        this.currentlyOperation = false;
        this.currentLocationItem.disabled = 0;
        this.showConfirmationButtons = false;
        this.currentLocationItem = null;

    }
    async showValues(){
            this.currentlyOperation = false;
            if(this.locationsList.length ==0 ) return;
            let updateAbleIds : any;
            const data = await this.os.asPromised(
              this.plSetup.markLocationEnabled('enableLocations', this.locationsList ,  [0] , true)
            );
            console.log('enableLocations Data',data);
            updateAbleIds = data.payload.ids;


          this.dropDownService.locationEnabled('' , (updateAbleIds || []));
          console.log('update able location data',updateAbleIds);
          this.toastService.success('locations enabled successfully' , 'success-toast');
          this.dialogRef.close()
    }
  }
