<div
  class="module-style notification-panel"
  style=""
  [ngClass]="{ notification_full_view: !openNotificationDialog }"
>
  <div
    #notificationRef
    class="row w-100 bg-white m-0 paddingTB-0 notification-panel-wrapper notificaton-panel"
  >
    <div class="col-12 nms-card pt-2 pl-2 pr-2">
      <div class="row align-center header-content-p align-items-center">
        <div class="row col" (click)="navigateBack()">
          <div class="cur-p" [hidden]="hideBackBtn">
            <i class="bi bi-chevron-left"></i>
          </div>
          <div class="d-flex align-items-center cur-p col heading pl-0">
            Updates & Notifications
            <div class="text-center custom-badge pl-2">
              <div>{{ unreadNotificationCount }}</div>
            </div>
          </div>
        </div>
        <div class="just-end row unread m-0 align-items-center">
          <!-- <div class="sub-text mx-1">unread</div> -->
          <div>
            <div *ngIf="showViewAll" class="mr-1">
              <button id="button" (click)="gotoAllNotifications()">
                View All
              </button>
            </div>
          </div>
          <!-- <div (click)="minMaxUserNotification()" class="minimize text-center pl-2">
            <i class="bi bi-dash-lg"></i>
          </div> -->

          <mat-icon
            class="expand-Controls"
            *ngIf="isExpandPanel"
            (click)="isExpandPanel = false"
          >
            remove
          </mat-icon>
          <mat-icon
            class="expand-Controls"
            *ngIf="!isExpandPanel"
            (click)="isExpandPanel = true"
          >
            add
          </mat-icon>
        </div>
      </div>
      <div
        [@openClose]="isExpandPanel"
        class="scroll-notifications w-100 col-md-12 doc-content comp-card p-0 pb-2"
        [class.cus-scroll-mini]="minHeight"
        [class.cus-scroll]="!minHeight"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="getDataOnScroll()"
      >
        <div
          *ngFor="
            let notification of sliceData
              ? (notifications | slice : 0 : 5)
              : notifications
          "
          class="row mt-0 ml-0 mr-0 align-center notification-item py-1"
        >
          <div
            (click)="
              [
                openDialog(notification['userNotificationId']),
                getSingleNotification(notification['userNotificationId']),
                decreaseCount(notification),
                (notification['is_read'] = 1)
              ]
            "
            class="row col-11 m-0 p-0 cur-p"
          >
            <div [class.col-1]="isFitImage" class="avatar pl-0">
              <!-- <img src="assets/images/male.png" alt="" /> -->
              <img
                class="user-profile-avatar mt-0"
                [src]="notification?.micro_picture"
                (error)="verifyImage($event, notification)"
                alt="..."
              />
            </div>
            <div class="col-11 pr-0">
              <div class="row m-0">
                <div class="badge-color mr-1">
                  <div
                    class="badge m-auto user-{{ notification?.role }}"
                    [style.background]="colors[notification['role']]"
                  >
                    {{
                      notification["role"] == "System"
                        ? "SYS"
                        : notification["role"]
                    }}
                  </div>
                </div>
                <div
                  class="name mr-1"
                  [style.color]="colors[notification['role']]"
                  *ngIf="notification['role'] != 'System'"
                >
                  {{ notification["name"] }}
                </div>

                <div
                  class="name mr-1"
                  [style.color]="colors[notification['role']]"
                  *ngIf="notification['role'] == 'System'"
                >
                  {{ notification["notification_subject"] }}
                </div>
                <div class="date">
                  {{ parseDate(notification["completion_date"]) }}
                </div>
              </div>
              <div
                class="notification-msg"
                [class.text-ellipsis]="ellipsisText"
              >
                {{ notification["notification_body"] }}
              </div>
            </div>
          </div>
          <div
            class="col-1 d-flex align-items-center justify-content-between p-0"
          >
            <span class="unread-dot"
              ><i
                *ngIf="notification['is_read'] == 0"
                class="bi bi-dot cur-p"
              ></i
            ></span>
            <a
              *ngIf="notification?.link && notification?.link !== '/' && notification['notification_body'].length < 61"
              [aria-label]="'Open Application'"
              [matTooltip]="'Open Application'"
              [href]=""
              (click)="redirect(notification)"
              class="notification-link"
            >
              <i class="material-icons _redirection_icon">arrow_forward_ios</i>
            </a>
          </div>
        </div>
        <div *ngIf="notifications.length == 0"class="no-record">
          <span>No Data Found</span>
        </div>
      </div>
    </div>
  </div>
</div>
