import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { setFullScreen } from "../../shared/interfaces/fs-document";
import { MapThemeEmitterService } from "../../services/map-theme-emitter.service";
import { Subject } from "rxjs";
import { OrgchartService } from "src/app/services/orgchart.service";
import { Router } from "@angular/router";

declare var $: any;

@Component({
  selector: "app-chart-full-view",
  templateUrl: "./chart-full-view.component.html",
  styleUrls: [
    "./chart-full-view.component.css",
    "../orgchart/orgchart.component.css",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ChartFullViewComponent implements OnInit {
  openDrawer = 0;

  orgChartView ="3";

  orgChartTheme = "1";

  orgChartImage = "1";

  mapTheme:any = '1';

  _opened: boolean = false;

  isfullscreen = false;

  autoCloseRMenu: number = 0;

  closeDrawer: any = 0;
  mapView: any = 3;
  mapCheck = new Subject<boolean>();
  constructor(
    private mapThemeEmitter: MapThemeEmitterService,
    public _orgChartService: OrgchartService,
    private router: Router,
    ) {}

  ngOnInit() {
    this.mapCheck.next(false);
    if (performance.navigation.type == 1) {
      this.mapView = 3;
    } else {
      this.mapView = this.orgChartView;
    }
    this.orgChartView = this._orgChartService.orgChartView.value;
    this.orgChartTheme = this._orgChartService.orgChartTheme.value;
    this.orgChartImage = this._orgChartService.orgChartImage.value;
  }

  ngDoCheck() {
    if ($("#close_drawer").val() == "1") {
      if ($(".chart_holder").is(":visible") === false) {
        this.close_dropdown();
      } else {
        this.close_dropdown();
      }

      $("#close_drawer").val("0");
    }
  }

  ngAfterViewInit() {
    setTimeout(function () {
      document.getElementById("full_screen").click();
      $("#loader-body").hide();
    }, 2000);
  }

  open_dropdown() {
    if (this.openDrawer == 0) {
      $("#leftnav").stop().slideDown(200);
      this.openDrawer = 1;
    } else {
      $("#leftnav").stop().slideUp(200);
      this.openDrawer = 0;
    }

    if ($(".chart_holder").is(":visible") !== false) {
      $("#map_themes").prop("disabled", true).addClass("disable_input");
      $("#preset_view").removeAttr("disabled").removeClass("disable_input");
      $("#themes").removeAttr("disabled").removeClass("disable_input");
      $("#image_colors").removeAttr("disabled").removeClass("disable_input");
    } else {
      $("#map_themes").removeAttr("disabled").removeClass("disable_input");
      $("#preset_view").prop("disabled", true).addClass("disable_input");
      $("#themes").prop("disabled", true).addClass("disable_input");
      $("#image_colors").prop("disabled", true).addClass("disable_input");
    }
  }

  close_dropdown() {
    if (this.openDrawer == 1) {
      setTimeout(function () {
        $("#leftnav").stop().slideUp(200);
        this.openDrawer = 0;
      }, 200);
    }
  }

  selectViewType(event) {
   // this.mapView = (event.target as HTMLInputElement).value;
   this._orgChartService.orgChartView.next(event)
   this._orgChartService.populateOrgChart();
   if (event == 1) {
     this.mapCheck.next(true);
   } else {

     this.mapCheck.next(false);
   }
   // this.close_dropdown();
  }

  selectThemeType(e) {
    this._orgChartService.orgChartTheme.next(e)
    this._orgChartService.populateOrgChart();
  }

  selectImageType(e) {
    this._orgChartService.orgChartImage.next(e)
    this._orgChartService.populateOrgChart();
  }

  full_screen_viewer() {
    //setFullScreen(true);
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  selectMapTheme() {
    this._orgChartService.selectedMapThemeNumber.next(this.mapTheme)
    // this.mapThemeEmitter.changeMessage(this.mapTheme);
    // this.close_dropdown();
    this._orgChartService.orgChartView.next("3");
    this._orgChartService.orgChartTheme.next("1");
  }
  ngOnDestroy() {
    sessionStorage.removeItem('themeValue')
    sessionStorage.removeItem('viewValue')
    sessionStorage.removeItem('imageValue')
    // this._orgChartService.orgChartView.next("3");
    // this._orgChartService.orgChartTheme.next("1");
    // this._orgChartService.orgChartImage.next("1");
  }
  closeOrgChart(){
    this._orgChartService.activeMapView.next(true)
    this._orgChartService.orgFilterData.next(null)
    this.orgChartView = "3";
    this.orgChartTheme =  "1";
    this.orgChartImage = "1";
    this._orgChartService.orgChartView.next("3");
    this._orgChartService.orgChartTheme.next("1");
    this._orgChartService.orgChartImage.next("1");
  }
}
