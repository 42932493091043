import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { OrgchartComponent } from './orgchart.component';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../../shared/modules/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartSidebarModule } from '../chart-sidebar/chart-sidebar.module';
import { OrgmapModule } from '../orgmap/orgmap.module';
import { OrgchartDetailComponent } from '../orgchart/orgchart-detail/orgchart-detail.component';
import { MlOrgchartModule } from '../ng-org-chart/ml-orgchart/ml-orgchart.module';
import { PopupModalModule } from 'src/app/shared/components/popup-modal/popup-modal.module';

const route: Routes = [
  { path: '', component: OrgchartComponent }
];

@NgModule({
  declarations: [
    OrgchartComponent,
    OrgchartDetailComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ChartSidebarModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MlOrgchartModule,
    OrgmapModule,
    RouterModule.forChild(route),
    PopupModalModule
  ]
})
export class OrgchartModule { }
