/**
 * @author Usman Ali <usman.ali@people.com.pk>
 */
import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    selector: 'input[numbersOnly]'
})
export class DigitOnlyDirective {

    constructor(private _el: ElementRef) {
    }
    @HostListener('input', ['$event']) onInputChange(event): void {
        const initalValue = this._el.nativeElement.value;
        this._el.nativeElement.value = initalValue.replace(/[^-0-9]*/g, '');
        if (initalValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }

}
