import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FileUploadService } from "../../../services/file-upload/file-upload.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "document-viewer-modal",
  templateUrl: "document-viewer-modal.html",
  encapsulation: ViewEncapsulation.None,
})
export class DocumentViewerModal {
  pdfUrl: SafeResourceUrl;
  viewer: string = "";
  documentsList = [];
  currentDocument = {};
  currentIndex: number = 0;
  url;
  formatType: boolean;

  constructor(
    public dialogRef: MatDialogRef<DocumentViewerModal>,
    private fileUploadService: FileUploadService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    // debugger;

    this.currentDocument["title"] = data.currentDocument.category_field_name;
    this.currentDocument["fileUrl"] = data.currentDocument.file_url;
    this.currentDocument["fileFormat"] = data.currentDocument.file_format;

    this.currentDocument["id"] = data.currentDocument.id;
    this.documentsList = [...data.documentsList];

    this.currentIndex = this.documentsList.findIndex(
      (value) => this.currentDocument["id"] == value.id
    );
    this.checkformat();
    this.url = this.fileUploadService.accessFile(
      this.fileUploadService.clickedFile.value
    );
  }
  checkformat() {
    if (
      this.currentDocument["fileFormat"].toLowerCase() == "png" ||
      this.currentDocument["fileFormat"].toLowerCase() == "jpeg" ||
      this.currentDocument["fileFormat"].toLowerCase() == "jpg"
    ) {
      this.viewer = "image";
    } else if (
      this.currentDocument["fileFormat"] == "doc" ||
      this.currentDocument["fileFormat"] == "docs" ||
      this.currentDocument["fileFormat"] == "docx"
    ) {
      this.viewer = "google";
    } else if (this.currentDocument["fileFormat"].length) this.viewer = "pdf";
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.currentDocument["fileUrl"]
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  async navDocument(nav) {
    if (nav == "next") {
      if (this.documentsList.length > this.currentIndex + 1)
        this.currentIndex++;
      else this.currentIndex = 0;
    } else if (nav == "prev") {
      if (this.currentIndex > 0) this.currentIndex--;
      else this.currentIndex = this.documentsList.length - 1;
    }
    this.currentDocument["title"] =
      this.documentsList[this.currentIndex]["category_field_name"];
    this.currentDocument["fileUrl"] =
      this.documentsList[this.currentIndex]["file_url"];

    const fileId = Number(this.currentDocument["fileUrl"]);
    if (fileId) {
      const fileAccessUrl = await this.fileUploadService.accessFile(fileId);
      if (fileAccessUrl["success"]) {
        this.currentDocument["fileUrl"] = fileAccessUrl["data"];
      }
    }
    this.currentDocument["fileFormat"] =
      this.documentsList[this.currentIndex]["file_format"];
    this.currentDocument["id"] = this.documentsList[this.currentIndex]["id"];

    this.checkformat();
  }

  async downloadFile(url, name, ext) {
    console.log(url, name, ext, "click on download");

    this.fileUploadService.downloadFile(url).subscribe((success) => {
      console.log(success);

      const blob = new Blob([success]);
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${name}.${ext}`;
      console.log(link.download);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  // async downloadFile(url, name, ext) {
  //   // debugger

  //   // if(url == ""){
  //   // url = await this.fileUploadService.accessFile(this.fileUploadService.clickedFile.value);
  //   // }
  //   console.log(url, name, ext);

  //   this.fileUploadService.downloadFile(this.fileUploadService.clickedFile.value).subscribe(
  //     resp =>{
  //       // debugger
  //       console.log(resp);

  //       const blob = new Blob([resp.payload.url.data], { type: resp.payload.fileExtension });
  //       var link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob);
  //       // window.open(link.href);
  //       link.download = resp.payload.fileName;
  //       console.log(link.download);

  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   );
  // }
  openFileInNewTab(url) {
    window.open(url, "_blank");
  }
}

